import React from 'react';
import { getSections, getSection } from '../auditProvider';
import FillAuditSectionListItem from './fillAuditSectionListItem';
import history from '../../../core/router/history';

class AuditSectionList extends React.Component {
  state = {
    sections: [],
    history: history,
    redirectToTemplate: false
  };

  componentWillMount() {
    if (this.props && this.props.sectionId) {
      let templateId = parseInt(this.props.templateId, 10);
      let sectionId = parseInt(this.props.sectionId, 10);

      getSection(templateId, sectionId).then(section => {
        this.setState({ sections: section.sections });
      });
    } else {
      if (this.props.templateId) {
        getSections(this.props.templateId).then(sections => {
          this.setState({ sections: sections });
        });
      }
    }
  }

  render() {
    const parentSectionId = parseInt(this.props.sectionId, 10);
    return (
      <div className="row" style={sectionStyle}>
        <div className="col-12">
          {this.state.sections.map(item => {
            return (
              <FillAuditSectionListItem
                ref="navUpdateAuditElapsedTime"
                key={item.id}
                templateId={this.props.templateId}
                auditId={this.props.auditId}
                parentSectionId={parentSectionId}
                sectionId={item.id}
                section={item}
                haveSubSections={item.sections.length > 0}
                haveOneSubSections={item.sections.length === 1}
                oneSubSectionsId={
                  item.sections.length === 1 ? item.sections[0].id : null
                }
                progressValue={this.state.progressValue}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
export default AuditSectionList;

const sectionStyle = {
  background: '#FFFFFF',
  height: '70vh',
  overflow: 'scroll',
  WebkitOverflowScrolling: 'touch'
};
