import { faCalendarAlt } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'reactstrap';
import Literal from '../../../../core/shared/literal';

class ConductedInfoRow extends React.Component {
  state = {
    isOpen: false,
    startDate: null
  };

  componentDidMount() {
    this.setState({ isOpen: false, startedDate: this.props.defaultDate });
    if (this.props.defaultDate === undefined) {
      const initialDate = moment();
      this.setState({ isOpen: false, startedDate: initialDate });
      this.props.startDateSelected(initialDate.toString().slice(0, 21));
    }
  }

  handleChange(date) {
    this.setState({ startedDate: date });
    this.props.startDateSelected(date.toString().slice(0, 21));
  }

  openCalendar(e) {
    this.setState({ tmpStartDate: this.state.startedDate });

    e && e.preventDefault();
    if (this.props.isEditable !== undefined ? this.props.isEditable : true) {
      let open = !this.state.isOpen;
      this.setState({ isOpen: open });
    }
  }

  closeCalendar(e) {
    e && e.preventDefault();
    if (this.props.isEditable !== undefined ? this.props.isEditable : true) {
      let open = !this.state.isOpen;
      this.setState({ isOpen: open });
    }
    this.setState({ startedDate: this.state.tmpStartDate });
  }

  saveCalendar(e) {
    e && e.preventDefault();
    if (this.props.isEditable !== undefined ? this.props.isEditable : true) {
      let open = !this.state.isOpen;
      this.setState({ isOpen: open });
    }
  }

  renderItem = (isOpen, startedDate) => {
    return isOpen ? (
      <DatePicker
        selected={this.state.startedDate}
        onChange={date => this.handleChange(date)}
        locale="en"
        withPortal
        inline
        showTimeSelect
        timeIntervals={5}
        timeFormat="HH:mm"
        dateFormat="LLL"
        shouldCloseOnSelect={false}
        showMonthDropdown
        showYearDropdown
      >
        <div>
          <Button
            color="error"
            onClick={() => {
              this.closeCalendar();
            }}
            style={buttonClose}
          >
            <Literal tKey="date_picker_close" />
          </Button>
          <Button
            color="danger"
            onClick={() => {
              this.saveCalendar();
            }}
            style={buttonSave}
          >
            <Literal tKey="date_picker_save" />
          </Button>
        </div>
      </DatePicker>
    ) : null;
  };

  render() {
    const { isOpen, startedDate } = this.state;

    const startedDateString = startedDate
      ? startedDate.toString().slice(0, 21)
      : null;

    return (
      <div className="row " style={containerStyle}>
        <div className="col-12">
          <div className="row ">
            <div className="col-8">
              <div className="row ">
                <div className="col-12 mt-3" style={titleStyle}>
                  {this.props.title}
                </div>
              </div>

              <div className="row ">
                <div className="col-12 mb-3" style={subtitleStyle}>
                  {startedDateString}
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="iconBackground">
                <FontAwesomeIcon
                  onClick={e => this.openCalendar(e)}
                  size="2x"
                  icon={faCalendarAlt}
                  style={iconStyle}
                />
              </div>
            </div>
            {this.renderItem(isOpen, startedDateString)}
            <div
              className="col-12"
              style={{
                background: '#E0E0E0',
                height: 2
              }}
            />
          </div>
        </div>
        <div />
      </div>
    );
  }
}
export default ConductedInfoRow;

const containerStyle = {
  background: '#FFFFFF',
  height: 80,
  marginTop: 3,
  marginBottom: 8
};

const titleStyle = {
  color: '#888888',
  textAlign: 'left',
  fontFamily: 'Roboto !important',
  fontSize: '7pt !important',
  fontWeight: 'bold'
};

const subtitleStyle = {
  text: '#99CC33',
  textAlign: 'left',
  height: 30,
  fontFamily: 'Roboto !important',
  fontSize: '7pt !important',
  fontWeight: '200 !important'
};

const iconStyle = {
  marginTop: '8px'
};

const buttonClose = {
  background: 'red',
  borderColor: 'red',
  color: '#FFFFFF',
  marginBottom: '8px',
  width: '120px',
  left: 0,
  position: 'relative',
  bottom: 0,
  height: '36px',
  marginRight: 2
};

const buttonSave = {
  background: '#99CC33',
  borderColor: '#99CC33',
  color: '#FFFFFF',
  marginBottom: '8px',
  width: '120px',
  right: 0,
  position: 'relative',
  bottom: 0,
  height: '36px'
};
