import React from 'react';
import { isAuthenticated } from '../../api/authManager';
import { Navbar } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/fontawesome-free-solid';


class NavigationBar extends React.Component {

  render() {
    const logoUrl = require('../../assets/logo_blanco.png');
    return isAuthenticated() ? (
      <div className="row">
        <div className="col-12 p-0">
          <Navbar style={navStyle} light>
            <div className="d-flex justify-content-between w-100">
              <div className="p-2">
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={() => this.props.openMenuTapped()}
                />
              </div>
              <div className="p-2">{getNavigationTitle()}</div>
              <div className="p-2">
                <img src={logoUrl} alt="Logo" width="16" height="16" />
              </div>
            </div>
          </Navbar>
        </div>
      </div>
    ) : null;
  }
}
export default NavigationBar;

let navigationTitle = '';
export function getNavigationTitle() {
  return navigationTitle;
}

export function setNavigationTitle(title) {
  navigationTitle = title;
}

const navStyle = {
  left: 0,
  right: 0,
  width: '100vw',
  textAlign: 'center',
  alignItems: 'center',
  color: 'white',
  height: '48px',
  background: '#333333',
  WebkitTransition: 'all',
  msTransition: 'all'
};