import React from 'react';
import SearchBar from '../../../core/shared/searchBar';
import TemplateListWebItem from './templateListWebItem';
import {
  getWebTemplates,
  getCountWebTemplates,
  webTemplatesTable
} from '../../../api/apiClient';
import { faInbox } from '@fortawesome/fontawesome-free-solid';
import { EmptyView } from '../core/emptyView';
import Loader from '../../../core/shared/loader';
import Literal from '../../../core/shared/literal';
import { getDBTable, getItem } from '../../fillAudit/dbProvider';

class NewsTemplatesWebList extends React.Component {
  state = {
    references: [],
    dataFetched: false,
    searchText: null,
    pagination: {
      currentPage: 0
    }
  };

  componentDidMount() {
    this.fetchReferences();
    this.fetchInitialPage();
  }

  fetchReferences() {
    getDBTable('template-reference').then(references =>
      this.setState({ references })
    );
  }

  render() {
    const templatesList = this.state.templates;
    const templatesListView =
      templatesList && templatesList.length > 0 ? (
        <div className="row">
          <div className="col-12" style={style.list}>
            {templatesList.map((item, index, array) => {
              return (
                <TemplateListWebItem
                  template={item}
                  key={index}
                  index={index}
                  activeReference={
                    this.state.references &&
                    this.state.references.some(r => r.id === item.id)
                  }
                  downloadOrDeletedItem={() => this.fetchReferences()}
                  lastItemOfPage={index === array.length - 1}
                  requestNextPage={() => {
                    this.fetchPage();
                  }}
                />
              );
            })}
          </div>
        </div>
      ) : this.state.dataFetched ? (
        this.state.oldTemplates.length === 0 ? (
          <EmptyView
            icon={faInbox}
            title={<Literal tKey="empty_templates_title" />}
          />
        ) : (
          <EmptyView icon={faInbox} title="Any template assigned" />
        )
      ) : null;

    return (
      <div className="row">
        <div className="col-12">
          <SearchBar onChange={value => this.searchBarOnChange(value)} />

          <Loader loading={!this.state.dataFetched} position="relative" />

          {templatesListView}
        </div>
      </div>
    );
  }

  searchBarOnChange = value => {
    this.setState(
      {
        searchText: value.target.value ? value.target.value.toLowerCase() : null
      },
      () => {
        this.fetchInitialPage();
      }
    );
  };

  fetchInitialPage() {
    const itemsForPage = 20;
    let pagination = {
      currentPage: 0,
      itemsForPage: itemsForPage
    };

    return getCountWebTemplates(this.state.searchText)
      .then(count => {
        let data = {
          ...pagination,
          totalPages: Math.ceil(count / itemsForPage),
          count: count
        };
        this.setState(
          {
            pagination: data,
            templates: null
          },
          () => {
            this.fetchPage();
          }
        );
      })
      .catch(() => {
        this.fetchLocalReferences(itemsForPage);
      });
  }

  fetchPage() {
    const currentPage = this.state.pagination
      ? this.state.pagination.currentPage
      : 0;
    const itemsForPage = this.state.pagination
      ? this.state.pagination.itemsForPage
      : 0;
    const offset = currentPage * itemsForPage;
    this.setState({ dataFetched: false });

    getWebTemplates(itemsForPage, offset, this.state.searchText).then(
      templates => {
        if (templates.length === 0 && offset === 0) {
          this.setState({
            references: [],
            dataFetched: true,
            templates: [],
            oldTemplates: [],
            pagination: {
              currentPage: 0
            }
          });
        } else {
          const items = this.state.templates
            ? [...this.state.templates, ...templates]
            : templates;
          this.setState({
            dataFetched: true,
            templates: items,
            oldTemplates: items,
            pagination: {
              ...this.state.pagination,
              currentPage: currentPage + 1
            }
          });
        }
      }
    );
    // }
  }

  fetchLocalReferences(itemsForPage) {
    return getDBTable(webTemplatesTable)
      .then(webTemplates => {
        const totalPages = Math.ceil(webTemplates.length / itemsForPage);
        this.setState({
          dataFetched: true,
          templates: webTemplates,
          pagination: {
            currentPage: totalPages,
            itemsForPage,
            totalPages: totalPages,
            count: webTemplates.length
          }
        });
      })
      .catch(() => {
        this.setState({
          dataFetched: true,
          templates: []
        });
      });
  }
}
export default NewsTemplatesWebList;

const style = {
  list: {
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch',
    height: 'calc(100vh - 110px)'
  }
};
