import React from 'react';
import { isNumeric } from '../../../../core/utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import Literal from '../../../../core/shared/literal';

class TextField extends React.Component {
  // componentWillMount(){
  //   if (this.props.hint !== null) {
  //     this.setState({
  //       textValue: this.props.textValue
  //     });
  //   }
  // }
  inputTextChange = evt => {
    let { hint, handleAnswerValue } = this.props;
    let textValue = evt.target.value.length > 0 ? evt.target.value : hint;
    textValue = textValue ? textValue : '';

    if (this.props.isNumeric) {
      textValue = textValue.replace(',','.')
      if (!isNumeric(textValue) && textValue !== '') {
        toast.error(<Literal tKey="textfield_number_validation" />, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3500
        });
        return;
      } 
    }

    this.setState({
      textValue: textValue
    });
    handleAnswerValue(textValue);
  };


  render() {
    let textValue =
      this.state && this.state.textValue
        ? this.state.textValue
        : this.props.textValue
          ? this.props.textValue
          : this.props.defaultValue;
    const textChange = evt => this.inputTextChange(evt);

    return (
      <div>
        <textarea
          value={textValue !== null ? textValue : ''}
          style={textField}
          className="ml-0 mr-0"
          onChange={evt => textChange(evt)}
          type={this.props.isNumeric ? "number" : "text"}
          pattern={this.props.isNumeric ? "[0-9]*" : "*"}
          step={this.props.isNumeric ? "0.01" : "0"}
        />
        <ToastContainer />
      </div>
    );
  }
}

export default TextField;

const textField = {
  minHeight: '130px',
  minWidth: '80vw',
  maxWidth: '90vw',
  border: 'solid 1px gray',
  borderRadius: '5px'
};
