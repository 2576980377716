import { faCamera, faFolder, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as loadImage from 'blueimp-load-image';
import device from 'current-device';
import moment from 'moment';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Literal from '../../../../core/shared/literal';
import { getAnswerValueExtraInfo } from '../../answerValueProvider';
import AudioRecorder from '../audio/audioRecorder';
import TextField from '../questions/TextField';

class SubQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extraValue: {
        comment: null,
        image: null,
        sound: null,
        document: null
      },
      textFieldValue: this.props.textValue
    };

    if (!props.extraValue) {
      const label = props.label;
      getAnswerValueExtraInfo(this.props.auditId, this.props.answerId).then(
        answer => {
          if (answer !== null) {
            let newExtraValue = answer.answer ? answer.answer.extraInfo : null;
            const emptyExtraValue = {};
            if (this.props.answer.nc) {
              emptyExtraValue.caducityDate = moment()
                .add(1, 'month')
                .format('YYYY-MM-DD');
            }
            this.updateQuestion(newExtraValue || emptyExtraValue);
          } else {
            const emptyExtraValue = {
              comment: label,
              image: null,
              sound: null
            };
            if (this.props.answer.nc) {
              emptyExtraValue.caducityDate = moment()
                .add(1, 'month')
                .format('YYYY-MM-DD');
            }
            this.updateQuestion(emptyExtraValue);
          }
        }
      );
    } else {
      // this.updateQuestion( props.extraValue);
    }

  }

  startRecording = () => {
    this.setState({
      record: true
    });
  };

  stopRecording = () => {
    this.setState({
      record: false
    });
  };

  onData(recordedBlob) {
    console.log('chunk of real-time data is: ', recordedBlob);
  }

  onStop(recordedBlob) {
    console.log('recordedBlob is: ', recordedBlob);
  }

  handleFormSubmit(evt) {
    evt.preventDefault();
  }

  notify_error = message =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      autoClose: 2000
    });

  updateQuestion(extraValue) {
    const { handleSubQuestion } = this.props;
    this.setState({ extraValue: extraValue });
    handleSubQuestion(extraValue);
  }

  inputTextChange(textValue) {
    const text = textValue ? textValue : this.props.label;
    let newExtraValue = {
      ...this.state.extraValue,
      comment: text
    };
    this.setState(
      {
        extraValue: newExtraValue,
        //textFieldValue: textValue
      },
      () => {
        this.updateQuestion(newExtraValue);
        //this.props.updateTextFieldValue(textValue);
      }
    );
  }

  updateExtraInfo(data) {
    this.updateQuestion(data);
  }
  HandleFileChange = event => {
    const file = event.target.files[0];
    if (file.size <= 6 * 1024 * 1024) {
    const fileType = file.type;

    if (fileType.startsWith('image/')) {
      const loadImageOptions = { canvas: true, maxWidth: 1200 };
      loadImage.parseMetaData(file, data => {
        if (data.exif && data.exif.get('Orientation')) {
          loadImageOptions.orientation = data.exif.get('Orientation');
        }
        loadImage(
          file,
          canvas => {
            var ctx = canvas.getContext('2d');
            switch (loadImageOptions.orientation) {
              case 2:
                ctx.transform(-1, 0, 0, 1, file.width, 0);
                break;
              case 3:
                ctx.transform(-1, 0, 0, -1, file.width, file.height);
                break;
              case 4:
                ctx.transform(1, 0, 0, -1, 0, file.height);
                break;
              case 5:
                ctx.transform(0, 1, 1, 0, 0, 0);
                break;
              case 6:
                ctx.transform(0, 1, -1, 0, file.height, 0);
                break;
              case 7:
                ctx.transform(0, -1, -1, 0, file.height, file.width);
                break;
              case 8:
                ctx.transform(0, -1, 1, 0, 0, file.width);
                break;
              default:
                ctx.transform(1, 0, 0, 1, 0, 0);
            }


          ctx.drawImage(canvas, 0, 0, file.width, file.height);

          canvas.toBlob(blob => {
            const url = URL.createObjectURL(blob);
            let newExtraValue = {
              ...this.state.extraValue,
              image: {
                filename: file.name,
                filesize: blob.size,
                filetype: fileType,
                file: url,
                blob: blob
              }
            };
            this.updateExtraInfo(newExtraValue);
          });
        },
        loadImageOptions
      );
    });
    }
    else {

      let newExtraValue = {
        ...this.state.extraValue,
        document: {
          filename: file.name,
          filesize: file.size,
          filetype: fileType,
          file: URL.createObjectURL(file),
          blob: file
        }
      }
      this.updateExtraInfo(newExtraValue);
    };
  }else{
    const errorMessage = (
      <Literal tKey="file_size_error" values="" />
    );
  
    this.notify_error(errorMessage);
  }
  };

  deleteImage = () => {
    const extraValue = {
      ...this.state.extraValue,
      image: null
    };
    this.updateQuestion(extraValue);
  };

  deleteAttachment = () => {
    const extraValue = {
      ...this.state.extraValue,
      document: null
    };
    this.updateQuestion(extraValue);
  };

  renderImage = extraValue => {
    var binaryData = [];
    var blob = null;
    if (extraValue.image && extraValue.image.blob) {
      binaryData.push(extraValue.image.blob);
    } else if (
      extraValue.image &&
      !extraValue.image.blob &&
      extraValue.image.blobBytes
    ) {
      var byteArray = new Uint8Array(extraValue.image.blobBytes);
      blob = new Blob([byteArray], { type: 'application/zip' });
      // binaryData.push(new Uint8Array(extraValue.image.blobBytes))
    }

    return (extraValue.image && extraValue.image.blob) || blob !== null ? (
      <div className="col-12" style={containerPhoto}>
        <div className="col-12 d-flex justify-content-center">
          <div className="iconBackground microphone">
            <FontAwesomeIcon
              size="2x"
              icon={faTrash}
              style={iconStyleTrash}
              onClick={this.deleteImage}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <img
            alt=""
            src={
              blob !== null
                ? URL.createObjectURL(blob)
                : URL.createObjectURL(
                  new Blob(binaryData, { type: 'application/zip' })
                )
            }
            style={imageStyle}
          />
        </div>
      </div>
    ) : null;
  };

  renderFile = extraValue => {
    if (extraValue.document) {
      return (
        <div className='col-12' style={containerFile}>
          <div className="col-12">
            <div className="iconBackground file">
              <FontAwesomeIcon
                size="2x"
                icon={faTrash}
                style={iconStyleTrash}
                onClick={this.deleteAttachment}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="iconBackground file">
              <FontAwesomeIcon
                size="2x"
                icon={faFolder}
                style={iconStyle}
              />
            </div>
            <p>{extraValue.document.filename}</p>
          </div>
        </div>
      );
    } else { return null }
  };

  setDate(event) {
    this.updateQuestion({
      ...this.state.extraValue,
      caducityDate: event.target.value
    });
  }

  render() {
    const { extraValue, textFieldValue } = this.state;
    const textChange = text => this.inputTextChange(text);

    const label = textFieldValue ? textFieldValue : this.props.label;

    const audio = extraValue ? extraValue.audio : null;

    const audioRecorder =
      device.os !== 'ipad' && device.os !== 'iphone' ? (
        <AudioRecorder
          audioData={audio}
          saveAudio={audio => {
            this.updateQuestion({
              ...extraValue,
              audio
            });
          }}
        />
      ) : null;

    return (
      <div className="row" style={container}>
        <div className="col-12" style={marginText}>
          <TextField
            defaultValue={label ? label : this.props.hint}
            textValue={textFieldValue}
            handleAnswerValue={text => textChange(text)}
          />

          <div className="row">
            <div className="col-4">{audioRecorder}</div>
            <div className="col-4" >
              <div className="iconBackground camera" style={camera}>
                <label htmlFor={'fuskCamera' + this.props.answerId}>
                  <FontAwesomeIcon
                    size="2x"
                    icon={faCamera}
                    style={iconStyleCam}
                  />
                </label>

                <input
                  id={'fuskCamera' + this.props.answerId}
                  type="file"
                  name="subQuestionImage"
                  style={{ display: 'none' }}
                  onChange={this.HandleFileChange}
                  accept="image"
                />
              </div>
              </div>
              <div className='col-4'>
                <div className="col-4">
                  <label htmlFor={'fuskDocument' + this.props.answerId}>
                    <div className="iconBackground file" style={file}>
                      <FontAwesomeIcon
                        size="2x"
                        icon={faFolder}
                        style={iconStyle}
                      />
                    </div>
                  </label>
                  <input
                    id={'fuskDocument' + this.props.answerId}
                    type="file"
                    name="subQuestionDocument"
                    style={{ display: 'none' }}
                    onChange={this.HandleFileChange}
                    accept=".docx, .pdf, .rar, .zip, .xlsx, .ppt, .pptx"
                  />
                </div>
            </div>
          </div>
          {this.renderImage(extraValue)}
          {this.renderFile(extraValue)}
          {this.props.answer.nc ? (
            <div className="row mt-3">
              <div className="col-12">Expiry date: </div>
              <div className="col-12">
                <input
                  type="date"
                  onChange={event => this.setDate(event)}
                  value={this.state.extraValue.caducityDate}
                />
              </div>
              <ToastContainer />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default SubQuestion;

const container = {
  marginRight: '20px'
};

const iconStyle = {
  marginTop: '8px',
  marginLeft: '9px'
};
const iconStyleCam = {
  marginTop: '8px',
  marginLeft: '9px'
};

const iconStyleTrash = {
  marginLeft: '11px',
  marginTop: '8px'
};

const imageStyle = {
  width: '100%',
  height: '250px',
  marginTop: '10px',
  marginBottom: '10px'
};

const marginText = {
  marginLeft: '25px'
};

const camera = {
  marginLeft: '20px'
};
const file = {
  marginLeft: '20px'
};

const containerPhoto = {
  border: '1px solid #99CC33',
  borderRadius: '5px',
  marginTop: '15px',
  marginLeft: '-10px'
};
const containerFile = {
  border: '1px solid #99CC33',
  borderRadius: '5px',
  marginTop: '15px',
  marginLeft: '-10px'
};
