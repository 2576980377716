import React from 'react';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';
import AppRouter from './core/router/appRouter';
import { LanguageContext } from './language.context';
import { getLanguages } from './screens/fillAudit/languageProvider';

require('./apple-touch-icon.png');

library.add(faStroopwafel);

class App extends React.Component {
  state = {
    selectedLanguage: null,
    languages: []
  };

  constructor(props) {
    super(props);
    this.setSelectedLanguage = this.setSelectedLanguage.bind(this);
    this.setLanguages = this.setLanguages.bind(this);
  }

  componentDidMount() {
    this.initLanguages();
  }

  render() {
    return (
      <div className="App">
        <div className="container-fluid">
          <LanguageContext.Provider
            value={{
              languages: this.state.languages,
              selectedLanguage: this.state.selectedLanguage,
              setSelectedLanguage: this.setSelectedLanguage,
              setLanguages: this.setLanguages
            }}
          >
            <AppRouter />
          </LanguageContext.Provider>
        </div>
      </div>
    );
  }

  initLanguages() {
    getLanguages().then(languages => {
      let selectedLanguage = languages.length ? languages[0].id : null;
      this.setState({ languages, selectedLanguage });
    });
  }

  setLanguages(languages) {
    this.setState({ selectedLanguage: languages[0].id, languages });
  }

  setSelectedLanguage(languageId) {
    this.setState({ selectedLanguage: languageId });
  }
}
export default App;
