import React from 'react';
import { requestLogin } from '../../api/authManager';
import { Button } from 'reactstrap';
import { uriRecover } from '../../core/router/appRouter';
import { Redirect } from 'react-router-dom';
import { Label } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import Literal from '../../core/shared/literal';
import { getLanguages } from '../fillAudit/languageProvider';
import { LanguageContext } from '../../language.context';

require('../../assets/login-bg.png');

class Login extends React.Component {
  state = {
    redirectToReferrer: false,
    redirectToRecover: false,
    remindCredentials: false,
    checkButtonChecked: false,
    loaderActive: false
  };

  componentDidMount() {
    let username = localStorage.getItem('user_email');
    let password = localStorage.getItem('user_pwd');

    if (username !== null && password !== null) {
      this.setState({
        defaultUsername: username,
        defaultPassword: password,
        checkButtonChecked: true
      });
    }
  }

  render() {
    // Redirect
    const { from } = this.props.location.state || {
      from: { pathname: '/home' }
    };
    if (this.state.redirectToReferrer) {
      return <Redirect to={from} />;
    } else if (this.state.redirectToRecover) {
      return <Redirect to={uriRecover} />;
    }

    const logoUrl = require('../../assets/logo_blanco.png');

    return (
      <div className="row" style={style.container}>
        <div className="col-12 safariBackground">
          <div className="row" style={style.background}>
            <div className="col-12" />
          </div>

          <div className="row" style={{ paddingTop: '12vh' }}>
            <div className="col-12 pl-5 pr-5">
              <input
                type="text"
                placeholder="username"
                defaultValue={this.state.defaultUsername}
                className="form-control "
                style={style.input}
                name="username"
                onChange={evt => this.handleChange(evt)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col 12 pl-5 pr-5">
              <input
                type="password"
                placeholder="password"
                defaultValue={this.state.defaultPassword}
                className="form-control"
                style={style.input}
                name="password"
                onChange={evt => this.handleChange(evt)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col 12 pl-5 pr-5">
              <LanguageContext.Consumer>
                {({ setLanguages }) => {
                  return (
                    <Button
                      className="w-100"
                      style={style.button}
                      color="green"
                      onClick={() => this.doLogin(setLanguages)}
                    >
                      LOGIN
                    </Button>
                  );
                }}
              </LanguageContext.Consumer>
              <ClipLoader
                sizeUnit={'px'}
                size={70}
                color={'#99CC33'}
                loading={this.state.loaderActive}
              />
            </div>
          </div>

          <div className="row">
            <div className="col 12 text" style={style.bottom.container}>
              <div className="row">
                <div
                  className="col-12 text-white"
                  style={style.bottom.remember}
                >
                  <Label className="container">
                    <input
                      checked={this.state.checkButtonChecked}
                      type="checkbox"
                      onClick={() =>
                        this.setState({
                          checkButtonChecked: !this.state.checkButtonChecked
                        })
                      }
                    />{' '}
                    Remember user & password
                  </Label>
                </div>
              </div>

              <div className="row">
                <div className="col-12" style={style.bottom.recover}>
                  <div className="row text-white">
                    <div className="col-4">
                      <img
                        src={logoUrl}
                        alt="Logo"
                        width="64"
                        height="64"
                        onClick={() => {
                          this.setState({
                            redirectToRecover: true
                          });
                        }}
                      />
                    </div>
                    <div className="col-8">
                      <h6
                        onClick={() => {
                          this.setState({
                            redirectToRecover: true
                          });
                        }}
                      >
                        Click here to recover your password{' '}
                      </h6>
                    </div>
                  </div>

                  <div>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  validateEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  doLogin = setLanguages => {
    // get params
    let username =
      this.state.username !== undefined
        ? this.state.username
        : this.state.defaultUsername;

    let password =
      this.state.password !== undefined
        ? this.state.password
        : this.state.defaultPassword;

    // store credentials
    if (this.state.checkButtonChecked) {
      localStorage.setItem('user_email', username);
      localStorage.setItem('user_pwd', password);
    } else {
      localStorage.removeItem('user_email');
      localStorage.removeItem('user_pwd');
    }

    // validate data
    if (!this.validateEmail(username)) {
      this.notify('Invalid email');
    } else if (password.length === 0) {
      this.notify('Invalid password');
    } else {
      this.setState({
        loaderActive: true
      });
      // valid data
      requestLogin(username, password)
        .then(res => {
          if (res !== null) {
            getLanguages().then(languages => {
              setLanguages(languages);
              this.setState({ redirectToReferrer: true, loaderActive: false });
            });
          } else {
            this.setState({ redirectToReferrer: false, loaderActive: false });
            this.notify(<Literal tKey="not_internet" />);
          }
        })
        .catch(error => {
          this.setState({ loaderActive: false });
          console.log(error);
          this.notify(error.message);
        });
    }
  };

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  }

  notify = message =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      autoClose: 2000
    });
}

export default Login;

// Styles
const style = {
  container: {
    height: '100vh',
    background: '#009310'
  },
  background: {
    position: 'absolute',
    top: '50vh',
    height: '50vh',
    width: '100vw',
    background: '#009310',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100vw 50vh',
    backgroundAttachment: 'fixed',
    OBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    WebkitBackgroundSize: 'cover'
  },
  input: {
    height: 52,
    marginBottom: 7,
    marginTop: 16
  },
  button: {
    height: 52,
    marginBottom: 7,
    marginTop: 16,
    background: '#99CC33',
    color: '#FFFFFF'
  },
  bottom: {
    container: {
      position: 'absolute',
      bottom: 0
    },
    remember: {
      height: '15vh',
      backgroundColor: '#00580A',
      display: 'flex',
      alignItems: 'center'
    },
    recover: {
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      height: '18vh',
      backgroundColor: '#003506'
    }
  }
};
