import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import { requestChangePassword } from '../../api/authManager';
import { ClipLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ChangePasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            loaderActive: false
        }
    }

    render() {
        const showModal =
            this.props &&
            this.props.showModal() !== undefined &&
            this.props.showModal();

        return (
            <div>
                <Modal
                    isOpen={showModal}
                    toggle={this.toggle}
                    className={this.props.className}
                    style={{ top: '5vh', background: '#99CC63' }}
                >
                    <ModalHeader toggle={this.toggle} style={{ textAlign: 'center' }}>
                        Change password
                    </ModalHeader>
                    <ModalBody>
                        <h5 >If you need to change your password, please fill out this form</h5>

                        <div className='row'>
                            <div className='col-12'>
                                <div id="form">
                                    <input
                                        type="password"
                                        placeholder="oldPassword"
                                        defaultValue={this.state ? this.state.oldPassword : null}
                                        className="form-control mt-2 mb-2"
                                        name="oldPassword"
                                        onChange={evt => this.handleChange(evt)}
                                    />
                                    <input
                                        type="password"
                                        placeholder="newPassword"
                                        defaultValue={this.state ? this.state.newPassword : null}
                                        className="form-control mt-2 mb-2"
                                        name="newPassword"
                                        onChange={evt => this.handleChange(evt)}
                                    />
                                    <input
                                        type="password"
                                        placeholder="repeat new password"
                                        defaultValue={this.state ? this.state.repeatPassword : null}
                                        className="form-control mt-2 mb-2"
                                        name="repeatPassword"
                                        onChange={evt => this.handleChange(evt)}
                                    />
                                </div>
                                <Button color="success" onClick={() => { this.changePassword(); }}
                                > CHANGE</Button>
                                <ClipLoader
                                    sizeUnit={'px'}
                                    size={70}
                                    color={'#99CC33'}
                                    loading={this.state ? this.state.loaderActive : false}
                                />
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
                <ToastContainer />
            </div>
        );
    }

    toggle() {
        this.props.closeModal();
    }

    notify = message =>
        toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            autoClose: 1100
        });



    changePassword() {
        const { oldPassword, newPassword, repeatPassword } = this.state;
        if (!oldPassword) {
            this.notify('Old password is mandatory')

        } else if (!newPassword) {
            this.notify('New password is mandatory')

        } else if (!repeatPassword) {
            this.notify('Repeat password is mandatory')
        } else if (newPassword !== repeatPassword) {
            this.notify('Passwords do not match')
        } else {
            this.setState({ loaderActive: true }, () => {

                requestChangePassword(oldPassword, newPassword)
                    .then(res => {
                        this.setState({ loaderActive: false }, () => {
                            toast.error('password changed correctly!', {
                                position: toast.POSITION.BOTTOM_CENTER,
                                hideProgressBar: true,
                                autoClose: 1100
                            });
                            this.toggle()
                        });
                    })
                    .catch(error => {
                        this.setState({ loaderActive: false });
                        console.log(error);
                        this.notify(error.message);
                    });
            });
        }
    }

    handleChange(evt) {
        this.setState({
            ...this.state,
            [evt.target.name]: evt.target.value
        });
    }


}
export default ChangePasswordModal;
