import idb from 'idb';
import { getUserId } from '../../api/authManager';
const dbName = 'app4Check-idb';
const currentVersion = 11;
export const dbPromise = Promise.resolve().then(() => {
  return idb.open(dbName, currentVersion, upgradeDB => {
    if (upgradeDB.oldVersion === 0) {
      createTables(upgradeDB);
    } else if (upgradeDB.oldVersion === 10) {
      Promise.resolve(upgradeDB).then(upgradeDB => {
        upgradeDB.createObjectStore('web-templates', { keyPath: 'id' });
      });
    } else if (upgradeDB.oldVersion === 9) {
      Promise.resolve(upgradeDB).then(upgradeDB => {
        upgradeDB.createObjectStore('language', { keyPath: 'id' });
      });
    } else if (upgradeDB.oldVersion === 8) {
      Promise.resolve(upgradeDB).then(upgradeDB => {
        upgradeDB.createObjectStore('visitedQuestion', { keyPath: 'id' });
      });
    } else if (upgradeDB.oldVersion === 7) {
      Promise.resolve(upgradeDB).then(upgradeDB => {
        upgradeDB.createObjectStore('visitedQuestion', { keyPath: 'id' });
        upgradeDB.createObjectStore('user', { keyPath: 'id' });
      });
    } else if (upgradeDB.oldVersion <= currentVersion - 1) {
      idb.delete(dbName).then(() => {
        createTables(upgradeDB);
      });
    }
  });
});

const createTables = upgradeDB =>
  Promise.resolve(upgradeDB).then(upgradeDB => {
    upgradeDB.createObjectStore('visitedQuestion', { keyPath: 'id' });
    upgradeDB.createObjectStore('user', { keyPath: 'id' });
    upgradeDB.createObjectStore('template-web', { keyPath: 'id' });
    upgradeDB.createObjectStore('template', { keyPath: 'id' });
    upgradeDB.createObjectStore('audit', { keyPath: 'primaryKey' });
    upgradeDB.createObjectStore('answerValue', { keyPath: 'id' });
    upgradeDB.createObjectStore('section-progress', { keyPath: 'id' });

    upgradeDB.createObjectStore('template-reference', { keyPath: 'id' });
    upgradeDB.createObjectStore('template-data', { keyPath: 'id' });

    upgradeDB.createObjectStore('language', { keyPath: 'id' });
    upgradeDB.createObjectStore('web-templates', { keyPath: 'id' });
  });

export function getDBTableKeys(tableName) {
  const currentUser = getUserId();
  return getDBTable(tableName).then(allValues =>
    allValues
      .filter(item => item.createdBy === currentUser)
      .map(item => (tableName === 'audit' ? item.primaryKey : item.id))
  );
  // return dbPromise.then(db => {
  //   return db
  //     .transaction(tableName)
  //     .objectStore(tableName)
  //     .getAllKeys();
  // });
}

export function getDBTable(tableName) {
  const currentUser = getUserId();
  return dbPromise
    .then(db => {
      return db
        .transaction(tableName)
        .objectStore(tableName)
        .getAll();
    })
    .then(items => items.filter(item => item.createdBy === currentUser));
}

export function getItem(tableName, key) {
  const currentUser = getUserId();
  return dbPromise
    .then(db => {
      return db
        .transaction(tableName)
        .objectStore(tableName)
        .get(key);
    })
    .then(item =>
      item === undefined
        ? undefined
        : item.createdBy === currentUser
        ? item
        : undefined
    );
}

export function putItem(tableName, item) {
  const currentUser = getUserId();
  item = { ...item, createdBy: currentUser };
  return dbPromise.then(db => {
    return db
      .transaction(tableName, 'readwrite')
      .objectStore(tableName)
      .put(item);
  });
}

export function deleteItem(tableName, key) {
  return dbPromise.then(db => {
    return db
      .transaction(tableName, 'readwrite')
      .objectStore(tableName)
      .delete(key);
  });
}

export function clearTable(tableName) {
  return dbPromise.then(db => {
    return db
      .transaction(tableName, 'readwrite')
      .objectStore(tableName)
      .clear();
  });
}

// export function clearData() {
//   const tables = [
//     'template-reference',
//     'template',
//     'template-data',
//     'audit',
//     'answerValue'
//   ];
//   tables.forEach(table => {
//     clearTable(table);
//   });

//   return Promise.resolve(true);
// }
