import { putItem } from '../screens/fillAudit/dbProvider';

import { fetchWithTimeout } from './apiClient';
import history from '../core/router/history';

let user = null;

export function isAuthenticated() {
  return getToken();
}

export function getToken() {
  let user = getUserData();
  return user && user !== null && user !== undefined ? user.token : false;
}

export function getUserId() {
  let user = getUserData();
  return user && user !== null && user !== undefined ? user.id : 0;
}

export function getUsername() {
  let userData = getUserData();
  if (userData !== null) {
    return userData.firstname + ' ' + userData.lastname;
  }
  return '';
}

export function setUserAvatar(avatar) {
  let userData = getUserData();
  userData.avatar = avatar;
}

export function getUserAvatar() {
  let userData = getUserData();
  if (userData !== null && userData.avatar !== null) {
    return userData.avatar;
  } else {
    return null;
  }
}

export function getUserCompany() {
  let userData = getUserData();
  return userData ? userData.company : null;
}

export function getUserCompanies() {
  let userData = getUserData();
  return userData.companies;
}

export function getUserData() {
  if (user != null) {
    return user;
  } else {
    user = loadDataFromLocalStorage('userData');
    return user ? user : null;
  }
  // const value = localStorage.getItem('userData');
  // if (value === null) {
  //   return Promise.resolve(null);
  // }
  // const userId = parseInt(value, 10);
  // return getItem('user', userId);
}

export function loadDataFromLocalStorage(key) {
  let data = localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (error) {
    return undefined;
  }
}

export function requestRecover(email) {
  return fetchWithTimeout([
    process.env.REACT_APP_API_HOST + '/user/recoverPassword/',
    {
      method: 'get',
      body: JSON.stringify({ mail: email })
    }
  ])
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function requestChangePassword(oldPassword, newPassword) {
  return fetchWithTimeout([
    process.env.REACT_APP_API_HOST + '/user/changePassword/',
    {
      method: 'post',
      body: JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword
      })
    }
  ])
    .then(response => response.json())
    .then(data => {
      return data;
    });
}

export function requestLogin(username, password) {
  return fetchWithTimeout([
    process.env.REACT_APP_API_HOST + '/auth/login',
    {
      method: 'POST',
      body: JSON.stringify({
        login: username,
        password: password
      })
    }
  ])
    .then(response => {
      if (response) {
        if (response.json) {
          return response.json();
        } else {
          throw new Error('Incorrect user or password');
        }
      } else return null;
    })
    .then(res => {
      if (res !== null) {
        let data = res;
        user = {};
        user.id = data.id;
        user.token = data.token.value;
        user.company = data.company;
        user.email = data.email;
        user.firstname = data.firstName;
        user.lastname = data.lastName;
        user.avatar = data.avatar;
        user.role = data.role;
        user.city = data.city;
        user.mobile = data.mobile;
        user.phone = data.phone;
        user.title = data.title;

        // user.devices = data.devices;
        user.country = data.country;
        user.companies = data.companies;

        localStorage.setItem('userData', JSON.stringify(user));
        localStorage.setItem('user_id', user.id);
        putItem('user', user);
        return user;
      } else {
        return null;
      }
    });
}

export function logoutUser() {
  return removeUserData().then(success => {
    if (localStorage.getItem('userData' === undefined)) {
    }
    if (user) {
      user.token = '';
    }
    history.push('/home');
    history.push('/login');
    return true;
  });
}

export function removeUserData() {
  localStorage.removeItem('user_email');
  localStorage.removeItem('user_pwd');
  localStorage.removeItem('user_id');
  localStorage.removeItem('userData');

  return Promise.resolve(true);
}

// function clearLocalDatabase() {
//   clearData();
// }
