import React from 'react';
import {
  secondsToTimeFormat,
  elapsedTimeManager
} from '../../../../core/utils/utils';

const fireTime = 1000; // ms
class CountUp extends React.Component {
  state = {
    elapsedTime: null
  };

  componentDidMount() {
    if (this.props.auditId) {
      const existingElapsedTime = elapsedTimeManager.get(this.props.auditId);
      this.setState({
        elapsedTime: existingElapsedTime
      });
      this.timer = setInterval(() => {
        this.updateDate();
      }, fireTime);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateDate() {
    const elapsedTime = parseInt(this.state.elapsedTime, 10) + fireTime / 1000;
    elapsedTimeManager.set(this.props.auditId, elapsedTime);
    this.setState({
      elapsedTime
    });
  }

  render() {
    return (
      <div>
        {this.state.elapsedTime >= 0
          ? secondsToTimeFormat(this.state.elapsedTime)
          : null}
      </div>
    );
  }
}
export default CountUp;
