import React from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from 'emotion';
import { variableColors } from '../modules/variableColors';

class Loader extends React.Component {
  render() {
    const size = this.props ? (this.props.size ? this.props.size : 70) : 70;
    const topMarginValue =
      this.props.topMargin !== undefined ? this.props.topMargin : '20';

    const topMargin = topMarginValue !== 0 ? topMarginValue + 'px' : '0px';

    const classData =
      this.props.position === 'relative'
        ? css({
          position: 'absolute'
        })
        : this.props.position === 'absolute'
        ? css({
            position: 'absolute',
            zIndex: 999,
            bottom: '0px',
            left: 'auto',
            right: 'auto',
            height: '10vh',
            background: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            boxSizing: 'content-box',
            border: 'solid #5B6DCD 10px'
          })
        : css({
            position: 'relative',
            zIndex: 999,
            top: topMargin,
            left: 'auto',
            right: 'auto'
          });

    return this.props.loading ? (
      <ClipLoader
        className={classData}
        loading={this.props.loading}
        color={variableColors.templateColor}
        sizeUnit={'px'}
        size={size}
      />
    ) : null;
  }
}
export default Loader;
