import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export function EmptyView(props) {
    const icon = props.icon ? <FontAwesomeIcon color="gray" size="5x" icon={props.icon} /> : null;
    const title = props.title ? <h3> {props.title} </h3> : null
    const description = props.description ? <h5> {props.description} </h5> : null

    return <div className="row">
        <div className="col-12 " style={{ top: '30vh' }}>
            {icon}
            <div className="row mt-4">
                <div className="col-12 ">
                    {title}
                    {description}
                </div>
            </div>
        </div>
    </div>
}