import React from 'react';
import styles from './Switch.module.css';

export default function Switch(props) {
  return (
    <div
      className={[
        styles.switch,
        props.enabled ? styles.switchEnabled : ''
      ].join(' ')}
      onClick={event => {
        event.stopPropagation();
        if (props.onChange) {
          props.onChange(!props.enabled);
        }
      }}
    >
      <div className={styles.switchSelector} />
    </div>
  );
}
