import React from 'react';
import { variableColors } from '../../../core/modules/variableColors';
import VisibilitySensor from 'react-visibility-sensor';
import DownloadTemplateButton from '../../../core/shared/downloadTemplateButton';
import { startAudit } from '../../fillAudit/auditProvider';
import Loader from '../../../core/shared/loader';

import { deleteTemplate } from '../../fillAudit/templateProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlay } from '@fortawesome/fontawesome-free-solid';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import history from '../../../core/router/history';
import { deleteItem } from '../../fillAudit/dbProvider';
import { isHotelCompany } from '../../../core/utils/utils';

class TemplateListWebItem extends React.Component {
  state = {
    activeReference: null,
    downloadingData: false
  };

  refreshReferenceActive() {
    this.setState({ downloadingData: false });
    if (this.props.downloadOrDeletedItem) {
      this.props.downloadOrDeletedItem();
    }
  }

  render() {
    const template = this.props.template;
    const actionButton =
      this.props.activeReference === null ? null : this.props
          .activeReference ? (
        <StartButton
          itemId={template.id}
          downloadingData={this.state.downloadingData}
          templateId={template.templateId}
          referenceId={template.referenceId}
          templateDeleted={() => {
            this.refreshReferenceActive();
          }}
          companyId={template.companyId}
          userId={template.userId}
          auditorId={template.auditorId}
        />
      ) : (
        <DownloadTemplateButton
          itemId={template.id}
          templateId={template.templateId}
          referenceId={template.referenceId}
          templateDownloaded={() => {
            this.refreshReferenceActive();
          }}
          notifyMessage={msg => {
            toast.error(msg, {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              autoClose: 2000
            });
          }}
        />
      );

    return (
      <VisibilitySensor
        partialVisibility={true}
        onChange={isVisible => {
          this.checkPagination(isVisible);
        }}
      >
        <div className="row" style={style.container}>
          <div
            className="col-12"
            style={{
              background:
                this.props.index % 2
                  ? variableColors.backgroundWhite
                  : variableColors.backgroundGrey
            }}
          >
            <div className="row" style={{ padding: '10px 0' }}>
              <div className="col-2">
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={number}
                  >
                    <div style={{ margin: '5px' }}>{template.id}</div>
                  </div>
                </div>
              </div>

              <div className="col-7" style={style.title}>
                <div className="row">
                  <div className="col-12">{template.name}</div>
                </div>

                <div className="row" style={style.subtitle}>
                  <div className="col-12 mb-2">{template.country}</div>
                </div>
              </div>

              <div className="col-3">
                <div className="d-flex justify-content-center align-items-center h-100">
                  {actionButton}
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </VisibilitySensor>
    );
  }
  checkPagination(isVisible) {
    if (isVisible && this.props.lastItemOfPage) {
      this.props.requestNextPage();
    }
  }
}

export default TemplateListWebItem;
const number = {
  backgroundColor: '#03930D',
  border: '2px solid #fff',
  color: 'white',
  borderRadius: 50,
  width: 42,
  height: 42,
  fontSize: 11,
  marginRigth: 0
};

const style = {
  container: {
    bottom: 0,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 12,
    textAlign: 'left',
    color: '#888888',
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 10,
    textAlign: 'left',
    color: '#B1B1B1',
    fontWeight: 'regular'
  },
  startButton: {
    background: '#99CC33',
    height: 32,
    fontSize: 12,
    textAlign: 'center'
  },
  separator: {
    height: 1,
    background: '#777777'
  }
};

function StartButton(props) {
  const startedDate = moment()
    .format('DD/MM/YYYY hh:mm:ss')
    .toString();
  const companyId = props.companyId ? props.companyId : null;
  const userId = props.userId ? props.userId : null;
  const referenceId = props.referenceId ? props.referenceId : null;

  return (
    <div className="row">
      <div className="iconPlay">
        <FontAwesomeIcon
          onClick={() => {
            if (isHotelCompany()) {
              history.push('/fillAuditIntro/' + props.templateId);
            } else {
              startAudit(
                props.templateId,
                startedDate,
                companyId,
                userId,
                referenceId
              ).then(audit => {
                history.push(
                  '/fillAuditIntro/' + props.templateId + '/' + audit.primaryKey
                );
              });
            }
          }}
          size="1x"
          icon={faPlay}
          style={style.icon}
        />
      </div>
      <div className="iconTrash">
        {props.downloadingData ? (
          <Loader
            loading={() => props.downloadingData}
            size={30}
            position="relative"
            topMargin={0}
          />
        ) : (
          <FontAwesomeIcon
            onClick={() => {
              deleteTemplate(props.templateId).then(success => {
                if (success) {
                  props.templateDeleted();
                }
              });

              if (props.itemId) {
                deleteItem('template-reference', props.itemId);
              }

              return () => {
                this.refreshReferenceActive();
              };
            }}
            size="1x"
            icon={faTrash}
            style={style.icon}
          />
        )}
      </div>
    </div>

    // <a
    //   className="btn btn-primary"
    //   style={style.startButton}
    //   target="_blank"
    //   href={urlPath}
    //   onClick={() => {
    //     history.push('/templates/news-web/opened');
    //   }}
    // >
    //   <Literal tKey="start" uppercase={true} />
    // </a>
  );
}
