import React from 'react';
import FillAuditQuestion from '../question/fillAuditQuestion';
import AuditProgressNav from '../progressNav/auditProgressNav';
import {
  getSection,
  getSectionProgress,
  calculateFinalSectionProgress,
  saveSectionProgress,
  calculateMiddleSectionProgress,
  calculateAuditTotalProgress
} from '../auditProvider';
import { saveAnswer } from '../answerValueProvider';
import history from '../../../core/router/history';
import { EventEmitter } from '../../../core/utils/events';
import Translation from '../components/translation/Translation';

class AuditSectionQuestions extends React.Component {
  state = {
    section: null,
    questions: [],
    answers: [],
    auditProgressValue: 0
  };

  componentDidMount() {
    this.mounted = true;
    if (window.safari) {
      if (history.pushState) {
        history.pushState(null, null, history.location.href);
      }
      window.onpopstate = function(event) {
        history.go(1);
      };
    }

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.section
    ) {
      this.processQuestions(this.props.location.state.section);
    } else {
      const { sectionId, templateId } = this.props.match.params;
      getSection(templateId, sectionId).then(section => {
        this.processQuestions(section);
      });
    }
  }

  updateSectionProgress() {
    const { sectionId, templateId, auditId } = this.props.match.params;
    return getSectionProgress(templateId, auditId, sectionId).then(value => {
      if (this.mounted) {
        this.setState({
          sectionProgressValue: value
        });
      }
    });
  }

  processQuestions(section) {
    this.updateSectionProgress();

    const questions = (section.sections.length === 0
      ? section.questions
      : section.sections[0].questions
    ).sort((a, b) => a.rank - b.rank);
    this.setState({
      section: section,
      questions: questions
    });
  }

  componentWillUnmount() {
    this.mounted = false;

    const {
      templateId,
      auditId,
      sectionId,
      parentSectionId
    } = this.props.match.params;

    return updateSectionQuestionsAuditProgress(
      templateId,
      auditId,
      sectionId,
      parentSectionId
    );
  }

  render() {
    const { templateId, auditId, sectionId } = this.props.match.params;

    if (this.state.section) {
      const sectionStyle = {
        title: {
          textAlign: 'left'
        },
        progress: {
          textAlign: 'right'
        }
      };
      return (
        <div className="row" style={{ background: '#E0E0E0' }}>
          <div
            className="col-12"
            style={{
              overflow: 'scroll',
              height: '92vh',
              WebkitOverflowScrolling: 'touch',
              maxWidth: '100vw',
              overflowX: 'hidden'
            }}
          >
            <AuditProgressNav templateId={templateId} auditId={auditId} />

            <div
              className="row text-white pt-2 pb-2"
              style={{ background: '#99CC33', minHeight: '5.5vh' }}
            >
              <div className="col-9" style={sectionStyle.title}>
                <Translation model={this.state.section} property="title" />
              </div>
              <div
                className="col-3"
                style={{ ...sectionStyle.progress, textAlign: 'right' }}
              >
                {this.state.sectionProgressValue != null
                  ? this.state.sectionProgressValue + ' %'
                  : null}
              </div>
            </div>

            <QuestionsContainer
              questions={this.state.questions}
              auditId={auditId}
              handlerQuestionAnswered={(question, answers, active) => {
                saveAnswer(
                  auditId,
                  templateId,
                  question,
                  answers,
                  sectionId
                ).then(() => {
                  return calculateFinalSectionProgress(
                    templateId,
                    auditId,
                    sectionId
                  ).then(sectionProgress => {
                    return saveSectionProgress(
                      auditId,
                      sectionId,
                      sectionProgress
                    ).then(() => {
                      this.updateSectionProgress();
                    });
                  });
                });
              }}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default AuditSectionQuestions;

class QuestionsContainer extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.questions.length !== nextProps.questions.length;
  }

  render() {
    return this.props.questions.map((item, idx) => {
      return (
        <div className="row" key={idx}>
          <div className="col-12 p-0" key={item.id}>
            <FillAuditQuestion
              question={item}
              auditId={this.props.auditId}
              questionId={item.id}
              handlerQuestionAnswered={this.props.handlerQuestionAnswered}
            />
          </div>
        </div>
      );
    });
  }
}

const updateSectionQuestionsAuditProgress = (
  templateId,
  auditId,
  sectionId,
  parentSectionId
) => {
  return calculateFinalSectionProgress(templateId, auditId, sectionId).then(
    sectionProgress => {
      return saveSectionProgress(auditId, sectionId, sectionProgress).then(
        () => {
          if (parentSectionId !== undefined && !isNaN(parentSectionId)) {
            return calculateMiddleSectionProgress(
              templateId,
              auditId,
              parentSectionId
            )
              .then(sectionProgress => {
                return saveSectionProgress(
                  auditId,
                  parentSectionId,
                  sectionProgress
                );
              })
              .then(() => {
                return calculateAuditTotalProgress(auditId).then(() =>
                  EventEmitter.dispatch('progressValueUpdated')
                );
              });
          } else {
            return calculateAuditTotalProgress(auditId).then(() =>
              EventEmitter.dispatch('progressValueUpdated')
            );
          }
        }
      );
    }
  );
};
