import React from 'react';
import QCheckBox from '../components/questions/QCheckBox';
import QRadioButton from '../components/questions/QRadioButton';
import QQuestion from '../components/questions/QQuestion';
import QTextField from '../components/questions/QTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { getAnswerValue } from '../answerValueProvider';
import HtmlViewer from '../../audit/htmlViewer';
import QSlider from '../components/questions/QSlider';
import { getItem } from '../dbProvider';
import Translation from '../components/translation/Translation';

class FillAuditQuestion extends React.Component {
  state = {
    component: null,
    showExtraInfo: false
  };

  componentWillMount() {
    this.questionComponent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.questionId !== nextProps.questionComponent;
  }

  render() {
    const haveExtraInfo =
      this.props.question.extraInfo !== null &&
      this.props.question.extraInfo !== undefined;
    const qComponent = this.state.component;

    const extraInfoRender = haveExtraInfo ? (
      <div className="col-2">
        <div className="sizeIcon">
          <FontAwesomeIcon
            icon={faBookOpen}
            style={iconStyle}
            onClick={() => {
              this.setState({ showExtraInfo: true });
            }}
          />
        </div>
        <HtmlViewer
          htmlContent={
            <Translation
              model={this.props.question}
              property="extraInfo"
              asHtml={true}
            />
          }
          showModal={() => this.state.showExtraInfo}
          closeModal={() =>
            this.setState({
              showExtraInfo: false
            })
          }
        />
      </div>
    ) : null;

    return (
      <div className="row" style={containerStyle}>
        <div className="col-12">
          <div className="row " style={questionTitleStyle}>
            <div className={haveExtraInfo ? 'col-10' : 'col-12'}>
              {<Translation model={this.props.question} property="label" />}
            </div>
            {extraInfoRender}
          </div>

          <div className="row" style={questionStyle}>
            <div className="col-md-12">{qComponent}</div>
          </div>
        </div>
      </div>
    );
  }

  answerFromId = answerId => {
    return this.props.question.answers.find(answer => {
      return answer.id === answerId;
    });
  };

  handleQuestionButtons = (question, answer, extraInfo) => {
    if (extraInfo !== undefined && extraInfo !== null) {
      answer.extraInfo = extraInfo;
    }
    this.props.handlerQuestionAnswered(question, [answer], null);
  };

  handleInputText = (question, answer, extraInfo) => {
    if (extraInfo !== undefined && extraInfo !== null) {
      answer.extraInfo = extraInfo;
    }
    this.props.handlerQuestionAnswered(question, [answer], null);
  };

  handleSingleSelect = (question, answerId, extraInfo) => {
    let answer = this.answerFromId(answerId);
    answer.extraInfo = extraInfo;

    this.props.handlerQuestionAnswered(question, [answer], null);
  };

  handleMultiSelect = (question, answers) => {
    this.props.handlerQuestionAnswered(question, answers, null);
  };

  generateQuestionComponentSwitch(
    questionType,
    question,
    defaultAnswer,
    singleAnswer,
    multipleAnswers
  ) {
    switch (questionType.id) {
      case 1:
      case 2:
        return (
          <QTextField
            hint=""
            question={question}
            initialAnswer={singleAnswer}
            auditId={this.props.auditId}
            handleQuestion={this.handleInputText}
          />
        );

      case 3:
        return (
          <QQuestion
            question={question}
            auditId={this.props.auditId}
            defaultAnswer={defaultAnswer}
            initialAnswer={singleAnswer}
            handleQuestion={this.handleQuestionButtons}
          />
        );

      case 4:
      case 5:
        return (
          <QCheckBox
            question={question}
            auditId={this.props.auditId}
            initialAnswers={multipleAnswers}
            defaultAnswer={defaultAnswer}
            handleQuestion={this.handleMultiSelect}
          />
        );

      case 6:
        return (
          //Slider
          <QSlider
            question={question}
            initialAnswer={singleAnswer}
            auditId={this.props.auditId}
            handleQuestion={this.handleInputText}
          />
        );

      case 7:
      case 8:
        return (
          <QRadioButton
            key={this.props.auditId}
            question={question}
            auditId={this.props.auditId}
            initialAnswer={singleAnswer}
            defaultAnswer={defaultAnswer}
            handleQuestion={this.handleSingleSelect}
          />
        );

      default:
        return (
          <QCheckBox
            question={question}
            auditId={this.props.auditId}
            initialAnswers={multipleAnswers}
            defaultAnswer={defaultAnswer}
            handleQuestion={this.handleMultiSelect}
          />
        );
    }
  }

  generateQuestionComponent(initialAnswers) {
    let question = this.props.question;
    let defaultAnswer = question
      ? question.answers.length
        ? question.answers.find(answer => answer.defaultValue)
        : null
      : null;

    let questionType = question.questionType;
    const singleAnswer =
      initialAnswers && initialAnswers.length > 0 ? initialAnswers[0] : null;

    const multipleAnswers =
      initialAnswers && initialAnswers.length > 0 ? initialAnswers : [];

    if (questionType === 8 && singleAnswer === null && defaultAnswer !== null) {
      return getItem('visitedQuestion', question.id)
        .then(item => {
          if (item !== undefined) {
            defaultAnswer = null;
          }
        })
        .then(() =>
          this.generateQuestionComponentSwitch(
            questionType,
            question,
            singleAnswer,
            multipleAnswers
          )
        );
    } else if (
      (questionType === 4 || questionType === 5) &&
      multipleAnswers.length === 0 &&
      defaultAnswer !== null
    ) {
      return getItem('visitedQuestion', question.id)
        .then(item => {
          if (item !== undefined) {
            defaultAnswer = null;
          }
        })
        .then(() =>
          this.generateQuestionComponentSwitch(
            questionType,
            question,
            defaultAnswer,
            singleAnswer,
            multipleAnswers
          )
        );
    } else {
      return Promise.resolve(
        this.generateQuestionComponentSwitch(
          questionType,
          question,
          defaultAnswer,
          singleAnswer,
          multipleAnswers
        )
      );
    }
  }

  questionComponent() {
    if (this.props.question) {
      const { questionId, auditId } = this.props;
      getAnswerValue(auditId, questionId).then(answers => {
        this.generateQuestionComponent(answers).then(component =>
          this.setState({ component: component })
        );
      });
    }
  }
}
export default FillAuditQuestion;

const containerStyle = {
  background: 'white',
  left: 0,
  marginLeft: 0,
  paddingLeft: 0,
  marginRight: 0
};

const questionTitleStyle = {
  marginBottom: 8,
  textAlign: 'left',
  background: '#EAEAEA',
  color: '#222222',
  paddingTop: '5px',
  paddingBottom: '5px'
};

const questionStyle = {
  marginTop: 6,
  marginBottom: 8,
  textAlign: 'left',
  color: '#454545'
};

const iconStyle = {
  color: '#fff',
  marginTop: 6,
  marginLeft: 6
};
