import React from 'react';
import CountUp from '../components/countUp/countUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import history from '../../../core/router/history';


const AuditProgressNav = ({ templateId, auditId, progressValue }) => {
  return (
    <div className="row text-white" style={containerStyle}>
      <div className="col-4 iconBack">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={() => {
            if (history.location.pathname === '/fillAuditIntro/' + templateId) {
              history.push('/templates/news');
            } else if (
              history.location.pathname ===
              '/fillAuditIntro/' + templateId + '/' + auditId
            ) {
              history.push('/templates/news');
            } else {
              history.goBack();
            }
          }}
        />
      </div>

      <div className="col-4 timeCounter" style={{ textAlign: 'center' }}>
        <CountUp auditId={auditId} />
      </div>
      <div className="col-4" style={{ textAlign: 'right' }}>
        {auditId && progressValue ? progressValue + ' %' : null}
      </div>
    </div>
  );
};
export default AuditProgressNav;

const containerStyle = {
  height: '6.5vh',
  background: '#666666',
  display: 'flex',
  alignItems: 'center'
};
