import React from 'react';
import { getUserCompanies } from '../../../../api/authManager';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/fontawesome-free-solid';
import Literal from '../../../../core/shared/literal';

class ClientInfoRow extends React.Component {
  state = {
    options: [],
    selectedClient: null,
    selectorOpened: false
  };

  componentDidMount() {
    let items = getUserCompanies().map(item => {
      return { value: item.id, label: item.name };
    });

    this.setState({
      options: items,
      selectedClient: this.props.defaultClient
    });
  }

  handleChange = selectedOption => {
    this.setState({ selectedClient: selectedOption });
    this.props.clientSelected(selectedOption);
    this.setState({ modal: false, selectorOpened: false });
  };

  toggle() {
    this.setState({ modal: false });
  }

  selectorModal = () => {
    const selectorHeight = this.state.selectorOpened ? '62vh' : '10vh';
    return (
      <div style={{ overflow: 'scroll', WebkitOverflowScrolling: 'touch' }}>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggle}
          className={this.props.className}
          style={{
            top: '5vh',
            width: '94vw',
            maxWidth: '94vw',
            height: '90vh'
          }}
        >
          <ModalHeader
            toggle={() => {
              this.toggle();
            }}
            style={{ textAlign: 'center' }}
          >
            <Literal tKey="audit_info_client" />
          </ModalHeader>
          <ModalBody>
            <div className="row" style={{ background: '#white' }}>
              <div
                className="col-12"
                style={{
                  overflow: 'scroll',
                  height: selectorHeight,
                  WebkitOverflowScrolling: 'touch'
                }}
              >
                <Select
                  options={this.state.options}
                  onMenuOpen={() => {
                    this.setState({ selectorOpened: true });
                  }}
                  onMenuClose={() => {
                    this.setState({ selectorOpened: false });
                  }}
                  isDisabled={
                    this.props.isEditable !== undefined
                      ? !this.props.isEditable
                      : false
                  }
                  onChange={this.handleChange}
                  value={
                    this.state.selectedClient ? this.state.selectedClient : null
                  }
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  render() {
    return (
      <div className="row " style={containerStyle}>
        <div className="col-12">
          <div className="row ">
            <div className="col-8">
              <div className="row ">
                <div className="col-12" style={titleStyle}>
                  {this.props.title}
                </div>
              </div>

              <div className="row ">
                <div className="col-10 mb-3" style={subtitleStyle}>
                  <input
                    className="noInputBorder"
                    type="text"
                    disabled={true}
                    readOnly="readonly"
                    value={
                      this.state.selectedClient
                        ? this.state.selectedClient.label
                        : ''
                    }
                  />

                  {this.selectorModal()}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="iconBackground">
                <FontAwesomeIcon
                  onClick={() => {
                    if (
                      this.props.isEditable !== undefined
                        ? this.props.isEditable
                        : true
                    ) {
                      this.setState({ modal: true });
                    }
                  }}
                  size="2x"
                  icon={faUserPlus}
                  style={iconStyle}
                />
              </div>
            </div>
          </div>

          <div className="row ">
            <div
              className="col-12"
              style={{ background: '#E0E0E0', height: 2 }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ClientInfoRow;

const containerStyle = {
  background: '#FFFFFF',
  marginTop: 3,
  marginBottom: 8,
  height: 80
};

const titleStyle = {
  color: '#888888',
  textAlign: 'left',
  fontFamily: 'Roboto !important',
  fontSize: '7pt !important',
  fontWeight: 'bold'
};

const subtitleStyle = {
  text: '#99CC33',
  textAlign: 'left',
  marginBottom: 5,
  fontFamily: 'Roboto !important',
  fontSize: '7pt !important',
  fontWeight: '200 !important'
};

const iconStyle = {
  marginTop: '8px',
  marginLeft: '4px'
};
