import React from 'react';
import { getUserCompany, getUsername } from '../../api/authManager';
import { Button } from 'reactstrap';
import { setNavigationTitle } from '../../core/router/navigationBar';
import  Background from '../../assets/fondo.png'

class Home extends React.Component {
  state = {
    company: getUserCompany(),
    username: getUsername()
  };

  componentWillMount() {
    setNavigationTitle('Welcome');
  }

  render() {
    const headerImage = (
      <div style={{ ...style.imageStyle.container, backgroundColor: '#fff', backgroundRepeat: 'no-repeat', backgroundImage: 'url(' + this.state.company.logo+')' }}>
      </div>
    );

    const welcome = (
        <div
          className="w-100 text-white"
          style={{ paddingTop: 25, paddingBottom: 23, background: '#5C7A1F'}}
        >
          <h5> Welcome to App4Check </h5>
          <h5> {this.state.username} </h5>
        </div>
    );

    const bottomButton = (
      <Button style={style.bottomStyle} color="success"
        onClick={() => {
          window.open('http://www.preverisk.com', '_blank');
        }}
      > www.preverisk.com
          </Button>
    );

    return (
      <div style={{backgroundImage: `url(${Background})`, margin: '0px -15px'}}>
       <div className="d-flex flex-column" style={{height: '93vh', alignItems: 'center'}}>
          {headerImage}
          <div className="w-100 d-flex flex-column" style={{ position: 'absolute', bottom: 0, right: 0 }}>
            {welcome}
            {bottomButton}         
          </div>
        </div>
      </div>
    );
  }
}
export default Home;

const style = {
  imageStyle: {
    container: {
      marginTop: '50px',
      height: '35vh',
      width: '35vh',
      textAlign: 'center',
      borderRadius: '50%',
      backgroundPosition: 'center',
      backgroundSize: '70%',
      boxShadow: '1px 1px 10px',


    },
    img: {
      marginTop: '15vh',
      maxWidth: '75vw'
    }
  },
  bottomStyle: {
    width: '100%',
    height: '8vh'
  },
  containerStyle: {
    background: '#99CC33',
    height: '100vh',
    marginBottom: 0
  }
};