import * as Promise from 'bluebird';
import { getToken } from './authManager';
import fetchIntercept from 'fetch-intercept';

import history from '../core/router/history';
import { clearTable, putItem } from '../screens/fillAudit/dbProvider';

const host = process.env.REACT_APP_API_HOST;
const hostApi2 = process.env.REACT_APP_API_HOST_2;

const httpErrorUnauthorizeCode = 401;
const httpErrorCode = 403;

export const webTemplatesTable = 'web-templates';

export const unregister = fetchIntercept.register({
  request: function(url, config) {
    if (config) {
      config.headers = {
        ...config.headers,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      };

      if (getToken() !== false) {
        config.headers = {
          ...config.headers,
          Token: getToken()
        };
      }
    }

    return [url, config];
  },

  requestError: function(error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function(response) {
    // Modify the reponse object
    if (
      response.status === httpErrorUnauthorizeCode ||
      response.status === httpErrorCode
    ) {
      handle403();
      return {};
    } else if (response.status >= 400) {
      return Promise.reject('ERror');
    } else {
      return response;
    }
  },

  responseError: function(error) {
    // Handle an fetch error
    return Promise.reject(error);
  }
});

function handle403() {
  history.push('/login');
}

const uriNotificationsHistory = host + '/notification/history?page=1&size=20';

export function fetchNotifications() {
  return fetchWithTimeout([uriNotificationsHistory, { method: 'get' }]);
}

export function getCountWebTemplates(searchText) {
  return fetchWithTimeout([
    hostApi2 +
      '/References/countForApp' +
      (searchText ? '?name=' + encodeURI(searchText) : ''),
    { method: 'get' }
  ])
    .then(r => {
      if (r !== undefined) {
        return r.json();
      } else {
        return { count: 0 };
      }
    })
    .then(data => {
      return Promise.resolve(data);
    })
    .then(data => {
      return Promise.resolve(data);
    });
}

export function getWebTemplates(itemsCount, offset, searchText) {
  return fetchWithTimeout([
    hostApi2 +
      '/References/listForApp?limit=' +
      itemsCount +
      '&offset=' +
      offset +
      (searchText ? '&name=' + encodeURI(searchText) : ''),
    { method: 'get' }
  ]).then(r => {
    if (r !== undefined) {
      return r.json().then(webTemplates => {
        let promise = null;
        if (offset) {
          promise = Promise.resolve();
        } else {
          promise = clearTable(webTemplatesTable);
        }
        return promise.then(() => {
          return Promise.mapSeries(webTemplates, webTemplate =>
            putItem(webTemplatesTable, webTemplate)
          ).then(() => {
            return webTemplates;
          });
        });
      });
    } else {
      return [];
    }
  });
}

export function fetchWithTimeout(fetchData) {
  const FETCH_TIMEOUT = 200000;
  let didTimeOut = false;

  return new Promise(function(resolve, reject) {
    const timeout = setTimeout(function() {
      didTimeOut = true;
      reject(new Error('Request timed out'));
    }, FETCH_TIMEOUT);

    return fetch(...fetchData)
      .then(function(response) {
        // Clear the timeout as cleanup
        clearTimeout(timeout);
        if (!didTimeOut) {
          resolve(response);
        }
      })
      .catch(function(err) {
        if (didTimeOut) return;
        reject(err);
      });
  });
}
