import React from 'react';

class BasicInfoRow extends React.Component {
  render() {

    const separator = (
      <div className="row" style={separatorStyle.container}>
        <div className="col-12" style={separatorStyle.line} />
      </div>
    );

    return (
      <div className="row">
        <div className="col-12" style={containerStyle}>
          <div className="row">
            <div className="col-12" style={titleStyle}>
              {this.props.title}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12" style={subtitleStyle}>
              {this.props.value}
            </div>
          </div>
          {separator}
        </div>
      </div>
    );
  }
}
export default BasicInfoRow;

const containerStyle = {
  background: '#FFFFFF',
  height: 80,
  marginTop: 3,
  marginBottom: 3
};
const separatorStyle = {
  container: {
    height: 2,
    bottom: 0
  },
  line: {
    background: '#E0E0E0',
    height: 2
  }
};
const titleStyle = {
  color: '#888888',
  textAlign: 'left',
  fontFamily: 'Roboto !important',
  fontSize: '7pt !important',
  fontWeight: 'bold'
};

const subtitleStyle = {
  text: '#99CC33',
  textAlign: 'left',
  fontFamily: 'Roboto !important',
  fontSize: '7pt !important',
  fontWeight: '200 !important'
};