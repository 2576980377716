import React from 'react';
import { getPendingAudits } from '../../fillAudit/auditProvider';
import { ClipLoader } from 'react-spinners';
import SearchBar from '../../../core/shared/searchBar';
import PendingAuditListItem from './pendingAuditListItem';
import { faInbox } from '@fortawesome/fontawesome-free-solid';
import { EmptyView } from '../core/emptyView';
import Literal from '../../../core/shared/literal';
import history from '../../../core/router/history';

class PendingAuditList extends React.Component {
  state = { history: history, dataFetched: false };

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems() {
    getPendingAudits().then(endedAudits => {
      this.setState({
        dataFetched: true,
        audits: endedAudits,
        oldAudits: endedAudits
      });
    });
  }

  searchBarOnChange = value => {
    this.setState({
      audits: this.state.oldAudits.filter(item => {
        return item.title
          .toLowerCase()
          .includes(value.target.value.toLowerCase());
      })
    });
  };

  render() {
    const auditsList = this.state.audits;
    const auditsListView = this.state.audits ? (
      <div className="row">
        <div className="col-12" style={this.listStyle}>
          {auditsList.map(item => (
            <PendingAuditListItem
              key={String(item.id) + '-' + item.startAuditTime}
              audit={item}
              refreshList={() => {
                this.fetchItems();
              }}
            />
          ))}
        </div>
      </div>
    ) : null;

    return (
      <div className="row" style={{ background: '#666666', height: 55 }}>
        <div className="col-12">
          <SearchBar onChange={value => this.searchBarOnChange(value)} />
          <ClipLoader
            sizeUnit={'px'}
            size={70}
            color={'#99CC33'}
            loading={!this.state.dataFetched}
          />

          {auditsList && auditsList.length > 0 ? (
            auditsListView
          ) : this.state.dataFetched ? (
            this.state.oldAudits.length === 0 ? (
              <EmptyView
                icon={faInbox}
                title={<Literal tKey="empty_saved_title" />}
                description={<Literal tKey="empty_saved_subtitle" />}
              />
            ) : (
              <EmptyView
                icon={faInbox}
                title={<Literal tKey="empty_search_title" />}
                description={<Literal tKey="empty_search_subtitle" />}
              />
            )
          ) : null}
        </div>
      </div>
    );
  }
}
export default PendingAuditList;
