import React from 'react';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import AuditInfoModal from '../../audit/auditInfoModal';
import { deleteAudit } from '../../fillAudit/auditProvider';
import { deleteTemplateIfNeeded } from '../../fillAudit/templateProvider';
import { getUserCompany } from '../../../api/authManager';
import history from '../../../core/router/history';

class PendingAuditListItem extends React.Component {
  state = {
    history: history,
    showModal: false
  };

  listStyle = {
    overflow: 'scroll',
    height: 'calc(100vh - 110px)',
    WebkitOverflowScrolling: 'touch'
  };

  toggleModal(evt) {
    evt.stopPropagation();
    this.setState({
      showModal: true
    });
  }

  render() {
    const audit = this.props.audit;
    const refreshList = this.props.refreshList;

    const userCompany = getUserCompany();
    const auditModalData = {
      auditId: audit.primaryKey,
      title: audit.title,
      documentNumber: audit.documentNumber,
      client: audit.clientAudit.label
        ? audit.clientAudit.label
        : userCompany.shortName + ' - ' + userCompany.name,
      started: audit.startDateAudit,
      elapsed: audit.elapsedAuditTime,
      progressValue: audit.auditTotalProgress
    };
    return (
      <div
        className="row mt-2 mb-2"
        style={{
          bottom: 0,
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={evt => {
          this.state.history.push(
            '/fillAuditIntro/' + audit.id + '/' + audit.primaryKey
          );
        }}
      >
        <div className="col-12">
          <div className="row">
            <AuditInfoModal
              auditData={auditModalData}
              showModal={() => this.state.showModal}
              closeModal={() =>
                this.setState({
                  showModal: false
                })
              }
              openAudit={() => {
                this.state.history.push(
                  '/fillAuditIntro/' + audit.id + '/' + audit.primaryKey
                );
              }}
              deleteAudit={() => {
                deleteAudit(audit.primaryKey).then(() => {
                  return deleteTemplateIfNeeded(audit.id).then(() => {
                    refreshList();
                  });
                });
              }}
            />

            <div className="col-2">
              <FontAwesomeIcon
                color={audit.isFinishedAudit ? 'green' : 'red'}
                size="2x"
                icon={faInfoCircle}
                onClick={evt => this.toggleModal(evt)}
              />{' '}
              {}
            </div>

            <div className="col-2">
              <Avatar size="32" src={audit.urlIcon} round={true} />
            </div>

            <div className="col-8" style={titleStyle}>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">{audit.title}</div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-2">{audit.documentNumber}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-12"
              style={{ height: 1, background: '#777777' }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default PendingAuditListItem;

const titleStyle = {
  fontSize: 12,
  textAlign: 'left'
};
