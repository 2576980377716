import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStopCircle,
  faMicrophone,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

window.MediaRecorder = require('audio-recorder-polyfill')

class AudioRecorder extends React.Component {
  constructor(props) {
    super(props);
    this.saveFile = this.saveFile.bind(this);
  }

  state = {
    record: {
      status: 'stop',
      file: null,
      base64: null
    }
  };

  recordButtonTapped() {
    const status = this.state.record.status;
    if (status === 'stop') {
      this.start();

      let newRecord = this.state.record;
      newRecord.status = 'recording';
      this.setState({
        record: newRecord
      });
    } else if (status === 'recording') {
      this.stop();
      let newRecord = this.state.record;
      newRecord.status = 'stop';
      this.setState({
        record: newRecord
      });
    }
  }

  recorder = null;

  start() {
    let newRecord = this.state.record;
    newRecord.base64 = null;
    this.setState({
      record: newRecord
    });

    // Request permissions to record audio
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.recorder = new MediaRecorder(stream)

      this.recorder.addEventListener('dataavailable', e => {
        let audio = {}
        audio.src = URL.createObjectURL(e.data)
        const blob = e.data;
        var file = {};
        file.file = blob;
        file.name =
          parseInt(Math.floor(Math.random() * 99999999) + 1, 10) + '.wav';
        file.size = blob.size;
        file.type = 'audio/wav';
        this.saveFile(file, blob);
        this.chunks = [];
      });

      this.recorder.start()
    });
  }

  stop() {
    this.recorder.stop()
    this.recorder.stream.getTracks().forEach(i => i.stop())
  }

  saveFile(file, blob) {
    const save = (base64data, file) => {
      this.updateAudioExtraValue(base64data, file);
    };
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      save(base64data, file);
    };
  }

  updateAudioExtraValue(base64, file) {
    const { saveAudio } = this.props;
    const audioData = {
      status: 'stop',
      base64: base64,
      file: file,
      filename: file.name,
      filesize: file.size,
      filetype: file.type
    };

    let newRecord = this.state.record;
    newRecord.base64 = audioData.base64;
    newRecord.file = audioData.file;
    this.setState({
      record: newRecord
    });

    saveAudio(audioData);
  }

  stream = null;
  
  componentDidUpdate(prevProps) {
    if (
      this.props.command &&
      this.props.command !== 'none' &&
      prevProps.command !== this.props.command
    ) {
      this[this.props.command]();
    }
  }

  componentWillUnmount() {
    if (this.stream !== null) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }

  render() {
    const { saveAudio } = this.props;
    const recordState = this.state.record.status;
    const base64 =
      this.state.record !== null
        ? this.state.record.base64 !== null
          ? this.state.record.base64 !== ''
            ? this.state.record.base64
            : null
          : this.props.audioData
            ? this.props.audioData.base64
            : null
        : null;

    const buttonRecord =
      base64 === null || base64 === undefined ? (
        <div className="iconBackground microphone">
          <FontAwesomeIcon
            size="2x"
            style={recordState !== 'recording' ? iconStyle : iconStop}
            icon={recordState !== 'recording' ? faMicrophone : faStopCircle}
            onClick={evt => this.recordButtonTapped(evt)}
          />
        </div>
      ) : null;

    const player =
      base64 !== null && base64 !== undefined && base64 !== '' ? (
        <audio src={base64} controls style={playerStyles} />
      ) : null;

    const trashButton =
      base64 !== null && base64 !== undefined ? (
        <div className="row">
          <div
            className="col-12 d-flex justify-content-center"
            style={marginTrash}
          >
            <div className="iconBackground">
              <FontAwesomeIcon
                size="2x"
                icon={faTrash}
                style={iconStyleTrash}
                onClick={() => {
                  let newRecord = this.state.record;
                  newRecord.status = 'stop';
                  newRecord.base64 = '';

                  this.setState({
                    record: newRecord
                  });
                  saveAudio(null);
                }}
              />
            </div>
          </div>
        </div>
      ) : null;

    return (
      <React.Fragment>
        {buttonRecord}
        <div className="row">
          <div className="col-12" style={player ? players : null}>
            {trashButton}
            {player}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AudioRecorder;
const iconStyle = {
  marginTop: '8px',
  marginLeft: '14px'
};

const iconStop = {
  marginTop: '9px',
  marginLeft: '9px'
};

const iconStyleTrash = {
  marginLeft: '11px',
  marginTop: '8px'
};

const playerStyles = {
  width: '200px',
  marginTop: '10px'
};

const players = {
  marginTop: '10px',
  border: '1px solid #99CC33',
  borderRadius: '5px',
  marginLeft: '5px'
};

const marginTrash = {
  marginBottom: '10px'
};