import {
  getDBTable,
  getDBTableKeys,
  getItem,
  putItem,
  deleteItem
} from './dbProvider';
import { existAuditForTemplate } from './auditProvider';
import { fetchWithTimeout } from '../../api/apiClient';
import * as Promise from 'bluebird';

const templateTable = 'template';
const templateDataTable = 'template-data';
const templateReferenceTable = 'template-reference';

// LIST
function getLocalTemplatesIds() {
  return getDBTableKeys(templateTable);
}

export function fetchTemplatesList() {
  return getLocalTemplatesIds().then(localIds => {
    const idList = localIds.map(value => ({
      id: value
    }));

    return fetchWithTimeout([
      process.env.REACT_APP_API_HOST + '/template/updateList',
      {
        method: 'post',
        body: JSON.stringify({ templates: idList })
      }
    ])
      .then(response => {
        if (response !== undefined) {
          return response.json();
        } else {
          return { new: [], old: [] };
        }
      })
      .then(data => {
        if (data) {
          data.new.forEach(template => {
            saveNewTemplate(template);
          });
          data.old.forEach(template => {
            deleteTemplate(template.id);
          });
        }
        return getLocalTemplates();
      })
      .catch(() => {
        return getLocalTemplates();
      });
  });
}

function getLocalTemplates() {
  return getDBTable(templateTable).then(localTemplates => {
    return Promise.resolve([...localTemplates]);
  });
}

// TEMPLATE ITEM
function getTemplateHeader(templateId) {
  return getItem(templateTable, templateId);
}

export function getTemplateData(templateId) {
  return getItem(templateDataTable, parseInt(templateId));
}

function getTemplate(templateId) {
  return getTemplateData(templateId).then(template => {
    if (template === undefined) {
      // return downloadTemplate(templateId);
    } else {
      return Promise.resolve(template);
    }
  });
}

// TEMPLATE  ATTRIBUTES GETTERS
export function getTemplateTitle(templateId) {
  return getTemplate(parseInt(templateId, 10)).then(template => {
    return Promise.resolve(template.title);
  });
}

export function templateDataExistInLocal(templateId) {
  return getTemplate(templateId).then(template => {
    return Promise.resolve(template !== undefined);
  });
}

export function templateReferenceActive(itemId) {
  return getItem(templateReferenceTable, itemId).then(response => {
    if (response !== undefined) {
      return templateActive(response.templateId);
    } else {
      return false;
    }
  });
}

export function templateActive(templateId) {
  return getItem(templateTable, templateId).then(template => {
    return Promise.resolve(
      template.localStatus !== undefined
        ? template.localStatus !== 'pendingDelete'
          ? true
          : false
        : true
    );
  });
}

// DOWNLOAD

export function downloadTemplate(templateId) {
  return fetchWithTimeout([
    process.env.REACT_APP_API_HOST + '/template/loadTranslated/' + templateId,
    {
      method: 'get'
    }
  ])
    .then(response => {
      if (response !== undefined) {
        return response.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (data !== null) {
        let template = { ...data, localStatus: 'active' };
        putItem('template', template);
        return saveNewTemplateDataInDatabase(template).then(success => {
          return saveNewTemplateAssetsInCache(template).then(success => {
            return Promise.resolve(template);
          });
        });
      } else {
        return Promise.resolve(null);
      }
    });
}

export function downloadReferenceTemplate(itemId, templateId, referenceId) {
  const data = {
    templateId: templateId,
    referenceId: referenceId,
    id: itemId,
    active: true
  };
  return putItem(templateReferenceTable, data).then(() => {
    return downloadTemplate(templateId);
  });
}

// SAVE
function saveNewTemplate(template) {
  putItem('template', template);
}

function saveNewTemplateDataInDatabase(template) {
  return getTemplateHeader(template.id).then(tem => {
    // tem.localStatus ? delete tem.localStatus : null;

    if (tem === undefined) {
      // template downloaded from reference template
      tem = {
        title: template.title,
        id: template.id,
        active: true
      };
    }
    return putItem(templateTable, tem).then(() => {
      return putItem(templateDataTable, template).then(() => {
        return Promise.resolve(true);
      }, template);
    }, template);
  }, template);
}

function saveNewTemplateAssetsInCache(template) {
  const allExtraInfo = getAllExtraInfoForTemplate(template).filter(
    i => i !== null
  );
  return extractExtraInfoPaths(allExtraInfo).then(allExtraAssetsPath => {
    if ('caches' in window && allExtraAssetsPath) {
      return caches.open('sw-precache-webpack-plugin').then(function(cache) {
        return cache.addAll(allExtraAssetsPath).then(function() {
          return true;
        });
      });
    } else {
      return true;
    }
  });
}

// EXTRA INFO

function extractExtraInfoPaths(list) {
  return Promise.reduce(
    list,
    (output, asset) => {
      const re = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g;
      const array = asset.match(re);
      return [...output, ...array];
    },
    []
  )
    .then(output => {
      return output.filter(path => path !== null);
    })
    .catch(() => {
      return null;
    });
}

function getAllExtraInfoForTemplate(template) {
  const extraInfos = template.sections.reduce((allExtraInfos, section) => {
    let sectionExtraInfos = section.questions.map(
      question => question.extraInfo
    );
    const subssectionExtraInfos = section.sections.reduce(
      (allSubExtraInfos, section) => {
        let list = section.questions.map(question => question.extraInfo);
        return list.filter(extraInfo => extraInfo !== null);
      },
      []
    );

    sectionExtraInfos = [...sectionExtraInfos, ...subssectionExtraInfos];

    return [...allExtraInfos, ...sectionExtraInfos];
  }, []);

  return extraInfos;
}

export function deleteTemplate(templateId) {
  return getTemplate(templateId).then(tmp => {
    return existAuditForTemplate(templateId).then(exist => {
      if (exist) {
        let template = { ...tmp, localStatus: 'pendingDelete' };
        putItem('template', template);
        return true;
      } else {
        deleteItem('template', templateId);
        deleteItem('template-data', templateId);
        return true;
      }
    });
  });
}

export function deleteTemplateIfNeeded(templateId) {
  return getItem('template', templateId).then(template => {
    if (
      template &&
      template.localStatus &&
      template.localStatus === 'pendingDelete'
    ) {
      return deleteTemplate(templateId);
    } else {
      return true;
    }
  });
}
