var variableColors = {
  backgroundGrey: '#F3F3F3',
  backgroundWhite: '#FFFFFF',
  templateColor: '#99CC33'
};
var QuestionType = {
  text: { id: 1, type: 'text' },
  numeric: { id: 2, type: 'numeric' }
};
export { variableColors, QuestionType };
