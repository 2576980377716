import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import { Button } from 'reactstrap';
import SmartQuestion from '../smartQuestion/smartQuestion';
import SubQuestion from '../subquestion/subQuestion';
import Translation from '../translation/Translation';

class QSlider extends React.Component {
  state = {
    selectedAnswerId: null,
    subQuestion: null,
    subquestionData: null,
    smartQuestion: null,
    smartQuestionData: null,
    answer: {},
  };
  componentWillMount() {
    if (this.props.initialAnswer !== null) {
      this.setState({
        selectedAnswerId: this.props.initialAnswer.answer.id
      });
    }
    let answer = this.props.initialAnswer;
    if (answer) {
      this.setState(
        {
          answer
        },
        () => {
          if (answer !== null) {
            this.updateViewsIfNeeded(answer.answer, true);
          }
        }
      );
    }
  }

  updateViewsIfNeeded(data, state) {
    this.hideSmartQuestion();
    this.hideSubQuestion();
    this.setState({
      answer: data,
      showSmartQuestion: false,
      subQuestion: null,
      subquestionData: null
    });
    if (state) {
      if (data.requiredInfo !== undefined && data.requiredInfo) {
        this.showSubQuestion();
      }
      if (data.section && data.section !== null) {
        this.showSmartQuestion(data);
      }
    } else {
      data.extraInfo = null;
    }
  }

  handleTextFieldChange = event => {
    this.setState({
      textFieldValue: event.target.value
    });
  }

  showSmartQuestion(data) {
    if (data !== null) {
      const smartQuestion = (
        <SmartQuestion
          section={data.section}
          answerId={data.answerId}
          auditId={this.props.auditId}
          showModal={() => this.state.showSmartQuestion}
          closeModal={() => {
            this.setState({
              showSmartQuestion: false
            });
          }}
        />
      );
      this.setState({
        smartQuestion: smartQuestion
      });
    }
  }
  hideSmartQuestion() {
    this.setState({
      smartQuestionData: null,
      smartQuestion: null
    });
  }
  showSubQuestion(answer) {
    const { handleQuestion, question } = this.props;
    const textFieldValue = answer.extraInfo ? answer.extraInfo.comment : '';

    const subQuestion = (
      <Translation
        model={answer}
        property="sublabel"
        nest={sublabel => (
          <SubQuestion
            answer={answer}
            answerId={answer.answerId}
            label={sublabel}
            textValue={textFieldValue}
            handleSubQuestion={extraInfo => {
              let subquestionData = extraInfo;
              this.setState({
                subquestionData: subquestionData
              });
              answer.extraInfo = subquestionData;
              handleQuestion(question, answer, subquestionData);
            }}
          />
        )}
      />
    );

    this.setState({
      subQuestion: subQuestion
    });
  }

  hideSubQuestion() {
    this.setState({
      subQuestionData: null,
      subQuestion: null
    });
  }

  handleButton(evt, answer) {
    const { handleQuestion, question } = this.props;

    this.setState({
      selectedAnswerId: answer.id,
      subquestionData: null
    });

    this.updateViewsIfNeeded(answer, true);

    if (answer.section && answer.section !== null) {
      this.setState({
        showSmartQuestion: true
      });
    }

    handleQuestion(question, answer, this.state.subquestionData);
  }

  handleFormSubmit(evt) {
    evt.preventDefault();
  }

  onSliderChange = value => {
    const { handleQuestion, question } = this.props;
    const answer = this.state.answer;
    answer.scoring = parseInt(value, 10);
    this.setState(
      {
        answer
      },
      () => {
        handleQuestion(question, answer);
      }
    );
  };

  render() {
    const { question } = this.props;
    let minValue =
      question.answers.length > 0 ? question.answers[0].scoring : 0;
    let maxValue =
      question.answers.length > 0
        ? question.answers[question.answers.length - 1].scoring
        : 0;
    const { subQuestion, smartQuestion } = this.state;
    const viewSmartQuestionButton = (
      <Button
        color="info"
        onClick={() => {
          this.showSmartQuestion(this.state.answer);

          this.setState({
            showSmartQuestion: true
          });
        }}
      >
        {' '}
        Info{' '}
      </Button>
    );

    const coloursLegendDiv = (answers, minValue, maxValue) => {
      const interval = maxValue - minValue;
      return (
        <div className="legend">
          {answers.map((answer, idx) => {
            const colour = answer.colour || '#99CC32';
            let width;
            if (idx < answers.length - 1) {
              width =
                ((question.answers[idx + 1].scoring - answer.scoring) /
                  interval) *
                100;
            }
            return (
              <div
                className="legend-item"
                key={answer.id}
                style={{ backgroundColor: colour, width: width + '%' }}
              />
            );
          })}
        </div>
      );
    };

    return (
      <div className="row">
        <div className="col-12">
          <form
            onSubmit={evt => {
              this.handleFormSubmit(evt);
            }}
          >
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 pt-2 ml-1 ml-1">{smartQuestion}</div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12" align="center">
                        {this.state.answer.scoring != null
                          ? this.state.answer.scoring
                          : minValue}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <Slider
                          min={minValue}
                          max={maxValue}
                          value={
                            this.state.answer &&
                            this.state.answer.scoring != null
                              ? this.state.answer.scoring
                              : minValue
                          }
                          onChange={this.onSliderChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6" align="left">
                        {minValue}
                      </div>
                      <div className="col-6" align="right">
                        {maxValue}
                      </div>
                    </div>
                  </div>

                  <div className="row w-100">
                    <div className="col-12">
                      {coloursLegendDiv(question.answers, minValue, maxValue)}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 pt-2 ml-1 ml-1">
                      {subQuestion}
                      {smartQuestion !== null ? viewSmartQuestionButton : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default QSlider;
