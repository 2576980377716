import React from 'react';
import Avatar from 'react-avatar';
import { variableColors } from '../../../core/modules/variableColors';
import {
  templateDataExistInLocal,
  deleteTemplate,
  templateActive
} from '../../fillAudit/templateProvider';
import DownloadTemplateButton from '../../../core/shared/downloadTemplateButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlay } from '@fortawesome/fontawesome-free-solid';
import { toast, ToastContainer } from 'react-toastify';
import history from '../../../core/router/history';


class TemplateListItem extends React.Component {
  state = {
    templateDownloaded: null
  };

  componentDidMount() {
    this.checkTemplateDownloaded();
  }

  checkTemplateDownloaded() {
    this.setState(
      {
        templateDownloaded: null
      },
      () => {
        templateDataExistInLocal(this.props.template.id).then(exist => {
          if (exist) {
            templateActive(this.props.template.id).then(active => {
              this.setState({
                templateDownloaded: active
              });
            });
          } else {
            this.setState({
              templateDownloaded: false
            });
          }
        });
      }
    );
  }

  render() {
    const template = this.props.template;
    const actionButton =
      this.state.templateDownloaded === null ? null : this.state
          .templateDownloaded ? (
        <StartButton
          templateId={template.id}
          templateDeleted={() => {
            this.checkTemplateDownloaded();
          }}
        />
      ) : (
        <DownloadTemplateButton
          templateId={template.id}
          notifyMessage={msg => {
            toast.error(msg, {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              autoClose: 2000
            });
          }}
          templateDownloaded={() => {
            this.checkTemplateDownloaded();
          }}
        />
      );

    return (
      <div className="row " style={{ ...style.container }}>
        <div
          className="col-12"
          style={{
            background:
              this.props.index % 2
                ? variableColors.backgroundWhite
                : variableColors.backgroundGrey
          }}
        >
          <div className="row" style={{ padding: '10px 0' }}>
            <div className="col-2">
              <div className="d-flex justify-content-center align-items-center">
                <Avatar size="32" src={template.urlIcon} round={true} />
              </div>
            </div>

            <div className="col-7" style={style.title}>
              <div className="row">
                <div className="col-12" style={{ fontWeight: 'bold' }}>
                  {template.title}
                </div>
              </div>

              <div className="row">
                <div className="col-12">{template.documentNumber}</div>
              </div>
            </div>

            <div className="col-3">
              <div className="d-flex justify-content-center align-items-center h-100">
                {actionButton}
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  }
}
export default TemplateListItem;

const style = {
  container: {
    alignItems: 'center'
  },
  title: {
    fontSize: 12,
    textAlign: 'left'
  },
  startButton: {
    background: variableColors.templateColor,
    height: 32,
    fontSize: 12
  },
  separator: {
    height: 1,
    background: '#777777'
  },
  icon: {
    marginLeft: '6px',
    marginRight: '6px'
  }
};

function StartButton(props) {
  return (
    <div className="row">
      {/* <Button
        style={style.startButton}
        onClick={() => {
          history.push('/fillAuditIntro/' + props.templateId);
        }}
      >
        <Literal tKey="start" uppercase={true} />
      </Button> */}

      <div className="iconPlay">
        <FontAwesomeIcon
          onClick={() => {
            history.push('/fillAuditIntro/' + props.templateId);
          }}
          size="1x"
          icon={faPlay}
          style={style.icon}
        />
      </div>
      <div className="iconTrash">
        <FontAwesomeIcon
          onClick={() => {
            deleteTemplate(props.templateId).then(success => {
              if (success) {
                props.templateDeleted();
              }
            });
          }}
          size="1x"
          icon={faTrash}
          style={style.icon}
        />
      </div>
    </div>
  );
}
