import React from 'react';
import { requestRecover } from '../../api/authManager';
import { Button } from 'reactstrap';
import { uriLogin } from '../../core/router/appRouter';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require('../../assets/login-bg.png');

class Recover extends React.Component {
  state = {
    redirectToLogin: false
  };

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  }

  notify = message =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      autoClose: 1100
    });

  doRecoverAccount = () => {
    let email = this.state.email;

    if (!this.validateEmail(email)) {
      this.notify('Invalid email');
    } else {
      requestRecover(email)
        .then(res => {
          this.setState({ redirectToLogin: true });
        })
        .catch(error => {
          console.log(error);
          this.notify(error.message);
        });
    }
  };

  render() {
    const containerStyle = {
      height: '100vh',
      background: '#009310'
    };

    const floatContainerStyle = {
      display: 'flex',
      alignItems: 'center',
      marginTop: 20
    };

    const inputStyle = {
      height: 52,
      marginBottom: 20,
      marginTop: 0
    };

    const buttonStyle = {
      height: 52,
      marginTop: 20,
      fontWeight: 'bold',
      background: '#99CC33',
      color: '#FFFFFF',
    };

    const titleStep = {
      textAlign: 'left'
    };

    const backButtonStyle = {
      height: 52,
      marginBottom: 7,
      marginTop: 5,
   
    };

    const number = {
      border: '2px solid #fff',
      borderRadius: 50,
      width: 45,
      height: 45,
      verticalAlign: 'center',
      fontSize: 28,
      marginRigth: 0
    }

    const logoPassword = {
      opacity: 0.5,
      marginBottom: 20
      
    }

    const titleInput = {
      marginTop: 20,
    }

    const titleRecover = {
      fontWeight: 'bold',
      color: '#EAEAEA',
      marginBottom: 10
    }

    const logoUrl = require('../../assets/logo_blanco.png');

    // Redirect
    const { redirectToLogin } = this.state;
    if (redirectToLogin) {
      return <Redirect to={uriLogin} />;
    }

    return (
      <div className="row text-white" style={containerStyle}>
        <div className="col-12 pt-5 safariBackground">
          <div style={logoPassword}>
            <img src={logoUrl} alt="Logo" width="128" height="128" />
          </div> 
          <div className="row">
            <div className="col-12 ">
              <div className="row">
                <div className="col-12">
                  <h3 style={titleRecover}>Recover Password</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-12" style={titleInput}>
                  <div className="row">
                    <div className="col-2">
                      <p style={number}>1</p>
                    </div>  
                    <div className="col-10" style={titleStep}>
                      Write your email and click: <p style={titleRecover}>SEND</p>
                    </div>  
                  </div>  
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <input
                    placeholder= "Email"
                    type="text"
                    style={inputStyle}
                    className="form-control"
                    id="email"
                    onChange={value => {
                      this.setState({
                        email: value.target.value.toLowerCase()
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-2">
                      <p style={number}>2</p>
                    </div> 
                    <div className="col-10" style={titleStep}>
                        You will receive an e-mail with te instruction for retrying
                        your password
                    </div>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-12" style={floatContainerStyle}>
                  <Button
                    className="w-100"
                    style={buttonStyle}
                    onClick={() => {
                      this.doRecoverAccount();
                    }}
                  >
                    SEND{' '}
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className="col-12" style={backButtonStyle}>
                  <Button
                    className="w-100"
                    style={{ ...buttonStyle, background: '#666666', marginTop: 10}}
                    onClick={() => {
                      this.setState({
                        redirectToLogin: true
                      });
                    }}
                  >
                    Back to login{' '}
                  </Button>

                  <div>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Recover;
