import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FillAuditQuestion from '../../question/fillAuditQuestion';
import { saveAnswer } from '../../answerValueProvider';

class SmartQuestion extends React.Component {
  state = {
    showModal: false,
    questions: [],
    answers: []
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.setState({
      modal: this.props.showModal()
    });
  }

  componentDidMount() {
    const { section } = this.props;
    this.processQuestions(section);
  }

  toggle() {
    const showModal = !this.state.showModal;
    this.setState(
      {
        modal: showModal
      },
      this.props.closeModal()
    );
  }

  processQuestions(section) {
    const questions = (section.sections.length === 0
      ? section.questions
      : section.sections[0].questions
    ).sort((a, b) => a.rank - b.rank);
    this.setState({
      section: section,
      questions: questions
    });
  }

  render() {
    const { auditId } = this.props;

    const showModal =
      this.props &&
      this.props.showModal() !== undefined &&
      this.props.showModal();

    return (
      <div style={{ overflow: 'scroll', WebkitOverflowScrolling: 'touch' }}>
        <Modal
          isOpen={showModal}
          toggle={this.toggle}
          className={this.props.className}
          style={{ top: '5vh', width: '94vw', maxWidth: '94vw' }}
        >
          <ModalHeader toggle={this.toggle} style={{ textAlign: 'center' }}>
          </ModalHeader>
          <ModalBody>
            <div className="row" style={{ background: '#E0E0E0' }}>
              <div
                className="col-12"
                style={{
                  overflow: 'scroll',
                  maxHeight: '92vh',
                  WebkitOverflowScrolling: 'touch'
                }}
              >
                {this.state.questions.map((item, key) => {
                  return (
                    <div className="row" key={'smart-' + key}>
                      <div className="col-12 p-0" key={item.id}>
                        <FillAuditQuestion
                          question={item}
                          auditId={auditId}
                          questionId={item.id}
                          handlerQuestionAnswered={(
                            question,
                            answers,
                            active
                          ) => {
                            saveAnswer(auditId, null, question, answers, this.props.section.id);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default SmartQuestion;
