import { getDBTable, putItem } from './dbProvider';
import * as Promise from 'bluebird';
import { fetchWithTimeout } from '../../api/apiClient';

const languageTable = 'language';

export function getLanguages() {
  return getDBTable(languageTable).then(languages => {
    let promise = null;
    if (languages && languages.length) {
      promise = Promise.resolve(languages);
    } else {
      promise = fetchLanguages().then(languages => {
        return Promise.each(languages, language => {
          return putItem(languageTable, language);
        }).then(() => {
          return Promise.resolve(languages);
        });
      });
    }
    return promise;
  });
}

function fetchLanguages() {
  return fetchWithTimeout([
    process.env.REACT_APP_API_HOST + '/language/list/',
    {
      method: 'get'
    }
  ])
    .then(response => response.json())
    .catch(error => {
      console.error(error);
      return [];
    });
}
