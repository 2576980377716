const EventEmitter = {
  events: {},
  dispatch: function(event, data) {
    if (!this.events[event]) return;

    this.events[event].forEach(cb => cb(data));
  },
  subscribe: function(event, cb) {
    this.events[event] = [];
    this.events[event].push(cb);
  }
};

module.exports = { EventEmitter };
