import React from 'react';
import { TabContent, Nav, NavItem, NavLink, Label } from 'reactstrap';
import Literal from '../../../core/shared/literal';
import { isHotelCompany } from '../../../core/utils/utils';
import history from '../../../core/router/history';
import styles from './tabBarAuditIntro.module.css';
import { LanguageContext } from '../../../language.context';
import { getTemplateData } from '../templateProvider';

class TabBarAuditIntro extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.languageSelector = this.languageSelector.bind(this);
    this.state = {
      activeTab: props.auditId === undefined ? '1' : '2',
      lastTabTapped: props.auditId === undefined ? '1' : '2',
      history: history
    };
  }

  componentDidMount() {
    let activeTab = this.state.lastTabTapped ? this.state.lastTabTapped : '1';
    this.toggle(activeTab);
    getTemplateData(this.props.templateId).then(template => {
      this.setState({ template });
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  languageSelector({ languages, selectedLanguage, setSelectedLanguage }) {
    let result = null;
    if (languages && this.state.template) {
      const template = this.state.template;
      const filteredLanguages = languages.filter(language => {
        return (
          language.id == template.defaultLanguageId ||
          !!template.translations.find(function(translation) {
            return translation.languageId == language.id && translation.title;
          })
        );
      });
      if (!this.state.selectedLanguage) {
        let selectedLanguage = template.defaultLanguageId;
        try {
          const browserLanguageCode = window.navigator.language.substr(0, 2);
          const browserLanguage = languages.find(
            language => language.code == browserLanguageCode
          );
          if (browserLanguage) {
            selectedLanguage = browserLanguage.id;
          }
        } catch (e) {}
        this.setState({ selectedLanguage });
        setSelectedLanguage(selectedLanguage);
      }
      result = filteredLanguages && selectedLanguage && (
        <select
          onChange={event => {
            setSelectedLanguage(event.target.value);
          }}
          value={selectedLanguage}
        >
          {filteredLanguages.map(language => (
            <option key={language.id} value={language.id}>
              {language.code}
            </option>
          ))}
        </select>
      );
    }
    return result;
  }

  render() {
    const infoLink = (
      <NavItem key={'infoLink' + this.props.auditId}>
        <NavLink
          className={this.state.activeTab === '1' ? 'active' : ''}
          onClick={() => {
            this.toggle('1');
          }}
        >
          <Label style={{ color: '#666666' }}>
            <Literal tKey="audit_information_title" uppercase={true} />
          </Label>
        </NavLink>
      </NavItem>
    );

    const sectionLink = (
      <NavItem key={'sectionLink' + this.props.auditId}>
        <NavLink
          className={this.state.activeTab === '2' ? 'active' : ''}
          onClick={() => {
            this.toggle('2');
          }}
        >
          <Label style={{ color: '#666666' }}>
            <Literal tKey="audit_form_title" uppercase={true} />
          </Label>
        </NavLink>
      </NavItem>
    );

    return (
      <div className={`row ${styles.navStyle}`}>
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Nav tabs>
                {this.props.auditId !== undefined
                  ? isHotelCompany()
                    ? [infoLink, sectionLink]
                    : [sectionLink]
                  : [infoLink]}
                {this.props.tabPanels && this.props.tabPanels.length > 1 ? (
                  <div className={styles.languageSelector}>
                    <LanguageContext.Consumer>
                      {this.languageSelector}
                    </LanguageContext.Consumer>
                  </div>
                ) : null}
              </Nav>
            </div>
          </div>

          <div className={`row ${styles.tabStyle}`}>
            <div className="col-12">
              <TabContent activeTab={this.state.activeTab}>
                {this.props.tabPanels}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TabBarAuditIntro;
