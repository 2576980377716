import React from 'react';
import TextField from './TextField';
import { QuestionType } from '../../../../core/modules/questionType';

class QTextField extends React.Component {
  state = {
    subQuestion: null,
    answer: {
      text: null
    },
    inputType: 1 // {id: 1, type: text }
  };

  componentWillMount() {
    if (this.props.initialAnswer === null) {
      let answer = this.state.answer;
      answer.id = Math.floor(Math.random() * 10000) + 1;
      this.setState({
        answer: answer
      });
    } else {
      this.setState({
        answer: this.props.initialAnswer.answer
      });
    }
    if (this.props.question) {
      this.setState({
        inputType: this.props.question.questionType
      });
    }
  }

  inputTextChange(textValue) {
    const { handleQuestion, question } = this.props;
    let answer = this.state.answer;
    let questionType = this.state.inputType;
    if (questionType.id === QuestionType.numeric.id) {
      answer.scoring = parseFloat(textValue, 10);
      answer.text = textValue;
    } else {
      answer.text = textValue;
    }
    this.setState({
      answer: answer
    });
    handleQuestion(question, answer);
  }

  render() {
    const textChange = text => this.inputTextChange(text);
    var defaultText = this.state.answer.text;
    // let inputmode = this.state.inputType.type;
    let questionType = this.state.inputType;
    let isNumeric = questionType.id === QuestionType.numeric.id;
    return (
      <div>
        <form>
          <div>
            <TextField
              hint={this.props.hint}
              isNumeric={isNumeric}
              defaultValue={
                defaultText !== undefined ? defaultText : this.props.hint
              }
              handleAnswerValue={text => textChange(text)}
            />
          </div>
        </form>
      </div>
    );
  }
}
export default QTextField;
