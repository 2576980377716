import React from 'react';
import { Button } from 'reactstrap';
import { getFinishedAudits } from '../../fillAudit/auditProvider';
import FinishedAuditListItem from './finishedAuditListItem';
import { faInbox } from '@fortawesome/fontawesome-free-solid';

import { ClipLoader } from 'react-spinners';
import SearchBar from '../../../core/shared/searchBar';
import { uploadAudit } from '../../audit/syncManager';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { EmptyView } from '../core/emptyView';
import Literal from '../../../core/shared/literal';
import { deleteTemplateIfNeeded } from '../../fillAudit/templateProvider';
import { isHotelCompany } from '../../../core/utils/utils';
import * as Promise from 'bluebird';
import history from '../../../core/router/history';

class FinishedAuditList extends React.Component {
  state = { history: history, dataFetched: false };

  componentDidMount() {
    this.refreshAudits();
  }

  render() {
    const auditsList = this.state.audits;
    const auditsListView = auditsList ? (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12" style={this.listStyle}>
              {auditsList.map((item, key, array) => {
                return (
                  <FinishedAuditListItem
                    audit={item}
                    key={key}
                    checked={
                      this.state.itemsStatus
                        ? this.state.itemsStatus[key]
                        : false
                    }
                    onChange={() => {
                      this.canActiveAudit(item.auditTotalProgress).then(
                        canActive => {
                          if (canActive) {
                            //update data
                            let newItemsStatus = this.state.itemsStatus
                              ? this.state.itemsStatus
                              : new Array(array.length).fill(false);
                            newItemsStatus[key] = !newItemsStatus[key];
                            this.setState({
                              itemsStatus: newItemsStatus
                            });
                          } else {
                            toast.error(
                              <Literal tKey="syncronize_validation_progress_error" />,
                              {
                                position: toast.POSITION.BOTTOM_CENTER,
                                hideProgressBar: true,
                                autoClose: 2000
                              }
                            );
                          }
                        }
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="row fixed-bottom m-0 p-0">
            <div className="col-6 m-0 p-0">
              <Button
                className="w-100"
                style={firstBottomButtonStyle}
                onClick={() => {
                  return Promise.map(this.state.audits, audit => {
                    return this.canActiveAudit(audit.auditTotalProgress).then(
                      canActivate => {
                        if (canActivate) {
                          return true;
                        } else {
                          toast.error(
                            <Literal tKey="syncronize_validation_progress_error" />,
                            {
                              position: toast.POSITION.BOTTOM_CENTER,
                              hideProgressBar: true,
                              autoClose: 2000
                            }
                          );
                          return false;
                        }
                      }
                    );
                  }).then(status => {
                    this.setState({
                      itemsStatus: status
                    });
                  });
                }}
              >
                <Literal tKey="audits_finished_select_all" />
              </Button>
            </div>

            <div className="col-6 m-0 p-0">
              <Button
                className="w-100"
                style={secondBottomButtonStyle}
                onClick={() => this.uploadAudits()}
              >
                <Literal tKey="audits_finished_syncronize" />
              </Button>
            </div>
          </div>
        </div>
        <div>
          <ToastContainer />
        </div>
      </div>
    ) : null;

    return (
      <div className="row" style={{ background: '#666666', height: 55 }}>
        <div className="col-12">
          <SearchBar onChange={value => this.searchBarOnChange(value)} />

          {auditsList && auditsList.length > 0 ? (
            auditsListView
          ) : this.state.dataFetched ? (
            this.state.oldAudits.length > 0 ? (
              <EmptyView
                icon={faInbox}
                title={<Literal tKey="empty_search_title" />}
                description={<Literal tKey="empty_search_subtitle" />}
              />
            ) : (
                <EmptyView
                  icon={faInbox}
                  title={<Literal tKey="empty_finished_title" />}
                  description={<Literal tKey="empty_finished_subtitle" />}
                />
              )
          ) : null}
          <ClipLoader
            sizeUnit={'px'}
            size={70}
            color={'#99CC33'}
            loading={!this.state.dataFetched}
          />
        </div>
      </div>
    );
  }

  canActiveAudit(auditProgress) {
    if (isHotelCompany()) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(true);

      // return Promise.resolve(auditProgress >= 100);
    }
  }

  refreshAudits() {
    this.setState(
      {
        dataFetched: false
      },
      () => {
        getFinishedAudits().then(endedAudits => {
          this.setState({
            dataFetched: true,
            audits: endedAudits,
            oldAudits: endedAudits
          });
        });
      }
    );
  }

  notify = message =>
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      autoClose: 2000
    });
  notify_error = message =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      autoClose: 2000
    });
  searchBarOnChange = value => {
    this.setState({
      audits: this.state.oldAudits.filter(item => {
        return item.title
          .toLowerCase()
          .includes(value.target.value.toLowerCase());
      })
    });
  };

  uploadAudits() {
    if (this.state.itemsStatus !== undefined) {
    const refresh = () => this.refreshAudits();
    let selectedAudits = [];
    this.state.itemsStatus.forEach((itemState, key) => {
      if (itemState) {
        selectedAudits.push(this.state.audits[key]);
      }
    });

    this.setState({ dataFetched: false }, () => {
      selectedAudits.map(audit =>
        uploadAudit(audit.primaryKey).then(
          auditTitle => {
            if (auditTitle === null) {
              this.refreshAudits();

              toast.error(
                <Literal
                  tKey="not_internet"
                  values={{ auditTitle: auditTitle }}
                />, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  hideProgressBar: true,
                  autoClose: 2000
                }
              );
            } else {
              deleteTemplateIfNeeded(audit.id);
              toast.success(<Literal
                tKey="audit_syncronize_successful"
                values={{ auditTitle: auditTitle }}
              />, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  hideProgressBar: true,
                  autoClose: 2000
                });

               setTimeout(function() {
                 refresh();
                }, 1000);
            }

          },
          err => {
            if (audit.id === selectedAudits[selectedAudits.length - 1].id) {
              this.refreshAudits();
            }
            this.notify_error(<Literal
              tKey="audit_syncronize_failure"
              values={{ auditTitle: err.message }}
            />);
          }
        ).catch(err => {
          this.notify_error(<Literal
            tKey="audit_syncronize_failure"
            values={{ auditTitle: err.message }}
          />);
        })
      );
    });
    }
    else{
      this.notify_error(<Literal
        tKey="no_audits_selected"
        values = ""/>)
    }
  }
}
export default FinishedAuditList;

const firstBottomButtonStyle = {
  background: '#99CC33',
  borderColor: '#99CC33',
  borderRadius: 0,
  height: '7vh',
  fontSize: 14
};

const secondBottomButtonStyle = {
  background: '#009310',
  borderColor: '#009310',
  borderRadius: 0,
  height: '7vh',
  fontSize: 14
};
