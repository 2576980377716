import React from 'react';
import TableMenu from './tableMenu';
import NavigationBar from './navigationBar';

export default class MenuContainer extends React.Component {
  state = {
    menuVisibility: false
  };

  render() {
    return (
      <React.Fragment>
        <NavigationBar
          openMenuTapped={() => {
            this.setState(prevState => ({
              menuVisibility: !prevState.menuVisibility
            }));
          }}
        />

        <TableMenu
          menuVisibility={this.state.menuVisibility}
          sectionOpened={visibility => {
            this.setState({
              menuVisibility: visibility
            });
          }}
        />
      </React.Fragment>
    );
  }
}