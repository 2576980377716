import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/fontawesome-free-solid';
import history from '../../../core/router/history';

class NewsTemplatesWebOpened extends React.Component {
  state = {
    dataFetched: false,
    searchText: null
  };

  render() {
    return (
      <div style={container}>
        <div className="row" style={container}>
          <div className="col-12">
            
            <div className="row" style={{marginTop: '25%'}}>
              <div className="col-12">
                <FontAwesomeIcon
                  icon={faDesktop}
                  style={{
                    fontSize: '90px',
                    marginTop: '40px',
                    marginBottom: '40px'
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h3 style={titleStyle}>
                  HAS COMPLETADO LA AUDITORIA EN EL NAVEGADOR, PULSA ATRÁS PARA
                  VOLVER AL LISTADO
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                
                <Button
                  className="w-75"
                  color="success"
                  onClick={() => {
                    history.push('/templates/news-web');
                  }}
                >
                  ATRÁS
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NewsTemplatesWebOpened;

const titleStyle = {
  color: '#666666',
  fontSize: '16px',
  fontWeight: '500',
  letterSpacing: '-0.16px',
  lineHeight: '22px',
  marginTop: '20px',
  marginBottom: '20px'
};

const container = {
  display: 'flex',
  background: '#F0F0F0',
  height: 'calc(100vh - 110px)',
};
