import React from 'react';
import { literalForKey } from '../utils/literalsManager';

class Literal extends React.Component {
  render() {
    const literal = literalForKey(this.props.tKey, this.props.values);
    return this.props.uppercase ? literal.toUpperCase() : literal;
  }
}
export default Literal;
