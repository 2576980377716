import React from 'react';

class Radiobutton extends React.Component {
  state = {
    isChecked: false
  };

  toggleCheckboxChange = evt => {
    const { didTapOption, answerId } = this.props;
    const newState = !this.props.isChecked;
    didTapOption(answerId, newState);
  };

  render() {
    const { label, checked } = this.props;

    return (
      <div className="checkbox" key={this.props.key}>
        <label className="row">
            <div className ="col-10">
              {label}
            </div>
          <div className="col-2">
            <div className="radioButton">
              <input
                type="radio"
                value={label}
                checked={checked}
                onChange={evt => this.toggleCheckboxChange(evt)}
              />
            </div>
           </div>
        </label>
      </div>
    );
  }
}

export default Radiobutton;
