import { getUserData } from '../../api/authManager';

const preveriskCompany = 1;
const auditorRoles = [2];

export function adviceOpenExternalApp(path) {
  localStorage.setItem('lastPathActive', path);
  return Promise.resolve(true);
}

export function existAdviceOpenExternalApp() {
  const lastPath = localStorage.getItem('lastPathActive');
  localStorage.removeItem('lastPathActive');
  return lastPath;
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
export function isHotelCompany() {
  const userData = getUserData();
  if (!userData || userData.company === null) {
    return false;
  }
  return (
    userData.company.id === preveriskCompany ||
    auditorRoles.indexOf(userData.role.id) > -1
  );
}

export function secondsToTimeFormat(seconds) {
  var date = new Date(null);
  date.setSeconds(seconds); // specify value for SECONDS here
  var timeString = date.toISOString().substr(11, 8);
  return timeString;
}

export const elapsedTimeManager = {
  get: auditId => parseInt(localStorage['elapsedTime-' + auditId] || 0),
  set: (auditId, elapsedTime) => {
    localStorage['elapsedTime-' + auditId] = elapsedTime;
  }
};
