const deviceLanguage = 'en';

export function literalForKey(key, values) {
  const literals = require('./translations/' + deviceLanguage + '.json');
  let literal = literals[key] ? literals[key] : '';

  if (values) {
    Object.keys(values).forEach(key => {
      literal = literal.replace(':' + key, values[key]);
    });
  }
  return literal;
}
