import React from 'react';
import { Button } from 'reactstrap';
import SmartQuestion from '../smartQuestion/smartQuestion';
import SubQuestion from '../subquestion/subQuestion';
import Translation from '../translation/Translation';
import Radiobutton from './Radiobutton';

class QRadioButton extends React.Component {
  state = {
    selectedAnswerId: 0,
    isChecked: this.props.checked,
    subQuestion: null,
    smartQuestion: null,
    smartQuestionData: null,
  };
  // updateTextFieldValue = (value) => {
  //   this.setState({ textFieldValue: value });
  // };

  componentWillMount() {
    let answer = null;
    if (this.props.initialAnswer !== null) {
      answer = this.props.initialAnswer;
      this.setState({
        selectedAnswerId: this.props.initialAnswer.answer.id
      });
      if (answer !== null) {
        this.updateViewsIfNeeded(answer.answer, true);
      }
    } else if (
      this.props.defaultAnswer !== null &&
      this.props.defaultAnswer !== undefined
    ) {
      answer = this.props.defaultAnswer;
      this.toggleCheckbox(answer.id, true);
    }
  }

  toggleCheckbox(answerId, active) {
    const { handleQuestion, question } = this.props;

    let answer = question.answers.filter(a => a.id === answerId);
    if (answer.length > 0) {
      answer = answer[0];
    }

    this.setState({ selectedAnswerId: answerId });
    this.updateViewsIfNeeded(answer, active);
    if (answer.section && answer.section !== null) {
      this.setState({
        showSmartQuestion: true
      });
    }
    if (active) {
      handleQuestion(question, answerId, answer.extraInfo);
    }
  }

  handleTextFieldChange = event => {
    this.setState({
      textFieldValue: event.target.value
    });
  }

  updateViewsIfNeeded(data, state) {
    this.hideSubQuestion();
    this.hideSmartQuestion();

    this.setState(
      {
        answer: data,
        showSmartQuestion: false,
        subQuestion: null,
        subquestionData: null
      },
      () => {
        if (state) {
          if (data.requiredInfo) {
            this.showSubQuestion(data);
          }
          if (data.section && data.section !== null) {
            this.showSmartQuestion(data);
          }
        } else {
          data.extraInfo = null;
        }
      }
    );
  }

  showSmartQuestion(data) {
    if (data !== null) {
      const smartQuestion = (
        <SmartQuestion
          section={data.section}
          answerId={data.answerId}
          auditId={this.props.auditId}
          showModal={() => this.state.showSmartQuestion}
          closeModal={() => {
            this.setState({
              showSmartQuestion: false
            });
          }}
        />
      );
      this.setState({
        smartQuestion: smartQuestion
      });
    }
  }
  hideSmartQuestion() {
    this.setState({
      smartQuestionData: null,
      smartQuestion: null
    });
  }
  showSubQuestion(answer) {
    const { handleQuestion, question } = this.props;
    const textFieldValue = answer.extraInfo ? answer.extraInfo.comment : '';

    const subQuestion = (
      <Translation
        model={answer}
        property="sublabel"
        nest={sublabel => (
          <SubQuestion
            answer={answer}
            answerId={answer.answerId ? answer.answerId : answer.id}
            auditId={this.props.auditId}
            label={ sublabel }
            textValue={textFieldValue}
            // updateTextFieldValue={this.updateTextFieldValue}
            handleSubQuestion={extraInfo => {
              let subquestionData = extraInfo;

              this.setState({
                subquestionData: subquestionData
              });
              answer.extraInfo = subquestionData;
              handleQuestion(question, answer.id, subquestionData);
            }}
          />
        )}
      />
    );

    this.setState({
      subQuestion: subQuestion
    });
  }

  hideSubQuestion() {
    this.setState({
      subQuestion: null
    });
  }

  render() {
    const { question } = this.props;

    const toggle = (answerId, active) => this.toggleCheckbox(answerId, active);
    const isChecked = id => this.state.selectedAnswerId === id;
    const { subQuestion, smartQuestion } = this.state;
    const viewSmartQuestionButton = (
      <Button
        color="info"
        onClick={() => {
          this.showSmartQuestion(this.state.answer);

          this.setState({
            showSmartQuestion: true
          });
        }}
      >
        {' '}
        Info{' '}
      </Button>
    );
    return (
      <div>
        <form>
          <div>
            {question.answers.map(function(item) {
              return (
                <Radiobutton
                  label={<Translation model={item} property="label" />}
                  key={item.id}
                  answerId={item.id}
                  questionId={question.id}
                  didTapOption={(answerId, active) => toggle(answerId, active)}
                  checked={isChecked(item.id)}
                />
              );
            })}
            <div className="row">
              <div className="col-12">{smartQuestion}</div>

              <div className="col-12">
                {subQuestion}
                {smartQuestion !== null ? viewSmartQuestionButton : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default QRadioButton;
