import React from 'react';
import { TabPane, Button } from 'reactstrap';
import TabBarAuditIntro from './tabBarAuditIntro';
import {
  getAudit,
  getAuditProgress,
  endAudit,
  startAudit
} from '../auditProvider';
import { getTemplateTitle } from '../templateProvider';
import AuditSectionList from '../sectionList/auditSectionList';
import AuditProgressNav from '../progressNav/auditProgressNav';
import StartAuditInformation from './startAuditInformation/startAuditInformation';
import Literal from '../../../core/shared/literal';
import EndAuditModal from '../../../core/shared/endAuditModal';
import { isHotelCompany } from '../../../core/utils/utils';
import { toast, ToastContainer } from 'react-toastify';
import device from 'current-device';
import { EventEmitter } from '../../../core/utils/events';
import { ClipLoader } from 'react-spinners';

class FillAuditIntro extends React.Component {
  state = {
    audit: {},
    auditTitle: '',
    questions: [],
    currentQuestion: null,
    currentQuestionIndex: 0,
    answers: [],
    tabs: [],
    showModal: false,
    loading: false
  };

  componentDidMount() {
    this.progressValueUpdated();

    EventEmitter.subscribe('progressValueUpdated', event =>
      this.progressValueUpdated(event)
    );

    if (device.os !== 'ios' && device.os !== 'ipad' && device.os !== 'iphone') {
      // navigator.mediaDevices.getUserMedia({ audio: true, video: true });

      if (navigator.permissions) {
        navigator.permissions.query({ name: 'camera' }).then(function(result) {
          if (result.state === 'granted') {
          } else if (result.state === 'prompt') {
          } else if (result.state === 'denied') {
          }
          result.onchange = function() {};
        });
      }
    }
  }

  progressValueUpdated() {
    const templateId = parseInt(this.props.match.params.templateId, 10);
    const { auditId, sectionId } = this.props.match.params;

    if (auditId !== undefined) {
      this.getAuditInfo(auditId).then(({ audit, auditProgressValue }) => {
        let auditTitle = audit && audit.title ? audit.title : '';
        const tabs = this.getTabs(templateId, auditId, sectionId, audit);
        this.setState({
          auditTitle: auditTitle,
          auditProgressValue,
          tabs
        });
      });
    } else {
      if (isHotelCompany()) {
        getTemplateTitle(templateId).then(templateTitle => {
          this.setState({
            tabs: [this.auditInfoTab('1', templateId, templateTitle)]
          });
        });
      }
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('progressValueUpdated', this.handleResize);
  }

  canEndAudit(auditId) {
    if (isHotelCompany()) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(true);
      // return getAudit(auditId).then(audit => {
      //   return audit.auditTotalProgress >= 100;
      // });
    }
  }

  auditInfoTab = (tabId, templateId, auditTitle, startedDate, client) => (
    <TabPane tabId={tabId} key={'auditInfo' + tabId}>
      <StartAuditInformation
        auditTitle={auditTitle}
        startedAudit={this.props.match.params.auditId !== undefined}
        startedDate={startedDate}
        client={client}
        startAuditClick={(startedDate, client) => {
          this.setState({ loading: true });
          return startAudit(templateId, startedDate, client).then(() => {
            this.setState({ loading: false });
            return Promise.resolve();
          });
        }}
      />
    </TabPane>
  );

  sectionListTab = (tabId, templateId, auditId, sectionId) => (
    <TabPane tabId={tabId} key={'sectionList' + tabId}>
      <EndAuditModal
        showModal={() => this.state.showModal}
        closeModal={() =>
          this.setState({
            showModal: false
          })
        }
      />

      <div className="row ">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <AuditSectionList
                templateId={templateId}
                auditId={auditId}
                sectionId={sectionId}
              />
            </div>
          </div>

          <div className="row fixed-bottom" style={bottomButtonStyleEnd}>
            <div className="col-12 endAudit" style={bottomButtonStyleEnd}>
              <Button
                style={bottomButtonStyle}
                onClick={e => {
                  this.canEndAudit(auditId).then(canEnd => {
                    if (canEnd) {
                      endAudit(auditId);
                      this.setState({
                        showModal: true
                      });
                    } else {
                      this.setState({
                        showModal: false
                      });
                      toast.error(
                        <Literal tKey="syncronize_validation_progress_error" />,
                        {
                          position: toast.POSITION.BOTTOM_CENTER,
                          hideProgressBar: true,
                          autoClose: 2000
                        }
                      );
                    }
                  });
                }}
                color="success"
              >
                <Literal tKey="audit_end" uppercase={true} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </TabPane>
  );

  getAuditInfo = auditId => {
    return getAudit(auditId).then(audit =>
      audit
        ? getAuditProgress(auditId).then(auditProgressValue => {
            return {
              audit,
              auditProgressValue: auditProgressValue.toFixed(2)
            };
          })
        : { audit: null, auditProgressValue: null }
    );
  };

  getTabs = (templateId, auditId, sectionId, audit) =>
    isHotelCompany()
      ? [
          this.auditInfoTab(
            '1',
            templateId,
            audit.title,
            audit.startDateAudit,
            audit.clientAudit
          ),
          this.sectionListTab('2', templateId, auditId, sectionId)
        ]
      : [this.sectionListTab('2', templateId, auditId, sectionId)];

  render() {
    const { templateId, auditId } = this.props.match.params;
    const loading = this.state.loading;
    return (
      <div className="row">
        <div className="col-12">
          <AuditProgressNav
            templateId={templateId}
            auditId={auditId}
            progressValue={this.state.auditProgressValue}
          />

          <div style={loading ? loaderContainerStyle : { display: 'none' }}>
            <ClipLoader
              sizeUnit={'px'}
              size={70}
              style={{
                position: 'absolute',
                zIndex: 999
              }}
              color={'#99CC33'}
              loading={loading}
            />
          </div>

          <TabBarAuditIntro tabPanels={this.state.tabs} auditId={auditId} templateId={templateId} />
          <ToastContainer />
        </div>
      </div>
    );
  }
}
export default FillAuditIntro;

const bottomButtonStyle = {
  height: '8vh',
  left: 0,
  right: 0,
  width: '100vw'
};

const bottomButtonStyleEnd = {
  height: '8vh',
  left: 0,
  right: 0,
  width: '100vw'
};

const loaderContainerStyle = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  zIndex: '999'
};
