import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../../api/authManager';
import { fetchWithTimeout } from '../../api/apiClient';
import { getToken } from '../../api/authManager';

// Screens
import FillAuditIntro from '../../screens/fillAudit/intro/fillAuditIntro';
import Login from '../../screens/login/login';
import Recover from '../../screens/login/recover';
import Home from '../../screens/home/home';
import Profile from '../../screens/profile/profile';
import AuditSectionQuestions from '../../screens/fillAudit/sectionQuestions/auditSectionQuestions';
import NewsTemplatesList from '../../screens/templates/news/newsTemplatesList';
import NewsTemplatesWebList from '../../screens/templates/newsweb/newsTemplatesWebList';
import PendingAuditList from '../../screens/templates/pending/pendingAuditList';
import FinishedAuditList from '../../screens/templates/finished/finishedAuditList';
import NotificationsList from '../../screens/notifications/notificationsList';

import NewsTemplatesWebOpened from '../../screens/templates/newsweb/newsTemplatesWebOpened';
import { existAdviceOpenExternalApp, isHotelCompany } from '../utils/utils';
import MenuContainer from './menuContainer';
import history from './history';

// Router Paths
export const uriLogin = '/login';
export const uriRecover = '/recover';

const uriHome = '/home';
const uriProfile = '/profile';
const uriNotifications = '/notifications';

const templates = '/templates';
export const uriNewTemplates = templates + '/news/';
export const uriNewWebTemplates = templates + '/news-web/';
const uriNewWebTemplatesOpened = templates + '/news-web/opened';

const uriPendingTemplates = templates + '/pending';
export const UriSyncTemplates = templates + '/finished';

const flillAuditIntro = '/fillAuditIntro';
const sectionList = '/SectionsList';
const sectionQuestions = '/SectionQuestions';
const aIdParam = '/:auditId';
const tIdParam = '/:templateId';
const sIdParam = '/:sectionId';
const sParentIdParam = '/:parentSectionId';

export const uriFillTemplateIntro = flillAuditIntro + tIdParam;
export const uriFillAuditIntro = flillAuditIntro + tIdParam + aIdParam;
const uriAuditSectionsList =
  flillAuditIntro + tIdParam + aIdParam + sectionList;
const uriAuditSubsectionsList =
  flillAuditIntro + tIdParam + aIdParam + sectionList + sIdParam;

const sectionQuestionsParentList =
  flillAuditIntro +
  tIdParam +
  aIdParam +
  sectionQuestions +
  sParentIdParam +
  sIdParam;

const uriAuditSectionQuestions =
  flillAuditIntro + tIdParam + aIdParam + sectionQuestions + sIdParam;

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: uriLogin,
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export const TemplatesList = () => {
  if (isHotelCompany()) {
    return <NewsTemplatesList />;
  } else {
    return <NewsTemplatesWebList />;
  }
};

let idx = 0;
let routeItems = [
  <Route path={uriLogin} component={Login} key={idx++} />,
  <Route path={uriRecover} component={Recover} key={idx++} />,
  <PrivateRoute exact path="/" component={Home} key={idx++} />,
  <PrivateRoute path={uriHome} component={Home} key={idx++} />,
  <PrivateRoute path={uriProfile} component={Profile} key={idx++} />,
  <PrivateRoute
    exact
    path={uriNewTemplates}
    component={TemplatesList}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={uriNewWebTemplates}
    component={TemplatesList}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={uriNewWebTemplatesOpened}
    component={NewsTemplatesWebOpened}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={UriSyncTemplates}
    component={FinishedAuditList}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={uriPendingTemplates}
    component={PendingAuditList}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={uriAuditSectionQuestions}
    component={AuditSectionQuestions}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={uriAuditSubsectionsList}
    component={FillAuditIntro}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={uriAuditSectionsList}
    component={FillAuditIntro}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={sectionQuestionsParentList}
    component={AuditSectionQuestions}
    key={idx++}
  />,

  <PrivateRoute
    exact
    path={uriFillAuditIntro}
    component={FillAuditIntro}
    key={idx++}
  />,
  <PrivateRoute
    exact
    path={uriFillTemplateIntro}
    component={FillAuditIntro}
    key={idx++}
  />,
  <PrivateRoute
    path={uriNotifications}
    component={NotificationsList}
    key={idx++}
  />
];

class AppRouter extends React.Component {
  componentDidMount() {
    this.tokenCheckInterval = setInterval(() => {
      this.checkTokenValidity();
    }, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.tokenCheckInterval);
  }

  checkTokenValidity() {
    const url = `${process.env.REACT_APP_API_HOST}/auth/checkToken`;
    const token = getToken();
  
    const fetchData = [url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    }];
  
    fetchWithTimeout(fetchData)
      .then(response => {
        if (response.status !== 200) {
          const { uriLogin, location } = this.props;
          const redirectBack = encodeURIComponent(location.pathname + location.search);
          window.location.replace(`${uriLogin}?redirect=${redirectBack}`);
        }
      })
      .catch(error => {
        console.error('Error while checking token validity:', error);
      });
  }
  // constructor(props) {
  //   super(props);

  //   // let that = this;
  //   // fetch.interceptors.response.use(
  //   //   function(response) {
  //   //     return response;
  //   //   },
  //   //   function(error) {
  //   //     if (error.response && error.response.status === 403) {
  //   //       that.handle403();
  //   //     }
  //   //     return Promise.reject(error);
  //   //   }
  //   // );
  // }

  handle403() {
    history.push('/login');
  }

  state = {
    items: routeItems,
    menuVisibility: false,
    store: {}
  };
  sectionOpened = () => {};

  render() {
    let data = existAdviceOpenExternalApp();
    let redirectItem = data ? <Redirect to={{ pathname: data }} /> : null;

    return (
      <Router history={history}>
        <div className="row">
          <div className="col-12">
            {redirectItem}

            <MenuContainer />

            <div className="row">
              <div className="col-12" key="routeItems">
                {routeItems}
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}
export default AppRouter;
