import React from 'react';
import {
  Button,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import Literal from '../../core/shared/literal';
import DeleteAuditModal from '../../core/shared/deleteAuditModal';
import {
  secondsToTimeFormat,
  elapsedTimeManager
} from '../../core/utils/utils';

class AuditInfoModal extends React.Component {
  state = {
    showDeleteModal: false
  };
  render() {
    const showModal =
      this.props &&
      this.props.showModal() !== undefined &&
      this.props.showModal();

    const auditData = this.props.auditData;

    const auditProgress = auditData.progressValue
      ? auditData.progressValue.toFixed(2)
      : 0;

    return (
      <div>
        <Modal
          isOpen={showModal}
          toggle={() => this.props.closeModal()}
          className={this.props.className}
          style={{ top: '5vh' }}
        >
          <ModalHeader
            toggle={() => this.props.closeModal()}
            style={{ textAlign: 'center' }}
          >
            {auditData.title}
          </ModalHeader>
          <ModalBody>
            <h6 style={titleStyle}>
              <Literal tKey="audit_info_document_number" />{' '}
            </h6>
            <div style={valueStyle}>{auditData.documentNumber}</div>

            <h6 style={titleStyle}>
              <Literal tKey="audit_info_client" />{' '}
            </h6>
            <div style={valueStyle}>{auditData.client}</div>

            <h6 style={titleStyle}>
              <Literal tKey="audit_info_started" />
            </h6>
            <div style={valueStyle}> {auditData.started}</div>

            <h6 style={titleStyle}>
              <Literal tKey="audit_info_progress" />
            </h6>
            <div className="text-center">{auditProgress + ' %'}</div>
            <Progress value={auditProgress} />

            <h6 style={titleStyle}>
              <Literal tKey="audit_info_elapsed" />
            </h6>
            <div style={valueStyle}>
              {secondsToTimeFormat(
                auditData.elapsed || elapsedTimeManager.get(auditData.auditId)
              )}
            </div>
            <DeleteAuditModal
              showModal={() => this.state.showDeleteModal}
              deleteAudit={() => this.props.deleteAudit()}
              closeModal={() =>
                this.setState({
                  showDeleteModal: false
                })
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={() => {
                this.props.closeModal();
                this.props.openAudit();
              }}
            >
              <Literal tKey="continue" uppercase={true} />
            </Button>
            <Button
              color="danger"
              onClick={e => {
                this.setState({
                  showDeleteModal: true
                });
              }}
            >
              <Literal tKey="delete" uppercase={true} />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default AuditInfoModal;
const titleStyle = {
  color: '#000000',
  fontSize: 14,
  fontWeight: 'bold',
  marginTop: 14,
  marginBottom: 2
};
const valueStyle = {
  color: '#999999',
  fontSize: 16,
  marginTop: 2,
  marginBottom: 14
};
