import React from 'react';
import Avatar from 'react-avatar';

class FinishedAuditListItem extends React.Component {
  render() {
    const audit = this.props.audit;

    return (
      <div className="row mt-2 mb-2" style={style.container}>
        <div className="col-12">
          <div className="row">
            <div className="col-2">
              <Avatar size="32" src={audit.urlIcon} round={true} />
            </div>

            <div className="col-7" style={style.title}>
              <div className="row">
                <div className="col-12">{audit.title}</div>
              </div>
              <div className="row">
                <div className="col-12 mb-2">{audit.startDateAudit}</div>
              </div>
            </div>

            <div className="col-3">
              <input
                type="checkbox"
                checked={this.props.checked ? this.props.checked : false}
                onChange={() => {
                  this.props.onChange(this.props.key, !this.props.checked);
                }}
              />
            </div>
          </div>

          <SeparatorView />
        </div>
      </div>
    );
  }
}
export default FinishedAuditListItem;

const style = {
  container: {
    bottom: 0,
    display: 'flex',
    alignItems: 'center'
  },
  np: {
    fontSize: 12,
    textAlign: 'left'
  },
  list: {
    overflow: 'scroll',
    height: 'calc(100vh - 110px)',
    WebkitOverflowScrolling: 'touch'
  },
  separator: {
    height: 1,
    background: '#777777'
  }
};

function SeparatorView() {
  return (
    <div className="row">
      <div className="col-12" style={style.separator} />
    </div>
  );
}
