import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/fontawesome-free-solid';
import { DebounceInput } from 'react-debounce-input';

class SearchBar extends React.Component {
  render() {
    const onChange = value => this.props.onChange(value);
    return (
      <div className="row" style={{height: '66px'}}>
        <div className="col-12" style={style.container}>
          <div className="form-group mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <DebounceInput
                className="form-control"
                minLength={2}
                debounceTimeout={300}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SearchBar;

const style = {
  container: {
    background: '#666666'
  }
};
