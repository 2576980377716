import { getDBTable, deleteItem, putItem } from './dbProvider';
import device from 'current-device';

const answerValueTable = 'answerValue';

export function getAnswerValues() {
  return getDBTable(answerValueTable);
}

function deleteAnswerValue(primaryKey) {
  return deleteItem(answerValueTable, primaryKey);
}

function getlLocalAnswerValues(auditId) {
  return getDBTable('answerValue').then(list =>
    Promise.resolve(list.filter(item => item.auditId === auditId))
  );
}

function saveAnswer(auditId, templateId, question, answers, sectionId) {
  let answerValues = [];
  // let localDataKey = 'av-' + templateId + '-' + auditId
  if (answerValues === undefined) {
    // answerValues = JSON.parse(localStorage.getItem(localDataKey));
  }

  if (Array.isArray(answers)) {
    let newValues = answers.map(a => {
      return { question: question, answer: a, sectionId };
    });
    let filtered = answerValues.filter(
      item => item.question.id !== question.id
    );

    newValues.map(item => {
      filtered.push(item);
      return item;
    });
    answerValues = filtered;
  } else {
    let newValue = { question: question, answer: answers, sectionId };
    let filtered = answerValues.filter(item => {
      return item.question.id !== question.id;
    });
    filtered.push(newValue);
    answerValues = filtered;
  }

  return getAnswerValues()
    .then(allAnswerValues => {
      // remove local data
      let outdatedAnswerValues = allAnswerValues.filter(
        answerValue =>
          answerValue.auditId === auditId &&
          answerValue.question.id === question.id
      );

      outdatedAnswerValues.forEach(answerForDelete =>
        deleteItem('answerValue', answerForDelete.id)
      );
      return Promise.resolve(answerValues);
    })
    .then(answerValues => {
      // save new  local data
      answerValues.forEach(answer => {
        if (
          answer.answer.answerId === undefined ||
          answer.answer.answerId === null
        ) {
          answer.answer.answerId = answer.answer.id;
        }
        answer.id =
          answer.answer && answer.answer.answerId
            ? auditId + '' + answer.question.id + '-' + answer.answer.answerId
            : auditId + '' + answer.question.id;
        answer.auditId = auditId;

        if (
          (answer.question.questionType.id === 1 ||
            answer.question.questionType.id === 2) &&
          answer.answer.text === ''
        ) {
          deleteItem('answerValue', answer.id);
        } else {
          if (
            device.os === 'ios' ||
            device.os === 'ipad' ||
            device.os === 'iphone'
          ) {
            if (
              answer.answer &&
              answer.answer.extraInfo &&
              answer.answer.extraInfo.image &&
              answer.answer.extraInfo.image.blob
            ) {
              const url = URL.createObjectURL(
                answer.answer.extraInfo.image.blob
              );
              return fetch(url).then(res => {
                answer.answer.extraInfo.image.blob = null;
                res.arrayBuffer().then(data => {
                  answer.answer.extraInfo.image.blobBytes = data;
                  putItem('answerValue', answer);
                });
              });
            } else {
              if (answer.answer.defaultValue) {
                putItem('visitedQuestion', { id: answer.question.id });
              }
              putItem('answerValue', answer);
            }
          } else {
            if (answer.answer.defaultValue) {
              putItem('visitedQuestion', { id: answer.question.id });
            }
            putItem('answerValue', answer);
          }
        }
      });

      answerValues = [];

      return Promise.resolve(answerValues);
    });
}

function getAnswerValueExtraInfo(auditId, answerId) {
  return getAnswerValues().then(allAnswerValues => {
    // remove local data
    let answerValues = allAnswerValues.filter(
      answerValue =>
        answerValue.auditId === auditId &&
        answerValue.id.endsWith(parseInt(answerId, 10))
    );
    if (answerValues.length > 0) {
      return Promise.resolve(answerValues[0]);
    } else {
      return Promise.resolve(null);
    }
  });
}

function getAnswerValue(auditId, questionId) {
  return getAnswerValues().then(allAnswerValues => {
    // remove local data
    let answerValues = allAnswerValues.filter(
      answerValue =>
        answerValue.auditId === auditId &&
        answerValue.question.id === questionId
    );
    if (answerValues.length > 0) {
      return Promise.resolve(answerValues);
    } else {
      return Promise.resolve(null);
    }
  });
}

export function existAnswerValueForQuestion(auditId, questionId) {
  return getAnswerValue(auditId, questionId).then(
    ansValue => ansValue !== null
  );
}

// export function existAnswerValueForAuditQuestion(
//   templateId,
//   auditId,
//   questionId
// ) {
//   return (
//     getLocalAnswerValues(templateId, auditId).filter(
//       item => item.question.id === questionId
//     ).length > 0
//   );
// }

// export function existOneAnswerOreMoreForQuestion(
//   templateId,
//   auditId,
//   questionId
// ) {
//   return getAnswerValue(auditId, questionId).then(questions =>
//     Promise.resolve(questions !== null)
//   );
// }

// function getLocalAnswerValues(templateId, auditId) {
// return loadDataFromLocalStorage(
//   'a-' + auditId + '-t-' + templateId + '-answerValues'
// );
// }

export {
  deleteAnswerValue,
  getAnswerValue,
  getlLocalAnswerValues,
  getAnswerValueExtraInfo,
  saveAnswer
};
