import React from 'react';
import { LanguageContext } from '../../../../language.context';

const defaultLanguageId = 1;

export default function Translation({ model, property, asHtml = false, nest }) {
  return model ? (
    <LanguageContext.Consumer>
      {({ selectedLanguage }) => {
        let translation = model;
        if (model && selectedLanguage != defaultLanguageId) {
          const foundTranslation = model.translations.find(
            translation => translation.languageId == selectedLanguage
          );
          if (foundTranslation) {
            translation = foundTranslation;
          }
        }
        const translatedProperty = translation[property];
        let result = null;
        if (nest) {
          result = nest(translatedProperty);
        } else {
          result = asHtml ? (
            <div dangerouslySetInnerHTML={{ __html: translatedProperty }} />
          ) : (
            translatedProperty
          );
        }
        return result;
      }}
    </LanguageContext.Consumer>
  ) : null;
}
