import React from 'react';
import SearchBar from '../../../core/shared/searchBar';
import TemplateListItem from './templateListItem';
import { fetchTemplatesList } from '../../fillAudit/templateProvider';
import { faInbox } from '@fortawesome/fontawesome-free-solid';
import { EmptyView } from '../core/emptyView';
import Loader from '../../../core/shared/loader';
import Literal from '../../../core/shared/literal';

class NewsTemplatesList extends React.Component {
  state = {
    dataFetched: false
  };

  componentDidMount() {
    fetchTemplatesList().then(
      templates => {
        this.setState({
          dataFetched: true,
          templates: templates,
          oldTemplates: templates
        });
      },
      err => {
        console.log(err);
        this.setState({
          dataFetched: true,
          templates: [],
          oldTemplates: []
        });
      }
    );
  }

  render() {
    const templatesList = this.state.templates;
    const templatesListView =
      templatesList && templatesList.length > 0 ? (
        <div className="row">
          <div className="col-12" style={style.list}>
            {templatesList.map((item, index) => (
              <TemplateListItem template={item} key={item.id} index={index} />
            ))}
          </div>
        </div>
      ) : this.state.dataFetched ? (
        this.state.oldTemplates.length === 0 ? (
          <EmptyView
            icon={faInbox}
            title={<Literal tKey="empty_templates_title" />}
          />
        ) : (
          <EmptyView
            icon={faInbox}
            title={<Literal tKey="empty_search_title" />}
            description={<Literal tKey="empty_search_subtitle" />}
          />
        )
      ) : null;

    return (
      <div className="row">
        <div className="col-12">
          <SearchBar onChange={this.searchBarOnChange} />
          <Loader loading={!this.state.dataFetched} position="relative" />
          {templatesListView}
        </div>
      </div>
    );
  }

  searchBarOnChange = value => {
    this.setState({
      templates: this.state.oldTemplates.filter(item => {
        return item.title
          .toLowerCase()
          .includes(value.target.value.toLowerCase());
      })
    });
  };
}
export default NewsTemplatesList;

const style = {
  list: {
    overflow: 'scroll',
    WebkitOverflowScrolling: 'touch',
    height: 'calc(100vh - 110px)'
  }
};
