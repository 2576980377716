import React from 'react';
import Checkbox from './Checkbox';

class QCheckBox extends React.Component {
  state = {
    selectedAnswerIds: [],
    selectedAnswers: []
  };

  componentWillMount() {
    let answers = null;
    if (
      this.props.initialAnswers !== null &&
      this.props.initialAnswers.length
    ) {
      answers = this.props.initialAnswers.reduce(
        (allAnswers, answer) => [...allAnswers, answer.answer],
        []
      );
      this.setState({
        selectedAnswers: answers
      });
    }
  }

  toggleCheckbox(answer, active) {
    const { handleQuestion, question } = this.props;
    let selectedAnswers = this.state.selectedAnswers;
    selectedAnswers = selectedAnswers.filter(
      item => item.answerId !== answer.id
    );
    active
      ? selectedAnswers.push(answer)
      : selectedAnswers.forEach(item =>
          item.answerId === answer.answerId || item.answerId === answer.id
            ? selectedAnswers.pop(item)
            : null
        );

    this.setState({
      selectedAnswers: selectedAnswers
    });

    handleQuestion(question, selectedAnswers);
  }

  handleFormSubmit(evt) {
    evt.preventDefault();
  }

  isChecked = id => {
    const list = this.state.selectedAnswers.filter(
      answer => answer.answerId === id
    );
    return list.length > 0;
  };

  getDataForAnswer = (question, answerId) => {
    const itemInLocal = this.state.selectedAnswers.filter(
      item => item.answerId === answerId
    );
    if (itemInLocal.length > 0) {
      return { ...itemInLocal[0], id: itemInLocal[0].answerId };
    } else {
      const itemInArray = question.answers.filter(item => item.id === answerId);
      if (itemInArray.length > 0) {
        return { ...itemInArray[0], answerId: itemInArray[0].id };
      } else {
        return null;
      }
    }
  };
  render() {
    const { question } = this.props;
    const tap = (answer, active) => this.toggleCheckbox(answer, active);

    return (
      <div className="row">
        <div className="col-12">
          <form
            onSubmit={evt => {
              this.handleFormSubmit(evt);
            }}
          >
            {question.answers.map(item => {
              return (
                <div className="row" key={item.id}>
                  <div className="col-12">
                    <div className="mt-2 mb-2">
                      <Checkbox
                        questionId={question.id}
                        defaultAnswer={
                          this.props.initialAnswers.length === 0
                            ? this.props.defaultAnswer
                            : undefined
                        }
                        auditId={this.props.auditId}
                        data={this.getDataForAnswer(question, item.id)}
                        label={item.label}
                        key={item.id}
                        answerId={item.id}
                        didTapOption={(answer, active) => tap(answer, active)}
                        checked={this.isChecked(item.id)}
                        answer={item}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </form>
        </div>
      </div>
    );
  }
}
export default QCheckBox;
