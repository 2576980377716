import React from 'react';
import { Label } from 'reactstrap';
import {
  getSectionProgressWithSection,
  saveSectionProgress,
  calculateAuditTotalProgress,
  calculateMiddleSectionProgress,
  getSectionProgressValue
} from '../auditProvider';
import history from '../../../core/router/history';
import Switch from '../../../core/shared/switch/Switch';
import Literal from '../../../core/shared/literal';
import { EventEmitter } from '../../../core/utils/events';
import Translation from '../components/translation/Translation';

class FillAuditSectionListItem extends React.Component {
  state = {
    section: null,
    progressValue: null,
    notCount: false
  };

  componentDidMount() {
    this.mounted = true;
    const { templateId, sectionId, auditId, section } = this.props;
    Promise.all([
      getSectionProgressWithSection(templateId, auditId, sectionId, section),
      getSectionProgressValue(auditId, sectionId)
    ]).then(([progressNumber, progressValueObject]) => {
      if (this.mounted) {
        this.setState({
          section,
          progressValue: progressNumber,
          notCount: progressValueObject.notCount
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <div
        className="row mt-3"
        style={{ minHeight: 52 }}
        onClick={() =>
          openSection(this.props, this.state.section, this.state.notCount)
        }
      >
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <Label>
              <Translation model={this.props.section} property="title" />
            </Label>
            <div className="d-flex justify-content-end align-items-center">
              {this.state.section && this.state.section.notCount && (
                <div className="mr-4 d-flex justify-content-end align-items-center">
                  <div className="mr-2">
                    <Literal tKey="na" />
                  </div>
                  <Switch
                    onChange={checked => {
                      this.setState({ notCount: checked });
                      saveNA(checked, this.props, this.state.progressValue);
                    }}
                    enabled={this.state.notCount}
                  ></Switch>
                </div>
              )}
              {this.state.notCount ? (
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              ) : (
                <div>
                  {' '}
                  {this.state.progressValue != null
                    ? this.state.progressValue + ' %'
                    : null}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12" style={{ background: '#E0E0E0', height: 2 }} />
      </div>
    );
  }
}
export default FillAuditSectionListItem;

function openSection(props, section, notCount) {
  if (!notCount) {
    if (props.haveOneSubSections) {
      history.push({
        pathname:
          '/fillAuditIntro/' +
          props.templateId +
          '/' +
          props.auditId +
          '/SectionQuestions/' +
          props.sectionId +
          '/' +
          props.oneSubSectionsId,
        state: { section }
      });
    } else if (props.haveSubSections) {
      history.push({
        pathname:
          '/fillAuditIntro/' +
          props.templateId +
          '/' +
          props.auditId +
          '/SectionsList/' +
          props.sectionId,
        state: { section }
      });
    } else {
      history.push({
        pathname:
          '/fillAuditIntro/' +
          props.templateId +
          '/' +
          props.auditId +
          '/SectionQuestions/' +
          props.parentSectionId +
          '/' +
          props.sectionId,
        state: { section }
      });
    }
  }
}

function saveNA(
  notCount,
  { sectionId, auditId, parentSectionId, templateId },
  progressValue
) {
  saveSectionProgress(auditId, sectionId, progressValue, notCount)
    .then(() => {
      const sectionIdToUpdate =
        parentSectionId && parentSectionId != NaN ? parentSectionId : sectionId;
      return calculateMiddleSectionProgress(
        templateId,
        auditId,
        sectionIdToUpdate
      ).then(sectionProgress => {
        return saveSectionProgress(auditId, sectionIdToUpdate, sectionProgress);
      });
    })
    .then(() => calculateAuditTotalProgress(auditId))
    .then(() => EventEmitter.dispatch('progressValueUpdated'));
}
