import React from 'react';
import { Button } from 'reactstrap';
import BasicInfoRow from './basicInfoRow';
import ClientInfoRow from './clientInfoRow';
import ConductedInfoRow from './conductedInfoRow';
import { getUsername } from '../../../../api/authManager';
import { ToastContainer, toast } from 'react-toastify';
import Literal from '../../../../core/shared/literal';
import moment from 'moment';

class StartAuditInformation extends React.Component {
  state = {
    auditTitle: '',
    activeButton: true
  };
  componentDidMount() {
    this.setState({
      auditTitle: this.props.auditTitle
    });
  }

  startAudit = () => {
    if (!this.props.startedAudit) {
      const { startedDate, client } = this.state;
      this.validate()
        .then(() => {
          this.setState({ activeButton: false });
          this.props.startAuditClick(startedDate, client).then(success => {
            this.setState({ activeButton: true });
          });
        })
        .catch(error => this.notify(error));
    } else {
      this.notify('You have already started this audit');
    }
  };

  validate() {
    if (this.state.client === undefined) {
      return Promise.reject('Client is mandatory');
    } else if (this.state.startedDate === undefined) {
      return Promise.reject('Start date is mandatory');
    } else {
      return Promise.resolve();
    }
  }

  notify = message =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      autoClose: 2000
    });

  startDateSelected(date) {
    const newDate = moment(date).format('DD/MM/YYYY hh:mm:ss');
    this.setState({
      startedDate: newDate
    });
  }

  clientSelected(client) {
    this.setState({
      client: client
    });
  }

  render() {
    const auditTitle = this.state.auditTitle;
    // const auditId = this.state.auditId;

    let defaultClient = this.props.client;
    let defaultStartDate = this.props.startedDate;
    const start = () => {
      if (this.state.activeButton) {
        this.startAudit();
       }      
    };
    return (
      <div className="row ">
        <div className="col-12">
          <div className="row mt-2">
            <div className="col-12">
              <BasicInfoRow
                title={<Literal tKey="audit_info_title" />}
                value={auditTitle ? auditTitle : null}
              />
              <ClientInfoRow
                title={<Literal tKey="audit_info_client" />}
                isEditable={
                  this.props.startedAudit ? !this.props.startedAudit : true
                }
                defaultClient={defaultClient}
                clientSelected={client => this.clientSelected(client)}
              />
              <ConductedInfoRow
                title={<Literal tKey="audit_info_conducted_on" />}
                isEditable={
                  this.props.startedAudit ? !this.props.startedAudit : true
                }
                defaultDate={defaultStartDate}
                startDateSelected={date => this.startDateSelected(date)}
              />
              <BasicInfoRow
                title={<Literal tKey="audit_info_personnel" />}
                value={getUsername()}
              />
            </div>
          </div>

          <div className="row fixed-bottom" style={bottomButtonStyle}>
            <div className="col-12" style={bottomButtonStyle}>
              <Button
                style={bottomButtonStyle}
                color="success"
                onClick={start}
                disabled={this.props.startedAudit}
              >
                <Literal tKey="audit_start" uppercase={true} />
              </Button>
            </div>
          </div>

          <div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  }
}
export default StartAuditInformation;

const bottomButtonStyle = {
  height: '8vh',
  left: 0,
  right: 0,
  background: '#99CC33'
};
