import React from 'react';
import { Button } from 'reactstrap';
import SmartQuestion from '../smartQuestion/smartQuestion';
import SubQuestion from '../subquestion/subQuestion';
import Translation from '../translation/Translation';

class Checkbox extends React.Component {
  state = {
    isChecked: this.props.checked,
    subQuestion: null,
    subQuestionData: null,
    smartQuestion: null,
    smartQuestionData: null,
    showSmartQuestion: false,
  };

  componentWillMount() {
    const { data } = this.props;
    if (data !== null) {
      this.updateViewsIfNeeded(data, this.state.isChecked);
      if (
        this.props.defaultAnswer !== undefined &&
        this.props.defaultAnswer !== null &&
        data.id === this.props.defaultAnswer.id
      ) {
        this.props.didTapOption(data, true);
        this.setState({
          isChecked: true
        });
      }
    }
  }
  toggleCheckboxChange = () => {
    const { data, didTapOption } = this.props;
    if (this.state.extraInfo !== undefined) {
      data.extraInfo = this.state.extraInfo;
    }
    const newState = !this.state.isChecked;
    this.setState({
      isChecked: newState
    });
    this.updateViewsIfNeeded(data, newState);
    this.setState({ showSmartQuestion: true });
    didTapOption(data, newState);
  };

  updateViewsIfNeeded(data, state) {
    if (state) {
      if (data.requiredInfo !== undefined && data.requiredInfo) {
        this.showSubQuestion();
      }
      if (data.section !== undefined && data.section !== null) {
        this.showSmartQuestion();
      }
    } else {
      this.hideSmartQuestion();
      this.hideSubQuestion();

      data.extraInfo = null;
      this.setState({ subQuestion: null, subquestionData: null });
    }
  }

  handleTextFieldChange = event => {
    this.setState({
      textFieldValue: event.target.value
    });
  }

  showSmartQuestion(active) {
    const { data } = this.props;
    if (data !== null) {
      const smartQuestion = (
        <SmartQuestion
          section={data.section}
          answerId={data.answerId}
          auditId={this.props.auditId}
          showModal={() => (active ? active : this.state.showSmartQuestion)}
          closeModal={() => {
            this.setState({
              showSmartQuestion: false
            });
          }}
        />
      );
      this.setState(
        {
          smartQuestion: smartQuestion
        },
        () => {
          active = null;
        }
      );
    }
  }
  hideSmartQuestion() {
    this.setState({
      smartQuestionData: null,
      smartQuestion: null
    });
  }
  showSubQuestion() {
    const { data, didTapOption, answer } = this.props;
    const textFieldValue = answer.extraInfo ? answer.extraInfo.comment : '';

    const subQuestion = (
      <Translation
        model={data}
        property="sublabel"
        nest={sublabel => (
          <SubQuestion
            answer={this.props.answer}
            answerId={data.answerId}
            questionId={this.props.questionId}
            auditId={this.props.auditId}
            extraInfo={this.state.subQuestionData}
            textValue={textFieldValue}
            label={ sublabel }
            handleSubQuestion={extraInfo => {
              let subquestionData = extraInfo;
              this.setState({
                subquestionData: subquestionData
              });
              answer.extraInfo = subquestionData
              data.extraInfo = subquestionData;
              didTapOption(data, true);
            }}
          />
        )}
      />
    );
    this.setState({
      subQuestion: subQuestion
    });
  }

  hideSubQuestion() {
    this.setState({
      subQuestionData: null,
      subQuestion: null
    });
  }

  render() {
    const { isChecked, subQuestion, smartQuestion } = this.state;
    const viewSmartQuestionButton = (
      <Button
        color="info"
        onClick={() => {
          this.showSmartQuestion(true);
        }}
      >
        {' '}
        Info{' '}
      </Button>
    );

    const titleStyle = {
      textAlign: 'left'
    };
    return (
      <div>
        <div className="row">
          <div className="row">
            <div className="col-12">{smartQuestion}</div>
          </div>

          <div className="col-10" style={titleStyle}>
            {<Translation model={this.props.data} property="label" />}
          </div>
          <div className="col-2">
            <div className="checkbox">
              <input
                type="checkbox"
                value={'checked'}
                checked={isChecked}
                style={{ marginLeft: 15 }}
                onChange={this.toggleCheckboxChange}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="checkbox">
              {smartQuestion !== null ? viewSmartQuestionButton : null}
            </div>
          </div>
        </div>

        {subQuestion}
      </div>
    );
  }
}
export default Checkbox;
