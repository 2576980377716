import React from 'react';
import {
  getUserData,
  getUserAvatar,
  setUserAvatar,
  logoutUser,
  getUsername
} from '../../api/authManager';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import ChangePasswordModal from './changePasswordModal';
import Literal from '../../core/shared/literal';
import * as loadImage from 'blueimp-load-image';
import { fetchWithTimeout } from '../../api/apiClient';

const image2base64 = require('image-to-base64');

class Profile extends React.Component {
  state = {
    profile: null,
    showModal: false
  };

  componentDidMount() {
    this.setState({ profile: getUserData() });
  }

  app_version = process.env.REACT_APP_VERSION;
  render() {
    let profile = this.state.hasOwnProperty('profile')
      ? this.state.profile
      : null;

    const email = profile ? profile.email : '';
    const companyName = profile ? profile.company.name : '';

    const rows = [getUsername(), email, companyName].map((rowTitle, key) => {
      return (
        <div className="row" key={key}>
          <div className="col-12">
            <div className="row" style={style.row}>
              <div className="col-12" style={{ text: '#333333' }}>
                <h6> {rowTitle}</h6>
              </div>
            </div>
            <SeparatorBar />
          </div>
        </div>
      );
    });

    return (
      <div className="row mt-2" style={style.container}>
        <div className="col-12 px-3">
          <div className="container-with-padding">
            <div className="row">
              <div className="col-12 mt-2 mb-2" style={style.versionLabel}>
                v{this.app_version}
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-2 mb-2">
                <Avatar
                  size="70"
                  round={true}
                  src={this.state.avatar ? this.state.avatar : getUserAvatar()}
                />

                <label htmlFor="fusk" className="ml-3">
                  <FontAwesomeIcon size="2x" icon={faCamera} />
                </label>

                <input
                  id="fusk"
                  type="file"
                  name="photo"
                  style={{ display: 'none' }}
                  onChange={e => this.imageHandleChange(e)}
                  accept="image"
                />
              </div>
            </div>

            {rows}

            <ChangePasswordModal
              showModal={() => this.state.showModal}
              closeModal={() => {
                this.setState({
                  showModal: false
                });
              }}
            />

            <div
              className="row fixed-bottom"
              style={style.bottomView.container}
            >
              <div className="col-12 w-100" style={style.bottomView.container}>
                <Button
                  style={style.bottomView.changeButton}
                  onClick={() => {
                    this.setState({
                      showModal: true
                    });
                  }}
                >
                  <Literal tKey="change_password" uppercase={true} />
                </Button>

                <Button
                  style={style.bottomView.logoutButton}
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  {' '}
                  <Literal tKey="log_out" uppercase={true} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  }

  notify = message =>
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true
    });

  uploadAvatar(avatar) {
    const file = avatar;
    const loadImageOptions = { canvas: true, maxWidth: 1200 };
    loadImage.parseMetaData(file, data => {
      if (data.exif && data.exif.get('Orientation')) {
        loadImageOptions.orientation = data.exif.get('Orientation');
      }
      loadImage(
        file,
        canvas => {
          file.preview = canvas.toDataURL(file.type);

          //     var ctx = canvas.getContext("2d");
          var ctx = canvas.getContext('2d');
          switch (loadImageOptions.orientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, file.width, 0);
              break;
            case 3:
              ctx.transform(-1, 0, 0, -1, file.width, file.height);
              break;
            case 4:
              ctx.transform(1, 0, 0, -1, 0, file.height);
              break;
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              ctx.transform(0, 1, -1, 0, file.height, 0);
              break;
            case 7:
              ctx.transform(0, -1, -1, 0, file.height, file.width);
              break;
            case 8:
              ctx.transform(0, -1, 1, 0, 0, file.width);
              break;
            default:
              ctx.transform(1, 0, 0, 1, 0, 0);
          }

          ctx.drawImage(canvas, 0, 0, file.width, file.height);
          // ctx.toBlob(newimg, file.type);

          canvas.toBlob(blob => {
            var newImg = document.createElement('img'),
              url = URL.createObjectURL(blob);

            newImg.onload = function() {
              // no longer need to read the blob so it's revoked
              URL.revokeObjectURL(url);
            };

            newImg.src = url;

            image2base64(url) // you can also to use url
              .then(response => {
                const formData = new FormData();
                formData.append('avatar', response);
                fetchWithTimeout([
                  process.env.REACT_APP_API_HOST + '/user/uploadAvatar',
                  {
                    method: 'post',
                    avatar: JSON.stringify({
                      binaryImg: response,
                      filename: avatar.name,
                      filesize: blob.size,
                      filetype: blob.type
                    })
                  }
                ])
                  .then(r => r.json())
                  .then(data => {
                    setUserAvatar(avatar);
                  });
              })
              .catch(error => {
                console.log(error); //Exepection error....
              });

            // base64Img.requestBase64(file.name, (err, res, body) => {
            //   newExtraValue.image.base64 = body;
            //   this.updateExtraInfo(newExtraValue);
            // });
          });
        },
        loadImageOptions
      );
    });
  }

  imageHandleChange(event) {
    const file = event.target.files[0];
    this.uploadAvatar(file);
    this.setState({
      avatar: URL.createObjectURL(file)
    });
  }
}

export default Profile;

const style = {
  container: {
    height: '100vh',
    background: '#FFFFFF'
  },
  row: {
    height: 55,
    marginTop: 4,
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    textColor: '#333333'
  },
  bottomView: {
    container: { zIndex: 0 },
    changeButton: {
      background: '#009310',
      borderColor: '#009310',
      width: '100vw',
      height: 60,
      borderRadius: 0
    },
    logoutButton: {
      background: '#99CC33',
      borderColor: '#99CC33',
      width: '100vw',
      height: 60,
      borderRadius: 0
    }
  },
  versionLabel: {
    marginTop: 1,
    marginBottom: 2,
    fontFamily: 'ArialUnicodeMS',
    fontSize: '14px',
    fontWeight: '170',
    lineHeight: '16px',
    textAlign: 'right',
    position: 'fixed',
    right: 0
  }
};

function SeparatorBar() {
  return (
    <div className="row">
      <div
        className="col-12 mt-1 mb-1"
        style={{ height: 1, background: '#999999' }}
      />
    </div>
  );
}
