import React from 'react';
import { Button } from 'reactstrap';
import SmartQuestion from '../smartQuestion/smartQuestion';
import SubQuestion from '../subquestion/subQuestion';
import Translation from '../translation/Translation';

class QQuestion extends React.Component {
  state = {
    selectedAnswerId: null,
    subQuestion: null,
    smartQuestion: null,
    smartQuestionData: null,
  };

  componentWillMount() {
    if (this.props.initialAnswer !== null) {
      let answer = this.props.initialAnswer;

      this.setState({
        selectedAnswerId: answer.answer.id
      });
      this.updateViewsIfNeeded(answer.answer, true);
    } else if (
      this.props.defaultAnswer !== null &&
      this.props.defaultAnswer !== undefined
    ) {
      let answer = this.props.defaultAnswer;

      this.setState({
        selectedAnswerId: answer.id
      });
      this.updateViewsIfNeeded(answer, true);
    }
  }

  updateViewsIfNeeded(data, state) {
    this.hideSmartQuestion();
    this.hideSubQuestion();
    this.setState({
      answer: data,
      showSmartQuestion: false,
      subQuestion: null
    });
    if (state) {
      if (data.requiredInfo !== undefined && data.requiredInfo) {
        this.showSubQuestion(data);
      }
      if (data.section && data.section !== null) {
        this.showSmartQuestion(data);
      }
    } else {
      data.extraInfo = null;
    }
  }

  handleTextFieldChange = event => {
    this.setState({
      textFieldValue: event.target.value
    });
  }

  showSmartQuestion(data) {
    if (data !== null) {
      const smartQuestion = (
        <SmartQuestion
          section={data.section}
          answerId={data.answerId}
          auditId={this.props.auditId}
          showModal={() => this.showSmartQuestion}
          closeModal={() => {
            this.showSmartQuestion = false;
          }}
        />
      );
      this.setState({
        smartQuestion: smartQuestion
      });
    }
  }
  hideSmartQuestion() {
    this.setState({
      smartQuestionData: null,
      smartQuestion: null
    });
  }
  showSubQuestion(answer) {
    const { handleQuestion, question } = this.props;
    const textFieldValue = answer.extraInfo ? answer.extraInfo.comment : '';
    const self = this;
    const subQuestion = (
      <Translation
        model={answer}
        property="sublabel"
        nest={sublabel => (
          <SubQuestion
            answer={answer}
            answerId={answer.id}
            auditId={self.props.auditId}
            extraInfo={self.subQuestionData}
            label={sublabel}
            textValue={textFieldValue}
            handleSubQuestion={extraInfo => {
              self.subquestionData = extraInfo;
              answer.extraInfo = self.subquestionData;
              handleQuestion(question, answer, self.subquestionData);
            }}
          />
        )}
      />
    );

    this.setState({
      subQuestion: subQuestion
    });
  }

  hideSubQuestion() {
    this.setState({
      subQuestion: null
    });
  }

  handleButton(evt, answer) {
    const { handleQuestion, question } = this.props;

    this.setState({
      selectedAnswerId: answer.id
    });

    this.updateViewsIfNeeded(answer, true);

    if (answer.section && answer.section !== null) {
      this.showSmartQuestion = true;
    }

    handleQuestion(question, answer, this.subquestionData);
  }

  handleFormSubmit = event => {
    event.preventDefault();
  };

  viewSmartQuestionButton = () => (
    <Button
      color="info"
      onClick={() => {
        this.showSmartQuestion(this.state.answer);
        this.setState({
          showSmartQuestion: true
        });
      }}
    >
      {' '}
      Info{' '}
    </Button>
  );

  render() {
    const { question } = this.props;
    const { subQuestion, smartQuestion, selectedAnswerId } = this.state;
    const buttonTapped = (evt, answer) => this.handleButton(evt, answer);
    const bunttonIsActive = answerId => selectedAnswerId === answerId;
    const buttonColor = (answerId, activeColor) =>
      bunttonIsActive(answerId) ? activeColor : '#dddddd';

    return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 pt-2 ml-1 ml-1">{smartQuestion}</div>
                </div>

                <div className="row">
                  {question.answers.map(item => {
                    return (
                      <div className="col-6 mt-2 mb-2" key={item.id}>
                        <Button
                          key={item.id}
                          color={buttonColor(item.id)}
                          style={{
                            whiteSpace: 'normal',
                            backgroundColor: buttonColor(item.id, item.colour)
                          }}
                          className="w-100 p-1"
                          onClick={evt => buttonTapped(evt, item)}
                        >
                          <Translation model={item} property="label" />
                        </Button>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="col-12 pt-2 ml-1 ml-1">
                      {subQuestion}
                      {smartQuestion !== null
                        ? this.viewSmartQuestionButton
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default QQuestion;
