import React from 'react';
import { ClipLoader } from 'react-spinners';
import { faInbox } from '@fortawesome/fontawesome-free-solid';
import { EmptyView } from '../templates/core/emptyView';
import { fetchNotifications } from '../../api/apiClient'

class NotificationsList extends React.Component {
    state = {
        dataFetched: false
    };

    componentDidMount() {
        fetchNotifications().then(notifications => {
            this.setState({
                dataFetched: true,
                notifications: notifications,
            });
        });
    }

    render() {

        const notificationsList = this.state.notifications;
        const notificationsListView = notificationsList && notificationsList.length > 0
            ? (
                <div className="row">
                    <div className="col-12" style={style.list}>
                        {notificationsList.map(item => (
                            item
                        ))}
                    </div>
                </div>
            )
            : this.state.dataFetched
                ? <EmptyView icon={faInbox} title="Any notification" />
                : null

        return (
            <div className="row" style={style.container}>
                <div className="col-12">
                    <ClipLoader loading={!this.state.dataFetched} color={'#99CC33'} sizeUnit={'px'} size={70} />
                    {notificationsListView}
                </div>
            </div>
        );
    }
}
export default NotificationsList;

const style = {
    container: { },
    list: {
        overflow: 'scroll',
        height: '85vh',
        WebkitOverflowScrolling: 'touch' 
    }
}