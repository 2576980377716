import * as Promise from 'bluebird';
import { fetchWithTimeout } from '../../api/apiClient';
import { EventEmitter } from '../../core/utils/events';
import { isHotelCompany, secondsToTimeFormat } from '../../core/utils/utils';
import { getAnswerValues, saveAnswer } from '../fillAudit/answerValueProvider';
import {
  calculateAuditTotalProgress,
  calculateFinalSectionProgress,
  calculateMiddleSectionProgress,
  deleteAudit,
  getAudit,
  getSectionProgressValue,
  getSections,
  saveAuditTotalProgress,
  saveSectionProgress
} from '../fillAudit/auditProvider';

const image2base64 = require('image-to-base64');

export function uploadAudit(auditPrimaryKey) {
  return getAudit(auditPrimaryKey).then(audit => {
    return auditGenerateDefaultAnswers(audit).then(localAudit => {
      let auditData = {
        template: {
          id: localAudit.id
        },
        company: {
          id:
            localAudit.clientAudit != null
              ? localAudit.clientAudit.value !== undefined
                ? localAudit.clientAudit.value
                : localAudit.clientAudit
              : localAudit.companies.length > 0
              ? localAudit.companies[0].id
              : null
        },
        elapsedTime: secondsToTimeFormat(localAudit.elapsedAuditTime),
        location: null,
        startedDate: localAudit.startDateAudit
      };
      if (!isHotelCompany()) {
        auditData.startedDate = localAudit.startDateAudit;
        // auditData.companyId = { id: localAudit.clientAudit };
        auditData.auditor = { id: localAudit.auditorId };
        // auditData.referenceId = localAudit.referenceId;
        auditData.user = { id: localAudit.userId };
      }
      let auditSectionsWithProgress = null;
      return getAllSectionsProgress(localAudit.id, localAudit.primaryKey)
        .then(auditSections => {
          auditSectionsWithProgress = auditSections;
          return fetchWithTimeout([
            process.env.REACT_APP_API_HOST + '/audit',
            {
              method: 'post',
              body: JSON.stringify(auditData)
            }
          ]);
        })
        .then(response => {
          if (response !== undefined) {
            return response.json();
          } else {
            return null;
          }
        })
        .then(data => {
          if (data !== null) {
            return getAnswerValuesForAudit(auditPrimaryKey).then(
              answerValues => {
                const filteredAnswerValues = answerValues.filter(
                  answerValue =>
                    !isInNotCountSection(auditSectionsWithProgress, answerValue)
                );
                const lastAnswer = filteredAnswerValues.pop();
                if (lastAnswer) {
                  return Promise.mapSeries(filteredAnswerValues, answerValue =>
                    uploadAnswerValue(
                      data.id,
                      answerValue,
                      false,
                      auditSectionsWithProgress
                    )
                  )
                    .then(() => {
                      // upload last answer
                      return uploadAnswerValue(
                        data.id,
                        lastAnswer,
                        true,
                        auditSectionsWithProgress
                      ).then(() =>
                        deleteAudit(localAudit.primaryKey).then(
                          () => localAudit.title
                        )
                      );
                    })
                    .catch(err => {
                      console.error(err);
                      fetchDeleteAuditUploaded(data.id);
                      return Promise.reject(new Error(localAudit.title));
                    });
                } else {
                  return deleteAudit(localAudit.primaryKey).then(
                    () => localAudit.title
                  );
                }
              }
            );
          } else {
            return Promise.reject(new Error(localAudit.title));
          }
        });
    });
  });

  function isInNotCountSection(auditSections, answerValue) {
    const sectionWithParent = auditSections.find(
      auditSection =>
        auditSection.sectionId == answerValue.sectionId &&
        auditSection.parentSectionId
    );
    const isInNotCountSectionSection = auditSections.some(
      auditSection =>
        auditSection.notCount && auditSection.sectionId == answerValue.sectionId
    );
    const isInNotCountSectionParentSection =
      sectionWithParent &&
      auditSections.some(
        auditSection =>
          auditSection.notCount &&
          auditSection.sectionId == sectionWithParent.parentSectionId
      );
    return isInNotCountSectionSection || isInNotCountSectionParentSection;
  }
}

export function uploadAnswerValue(auditId, answerValue, isLast, auditSections) {
  const extraInfo = answerValue.answer.extraInfo;
  console.log(extraInfo);
  const neededAnswerId =
    answerValue.question.questionType.id !== 1 &&
    answerValue.question.questionType.id !== 2 &&
    answerValue.question.questionType.id !== 6;

  const answerData = {
    question: {
      id: answerValue.question.id
    },
    ...((neededAnswerId && { answer: { id: answerValue.answer.id } }) || null),
    lastAnswer: isLast,
    scoring:
      answerValue.answer && answerValue.answer.scoring != null && answerValue.answer.scoring != undefined
        ? answerValue.answer.scoring
        : 1.0,
    text: answerValue.answer.text,
    extraValue:
      extraInfo && extraInfo !== null
        ? {
            comment: extraInfo.comment || extraInfo.text,
            img: extraInfo.image ? extraInfo.image.filename : null,
            binaryImg: !extraInfo.image
              ? null
              : {
                  base64: null,
                  filename: extraInfo.image ? extraInfo.image.filename : null,
                  filesize: extraInfo.image ? extraInfo.image.filesize : null,
                  filetype: extraInfo.image ? extraInfo.image.filetype : null
                },
            audio: extraInfo.audio ? extraInfo.audio.filename : null,
            binaryAudio: !extraInfo.audio
              ? null
              : {
                  base64: extraInfo.audio
                    ? extraInfo.audio.base64.split(',')[1]
                    : null,
                  filename: extraInfo.audio ? extraInfo.audio.filename : null,
                  filesize: extraInfo.audio ? extraInfo.audio.filesize : null,
                  filetype: extraInfo.audio ? extraInfo.audio.filetype : null
                },
                document: extraInfo.document ? extraInfo.document.filename : null,
                binaryDocument: !extraInfo.document
                  ? null
                  : {
                      base64: null,
                      filename: extraInfo.document ? extraInfo.document.filename : null,
                      filesize: extraInfo.document ? extraInfo.document.filesize : null,
                      filetype: extraInfo.document ? extraInfo.document.filetype : null
                    },
            caducitydate: extraInfo.caducityDate
          }
        : null
  };

  if (isLast) {
    answerData.auditSectionsForLastAnswer = auditSections;
  }

  if (extraInfo !== undefined && extraInfo !== null && extraInfo.image) {
    let url = '';
    if (extraInfo.image.blob) {
      url = URL.createObjectURL(extraInfo.image.blob);
    } else if (extraInfo.image.blobBytes) {
      const blob = new Blob([extraInfo.image.blobBytes], {
        type: 'application/zip'
      });
      url = URL.createObjectURL(blob);
    }
    return image2base64(url).then(response => {
      answerData.extraValue.binaryImg.base64 = response;
      return fetchUploadAnswerValue(auditId, answerData);
    });
  } 
  else if (extraInfo !== undefined && extraInfo !== null && extraInfo.document) {
    const fileUrl = extraInfo.document.file;
    if(fileUrl) {
      return fetch(fileUrl)
        .then(response => response.blob())
        .then(fileBlob => {
          return fileBlob.arrayBuffer();
        })
        .then(arrayBuffer => {
          const base64String = arrayBufferToBase64(arrayBuffer);

          answerData.extraValue.binaryDocument = {
            base64: base64String,
            filename: extraInfo.document.filename,
            filesize: extraInfo.document.filesize,
            filetype: extraInfo.document.filetype,
            document: extraInfo.document.document,
          };
          return fetchUploadAnswerValue(auditId, answerData);
        })
    }
  }
  else {
    return fetchUploadAnswerValue(auditId, answerData);
  }
}

function getAllSectionsProgress(templateId, localAuditId) {
  return getSections(templateId).then(sections => {
    const allSections = sections.reduce((allSections, section) => {
      allSections.push({ sectionId: section.id });
      if (section.sections) {
        section.sections.forEach(subsection => {
          allSections.push({
            sectionId: subsection.id,
            parentSectionId: section.id
          });
        });
      }
      return allSections;
    }, []);
    return Promise.mapSeries(allSections, section => {
      return getSectionProgressValue(localAuditId, section.sectionId).then(
        progressValue => {
          return { ...section, notCount: progressValue.notCount };
        }
      );
    });
  });
}

const existAnswerValueForQuestion = (questionId, list) => {
  const results = list ? list.filter(id => id === questionId) : [];
  return results.length;
};

const getAnswerValuesForAudit = auditId => {
  return getAnswerValues().then(allAnswerValues =>
    allAnswerValues.filter(answerValue => answerValue.auditId === auditId)
  );
};

const auditGenerateDefaultAnswers = audit => {
  if (audit.sections.length) {
    return getAnswerValuesForAudit(audit.primaryKey).then(auditAnswerValues => {
      const answerValuesQuestionIds = auditAnswerValues.map(answerValue =>
        answerValue.question ? answerValue.question.id : null
      );
      return Promise.mapSeries(audit.sections, firstLevelSection => {
        return generateAnswersForSection(
          firstLevelSection,
          audit.primaryKey,
          audit.id,
          null,
          answerValuesQuestionIds
        );
      }).then(() => {
        let allQuestionsCount = audit.sections.map(section => {
          let allQuestions = [];

          section.sections.forEach(section => {
            section.questions.forEach(question => allQuestions.push(question));
            section.sections.forEach(subSection => {
              subSection.questions.forEach(question =>
                allQuestions.push(question)
              );
            });
          });
          return allQuestions.length;
        });

        var count = 0;
        allQuestionsCount.forEach(item => (count = count + item));

        return getAnswerValuesForAudit(audit.primaryKey).then(answerValues => {
          if (answerValues.length) {
            let progress = (parseFloat(answerValues.length / count) * 100) > 100 ? 100 : (parseFloat(answerValues.length / count) * 100);
            return saveAuditTotalProgress(audit.primaryKey, progress).then(
              () => {
                EventEmitter.dispatch('progressValueUpdated');
                return Promise.resolve(audit);
              }
            );
          } else {
            return Promise.resolve(audit);
          }
        });
      });
    });
  } else {
    return Promise.resolve(audit);
  }
};

function generateAnswersForSection(
  section,
  auditPK,
  templateId,
  parentSectionId,
  answerValueIds
) {
  if (section.questions && section.questions.length) {
    return generateAnswersForQuestions(
      section.id,
      section.questions,
      auditPK,
      templateId,
      parentSectionId,
      answerValueIds
    );
  } else if (section.sections && section.sections.length) {
    return Promise.resolve(section.sections).mapSeries(sectionChild =>
      generateAnswersForSection(
        sectionChild,
        auditPK,
        templateId,
        section.id,
        answerValueIds
      )
    );
  } else {
    return true;
  }
}

function generateAnswersForQuestions(
  sectionId,
  questions,
  auditId,
  templateId,
  parentSectionId,
  answerValueIds
) {
  return Promise.resolve(questions).mapSeries(question => {
    if (existAnswerValueForQuestion(question.id, answerValueIds)) {
      return Promise.resolve();
    } else {
      let defaults = question.answers.filter(answer => answer.defaultValue);
      var answer = defaults[0];
      if (defaults.length) {
        if (answer.id) {
          answer.answerId = answer.id;
        }

        return saveAnswer(
          auditId,
          templateId,
          question,
          answer,
          sectionId
        ).then(() => {
          return calculateFinalSectionProgress(
            templateId,
            auditId,
            sectionId
          ).then(sectionProgress => {
            return saveSectionProgress(
              auditId,
              sectionId,
              sectionProgress
            ).then(() => {
              // this.updateSectionProgress();
              if (parentSectionId) {
                return calculateMiddleSectionProgress(
                  templateId,
                  auditId,
                  parentSectionId
                )
                  .then(sectionProgress => {
                    return saveSectionProgress(
                      auditId,
                      parentSectionId,
                      sectionProgress
                    );
                  })
                  .then(() => {
                    return calculateAuditTotalProgress(auditId).then(() =>
                      EventEmitter.dispatch('progressValueUpdated')
                    );
                  });
              } else {
                return calculateAuditTotalProgress(auditId).then(() =>
                  EventEmitter.dispatch('progressValueUpdated')
                );
              }
            });
          });
        });
      } else {
        return Promise.resolve();
      }
    }
  });
}

const fetchUploadAnswerValue = (auditId, auditData) => {
  return fetchWithTimeout([
    process.env.REACT_APP_API_HOST + '/audit/' + auditId + '/answerValue',
    {
      method: 'post',
      body: JSON.stringify(auditData)
    }
  ])
    .then(response => response.json())
    .then(res => {
      return res;
    });
};

const arrayBufferToBase64 = buffer =>{
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
} 

const fetchDeleteAuditUploaded = auditId => {
  return fetchWithTimeout([
    process.env.REACT_APP_API_HOST + '/audit/' + auditId,
    {
      method: 'delete'
    }
  ]);
};
