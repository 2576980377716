import React from 'react';
import './Menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeOpen,
  faClipboardList,
  faListUl,
  faThLarge,
  faSignOutAlt
} from '@fortawesome/fontawesome-free-solid';
import Avatar from 'react-avatar';
import { getUserAvatar, logoutUser } from '../../api/authManager';

class MenuItem extends React.Component {
  render() {
    const itemData = this.props.data;
    return (
      <div
        className="row"
        style={style.container}
        key={this.props.index}
        onClick={togle =>
          this.openSection(itemData.path, itemData.navigationTitle)
        }
      >
        <div className="col-3 mt-2 mb-2" style={style.icon}>
          <div
            className="d-flex justify-content-center align-items-center"
            style={style.backgroundIcon}
          >
            {this.iconForItem(itemData)}
          </div>
        </div>

        <div className="col-9  " style={style.title}>
          {itemData.title}
        </div>
      </div>
    );
  }

  openSection(path, navTitle) {
    if (path === '/logout') {
        logoutUser();
    } else {
      this.props.openSection(path, navTitle);
    }
  }

  iconNameForItemType(type) {
    return type === 'news'
      ? faThLarge
      : type === 'sync'
        ? faClipboardList
        : type === 'saved'
          ? faListUl
          : type === 'notifications'
            ? faEnvelopeOpen
            : type === 'logout'
              ? faSignOutAlt
              : null;
  }

  iconForItem = itemData => {
    if (itemData.iconUrl !== null) {
      return <Avatar size="40" round={true} src={getUserAvatar()} />;
    } else if (itemData.iconName !== null) {
      let icon = this.iconNameForItemType(itemData.iconName);
      return <FontAwesomeIcon icon={icon} style={{ fontSize: '22px' }} />;
    }
  };
}
export default MenuItem;

const style = {
  container: {
    alignItems: 'center'
  },
  icon: {
    color: '#fff'
  },
  backgroundIcon: {
    background: '#99CC33',
    borderRadius: '50%',
    height: 40,
    width: 40,
    marginLeft: 10
  },
  title: {
    textAlign: 'left',
    alignItems: 'center',
    display: 'flex'
  }
};
