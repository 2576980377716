import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

class HtmlViewer extends React.Component {
  render() {
    const showModal =
      this.props &&
      this.props.showModal() !== undefined &&
      this.props.showModal();

    const content = this.props.htmlContent;
    return (
      <div style={{ overflow: 'scroll', WebkitOverflowScrolling: 'touch' }}>
        <Modal
          isOpen={showModal}
          toggle={() => {
            this.props.closeModal();
          }}
          className={this.props.className}
          style={{ top: '5vh', width: '94vw', maxWidth: '94vw' }}
        >
          <ModalBody>
            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <div>
                  <FontAwesomeIcon
                    size="1x"
                    icon={faTimesCircle}
                    onClick={() => this.props.closeModal()}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                overflow: 'scroll',
                WebkitOverflowScrolling: 'touch'
              }}
            >
              {content}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default HtmlViewer;
