import { getDBTable, putItem, getItem, deleteItem } from './dbProvider';
import {
  getlLocalAnswerValues,
  deleteAnswerValue,
  getAnswerValues
} from './answerValueProvider';
import { getTemplateData, templateActive } from './templateProvider';
import * as Promise from 'bluebird';
import { getUserId } from '../../api/authManager';
import moment from 'moment';
import history from '../../core/router/history';
import { elapsedTimeManager, isHotelCompany } from '../../core/utils/utils';

const auditTable = 'audit';
const progressTable = 'section-progress';

// GETTER AUDITS LIST
function getAudits() {
  return getDBTable(auditTable);
}

export function getPendingAudits() {
  return getAudits();
}

export function getFinishedAudits() {
  return getAudits().then(audits => {
    return Promise.resolve(audits.filter(audit => audit.isFinishedAudit));
  });
}

export function getAudit(auditKey) {
  return getItem(auditTable, auditKey);
}

///////////////
// AUDIT PARAMS
///////////////
export function getAuditStartTime(auditId) {
  return getAudit(auditId).then(audit => audit.startAuditTime);
}

export function getAuditStartAndElapsedTime(auditId) {
  return getAudit(auditId).then(audit => {
    return {
      startAuditTime: audit && audit.startAuditTime ? audit.startAuditTime : '',
      elapsedAuditTime:
        audit && audit.elapsedAuditTime ? audit.elapsedAuditTime : ''
    };
  });
}

export function getSections(templateId) {
  return getTemplateData(parseInt(templateId, 10)).then(template => {
    return Promise.resolve(template === undefined ? [] : template.sections);
  });
}

export function getSection(templateId, sectionId) {
  return getTemplateData(parseInt(templateId, 10)).then(template => {
    let sections = template.sections.filter(
      section => section.id === parseInt(sectionId, 10)
    );

    if (sections.length > 0) {
      let section = sections[0];
      return Promise.resolve(section);
    } else {
      let allSection = template.sections.reduce(
        (allSections, section) => [...allSections, ...section.sections],
        []
      );
      let sections = allSection.filter(
        section => section.id === parseInt(sectionId, 10)
      );
      if (sections.length > 0) {
        let section = sections[0];
        return Promise.resolve(section);
      }
    }
  });
}

/////////////////
// AUDIT PROGRESS
/////////////////

export function calculateAuditTotalProgress(auditId) {
  return calculateFirstLevelSectionsProgress(auditId).then(
    auditTotalProgressValue => {
      saveAuditTotalProgress(auditId, auditTotalProgressValue);
    }
  );
}

export function calculateMiddleSectionProgress(templateId, auditId, sectionId) {
  return getSection(templateId, sectionId).then(section => {
    if (section.sections) {
      const subSections = section.sections;
      return Promise.map(subSections, subsection => {
        return getSectionProgressValue(auditId, subsection.id).then(progress =>
          progress.notCount ? 100 : progress.value
        );
      })
        .then(progressArray => progressArray.reduce((a, b) => a + b), 0)
        .then(progressValue => progressValue / section.sections.length);
    }
  });
}

export function calculateFirstLevelSectionsProgress(auditId) {
  return getAudit(auditId).then(audit => {
    const sections = audit.sections;
    return Promise.map(sections, section => {
      return getSectionProgressValue(auditId, section.id).then(progress => {
        return saveSectionProgress(auditId, section.id, progress.value).then(
          () => {
            return Promise.resolve(progress.notCount ? 100 : progress.value);
          }
        );
      });
    })
      .then(progressArray => progressArray.reduce((a, b) => a + b), 0)
      .then(progressValue => progressValue / audit.sections.length);
  });
}

export function calculateFinalSectionProgress(templateId, auditId, sectionId) {
  return getSection(templateId, sectionId).then(section => {
    return getlLocalAnswerValues(auditId)
      .then(answerValues => {
        return Promise.map(section.questions, question => {
          return answerValues.filter(q => question.id === q.question.id)
            .length > 0
            ? 1
            : 0;
        });
      })
      .then(progressArray => progressArray.reduce((a, b) => a + b), 0)
      .then(progressValue => (progressValue / section.questions.length) * 100);
  });
}

export function saveSectionProgress(
  auditId,
  sectionId,
  progressValue,
  notCount
) {
  const pk = auditId + '-' + sectionId;
  let checkNotCountPromise = null;
  if (notCount !== true && notCount !== false) {
    checkNotCountPromise = getSectionProgressValue(auditId, sectionId).then(
      progress => progress.notCount
    );
  } else {
    checkNotCountPromise = Promise.resolve(notCount);
  }
  return checkNotCountPromise
    .then(finalNotCount => {
      const progressObject = {
        id: pk,
        value: parseFloat(progressValue),
        notCount: finalNotCount
      };
      return putItem(progressTable, progressObject);
    })
    .then(() => {
      return true;
    });
}

export function getSectionProgressValue(auditId, sectionId) {
  const id = auditId + '-' + sectionId;
  return getItem(progressTable, id).then(progressItem => {
    if (progressItem === undefined || progressItem.value === undefined) {
      const pk = auditId + '-' + sectionId;
      const progressObject = { id: pk, value: 0, notCount: false };
      return putItem(progressTable, progressObject).then(() => {
        return progressObject;
      });
    } else {
      return progressItem;
    }
  });
}

export function getSectionProgress(templateId, auditId, sectionId) {
  return getSection(templateId, sectionId).then(section => {
    return getSectionProgressWithSection(
      templateId,
      auditId,
      sectionId,
      section
    );
  });
}

export function getSectionProgressWithSection(
  templateId,
  auditId,
  sectionId,
  section
) {
  if (section !== undefined) {
    // let allSections = audit.sections.reduce((allSections, section) => [...allSections, ...section.sections], []);
    let allQuestions = [];
    allQuestions =
      section.questions !== undefined && section.questions.length > 0
        ? section.questions.map(question => ({ ...question, sectionId }))
        : [];
    section.sections.forEach(section => {
      section.questions.forEach(question =>
        allQuestions.push({ ...question, sectionId: section.id })
      );
    });
    return Promise.each(allQuestions, question => {
      return getSectionNotCount(auditId, question.sectionId).then(notCount => {
        question.isInNotCountSection = notCount;
      });
    })
      .then(() => {
        return getlLocalAnswerValues(auditId);
      })
      .then(answerValues => {
        const validQuestions = allQuestions.filter(
          question =>
            answerValues.filter(answer => answer.question.id === question.id)
              .length > 0 || question.isInNotCountSection
        );
        const progress = (validQuestions.length / allQuestions.length) * 100;

        let numDecimals = 0;
        if (progress <= 0) {
          numDecimals = 2;
        } else if (progress < 10) {
          numDecimals = 2;
        } else if (progress < 100) {
          numDecimals = 1;
        }
        return Promise.resolve(progress.toFixed(numDecimals));
      });
  } else {
    return Promise.resolve(0);
  }

  function getSectionNotCount(auditId, sectionId) {
    return getSectionProgressValue(auditId, sectionId).then(progressValue => {
      return progressValue.notCount;
    });
  }
}

// function getProgressForQuestionsList(auditId, questions) {
//   return Promise.map(questions, question => {
//     return existAnswerValueForQuestion(auditId, question.id).then(exist => {
//       return exist ? 1 : 0;
//     });
//   }).then(result => {
//     let pValue = result.reduce(
//       (count, questionProgress) => (count = count + questionProgress)
//     );
//     return pValue / questions.length;
//   });
// }

export function saveAuditTotalProgress(auditId, totalProgress) {
  return getAudit(auditId).then(audit => {
    audit.auditTotalProgress = totalProgress;
    return putItem('audit', audit);
  });
}

export function getAuditProgress(auditId) {
  return getAudit(auditId).then(audit => {
    return audit.auditTotalProgress;
  });
}

/////////////////
// CREATE AUDIT
/////////////////
export function initProgressData(section, auditId, templateId) {
  let eSections = section.sections && section.sections.length > 0;
  let eQuestions = section.questions && section.questions.length > 0;
  let questionsItem = (sectionId, questionsCount) => {
    return { sectionId: sectionId, values: questionsCount };
  };

  let valie =
    !eSections && !eQuestions
      ? null
      : !eSections && eQuestions
      ? questionsItem(section.id, new Array(section.questions.length))
      : section.sections.map(section => {
          return questionsItem(section.id, new Array(section.questions.length));
        });
  return valie;
}

export function createAudit(template, startedDate, client) {
  // Create audit
  let randomAuditKey = Math.random()
    .toString(32)
    .slice(2, 14);

  const pvalues = template.sections.map(section => {
    return initProgressData(section);
  });

  const auditPK = 'a-' + template.id + '-' + randomAuditKey;
  let audit = {
    ...template,
    isFinishedAudit: false,
    auditAnswerValues: [],
    auditProgress: pvalues,
    primaryKey: auditPK,
    clientAudit: client,
    auditTotalProgress: 0,
    startDateAudit: startedDate,
    startAuditTime: moment()
      .format('DD/MM/YYYY hh:mm:ss')
      .toString(),
    elapsedAuditTime: 0
  };

  return putItem(auditTable, audit).then(auditPK => Promise.resolve(audit));
}

export function startAudit(
  templateId,
  startedDate,
  client,
  userId,
  referenceId
) {
  return getTemplateData(parseInt(templateId, 10)).then(template => {
    return createAudit(template, startedDate, client).then(audit => {
      if (!isHotelCompany()) {
        audit.startedDate = startedDate;
        audit.companyId = client;
        audit.auditorId = getUserId();
        audit.referenceId = referenceId;
        audit.userId = userId;
      }
      return putItem('audit', audit).then(auditId => {
        history.replace('/fillAuditIntro/' + templateId + '/' + auditId);
        return audit;
      });
    });
  });
}

export function existAuditForTemplate(templateId) {
  return getDBTable('audit').then(audits => {
    const results = audits.filter(audit => audit.id === templateId);
    return results.length > 0;
  });
}

/////////////////
// END AUDIT
/////////////////
export function endAudit(auditId) {
  return getAudit(auditId).then(audit => {
    audit.elapsedAuditTime = elapsedTimeManager.get(auditId);
    audit.isFinishedAudit = true;
    return putItem(auditTable, audit);
  });
}

export function deleteAudit(primaryKey) {
  return getAudit(primaryKey).then(audit => {
    return deleteItem(auditTable, primaryKey).then(() => {
      return getAnswerValues().then(allAnswerValues => {
        let answerValues = allAnswerValues.filter(
          answerValue => answerValue.auditId === primaryKey
        );

        return Promise.each(answerValues, answerValue =>
          deleteAnswerValue(answerValue.id)
        ).then(() => {
          return templateActive(audit.id).then(active => {
            if (!active) {
              return getAudits().then(audits => {
                const output = audits.filter(a => a.id === audit.id);
                if (output === 0) {
                  return deleteItem('template-data', audit.id);
                } else {
                  return Promise.resolve(true);
                }
              });
            }
          });
        });
      });
    });
  });
}
