import React from 'react';
import './Menu.css';
import { isAuthenticated } from '../../api/authManager';
import { getUsername, getUserAvatar } from '../../api/authManager';
import { setNavigationTitle } from './navigationBar';
import MenuItem from './menuItem';
import { literalForKey } from '../utils/literalsManager';
import { isHotelCompany } from '../utils/utils';
import history from './history';

class TableMenu extends React.Component {
  render() {
    let visibility = 'hide';
    if (this.props.menuVisibility) {
      visibility = 'show';
    }

    let sections = menuData;
    const style = getStyle(visibility);

    // Add Profile section if authenticated
    if (
      isAuthenticated() &&
      (sections.find(section => section.path === '/profile') &&
        !sections.find(section => section.title === getUsername()))
    ) {
      sections = sections.splice(
        sections.findIndex(section => section.path === '/profile'),
        1
      );
      sections.unshift({
        title: getUsername(),
        path: '/profile',
        iconUrl: getUserAvatar(),
        iconName: null,
        navigationTitle: 'Profile'
      });
    } else if (
      isAuthenticated() &&
      !sections.find(section => section.path === '/profile')
    ) {
      sections.unshift({
        title: getUsername(),
        path: '/profile',
        iconUrl: getUserAvatar(),
        iconName: null,
        navigationTitle: 'Profile'
      });
    }

    if (isHotelCompany()) {
      sections = sections.filter(
        section => section.type === 'HC' || !section.type
      );
    } else {
      sections = sections.filter(
        section => section.type === 'TC' || !section.type
      );
    }

    // Menu items render
    let menuItems = sections.map((itemData, index) => {
      return [
        <MenuItem
          data={itemData}
          key={index + '-item'}
          index={index}
          openSection={(path, navTitle) => this.openSection(path, navTitle)}
        />,
        <div
          className="col-12"
          style={style.separator}
          key={index + '-separator'}
        />
      ];
    });

    return (
      <div
        id="flyoutMenu"
        onMouseDown={this.props.handleMouseDown}
        className={visibility}
        style={style.container}
        onClick={evt => {
          evt.stopPropagation();
          this.props.sectionOpened();
        }}
      >
        <div style={style.table} className={visibility}>
          {menuItems}
        </div>
      </div>
    );
  }

  handleMouseDown(e) {
    e.stopPropagation();
  }

  openSection(path, navTitle) {
    setNavigationTitle(navTitle);
    this.props.sectionOpened();
    history.push(path);
  }
}
export default TableMenu;

const menuData = [
  {
    title: 'Templates',
    path: '/templates/news',
    iconUrl: null,
    iconName: 'news',
    navigationTitle: literalForKey('menu_templates'),
    type: 'HC'
  },
  {
    title: 'References',
    path: '/templates/news-web',
    iconUrl: null,
    iconName: 'news',
    navigationTitle: literalForKey('menu_references'),
    type: 'TC'
  },
  {
    title: 'Sync Audits',
    path: '/templates/finished',
    iconUrl: null,
    iconName: 'sync',
    navigationTitle: literalForKey('menu_audits_finished')
  },
  {
    title: 'Saved Audits ',
    path: '/templates/pending',
    iconUrl: null,
    iconName: 'saved',
    navigationTitle: literalForKey('menu_audits_saved')
  },
  {
    title: 'Logout',
    path: '/logout',
    iconUrl: null,
    iconName: 'logout',
    navigationTitle: literalForKey('menu_log_out')
  }
];

const getStyle = visibility => ({
  table: {
    width: '75vw',
    height: '100vh',
    background: 'white',
    opacity: 1,
    visibility: visibility
  },
  container: {
    width: '100vw',
    marginTop: '7vh',
    background: 'rgb(204,204,204, 0.75)',
    backgroundColor: 'rgb(204,204,204, 0.75)',
    height: '100vh',
    visibility: visibility
  },
  separator: {
    height: 1,
    background: '#999999'
  },
  menuItem: {
    container: {
      alignItems: 'center'
    },
    icon: {},
    title: {
      textAlign: 'left',
      alignItems: 'center',
      display: 'flex'
    }
  }
});
