import React from 'react';
import {
  downloadTemplate,
  downloadReferenceTemplate
} from '../../screens/fillAudit/templateProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/fontawesome-free-solid';
import Loader from './loader';
import Literal from './literal'

class DownloadTemplateButton extends React.Component {
  state = {
    downloadingData: false,
    dataDownloaded: false
  };

  render() {
    const component = this.state.downloadingData ? (
      <Loader
        loading={this.state.downloadingData}
        size={30}
        position="relative"
        topMargin={0}
      />
    ) : (
      <FontAwesomeIcon
        onClick={() => this.downloadAction()}
        size="2x"
        icon={faDownload}
        style={style}
      />
    );

    return component;
  }

  downloadAction() {
    this.setState(
      {
        downloadingData: true
      },
      () => {
        if (this.props.referenceId === undefined) {
          this.downloadTemplate(this.props.templateId);
        } else {
          this.downloadReference(
            this.props.itemId,
            this.props.templateId,
            this.props.referenceId
          );
        }
      }
    );
  }

  downloadTemplate(templateId) {
    downloadTemplate(templateId).then(
      downloaded => {
        if (downloaded !== null) {
          this.setState({
            downloadingData: false,
            dataDownloaded: downloaded
          });
          this.props.templateDownloaded();
        } else {
          this.props.notifyMessage(<Literal tKey="not_internet" />);

          this.setState({
            downloadingData: false,
            dataDownloaded: downloaded
          });
          this.props.templateDownloaded();
        }
      },
      err => {
        console.log(err);

        this.setState({
          downloadingData: false,
          dataDownloaded: false
        });
        this.props.templateDownloaded();
        this.props.notifyMessage(<Literal tKey="not_internet" />);
      }
    );
  }

  downloadReference(itemId, templateId, referenceId) {
    downloadReferenceTemplate(itemId, templateId, referenceId).then(
      downloaded => {

        if (downloaded !== null) {
           
          this.setState({
            downloadingData: false,
            dataDownloaded: downloaded
          });
          this.props.templateDownloaded();
        } else {
          this.props.notifyMessage(<Literal tKey="not_internet" />);
          this.setState({
            downloadingData: false,
            dataDownloaded: false
          });
          this.props.templateDownloaded();
        }
      },
      err => {
        console.log(err);
        this.setState({
          downloadingData: false,
          dataDownloaded: false
        });
        this.props.templateDownloaded();
        this.props.notifyMessage(<Literal tKey="not_internet" />);

      }
    );
  }
}
export default DownloadTemplateButton;
const style = {
  marginTop: '2px',
  marginLeft: '2px'
};
