import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import Literal from '../../core/shared/literal';
import { UriSyncTemplates, uriNewTemplates } from '../router/appRouter';
import { isHotelCompany } from '../utils/utils';
import history from '../router/history';

class EndAuditModal extends React.Component {
  render() {
    const showModal =
      this.props &&
      this.props.showModal() !== undefined &&
      this.props.showModal();

    return (
      <div>
        <Modal
          isOpen={showModal}
          toggle={() => this.props.closeModal()}
          className={this.props.className}
          style={{ top: '35vh' }}
        >
          <ModalHeader
            toggle={() => this.props.closeModal()}
            style={{ textAlign: 'center' }}
          >
            {' '}
            <h6 style={titleStyle}>
              <Literal tKey="audit_end_alert" />
            </h6>
          </ModalHeader>
          <ModalFooter style={{ height: '60px' }}>
            <Button
              className="btn-round"
              color="danger"
              style={{
                position: 'absolute',
                left: '32px',
                bottom: '8px',
                height: 'auto',
                background: '#E2E2E2',
                borderColor: '#CED0DA',
                color: '#666565'
              }}
              onClick={() => {
                history.push(UriSyncTemplates);
              }}
            >
              <Literal tKey="menu_audits_finished" />
            </Button>
            <Button
              color="danger"
              style={{
                position: 'absolute',
                right: '32px',
                marginTop: '16px',
                bottom: '8px',
                height: 'auto',
                background: '#E2E2E2',
                borderColor: '#CED0DA',
                color: '#666565'
              }}
              onClick={e => {
                if (isHotelCompany()) {
                  history.push(uriNewTemplates);
                } else {
                  history.push(uriNewTemplates);
                }
              }}
            >
              <Literal tKey="menu_templates" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default EndAuditModal;
const titleStyle = {
  marginTop: 8,
  marginBottom: 0,
  color: '#758092',
  fontFamily: 'ArialUnicodeMS',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '22px',
  textAlign: 'center'
};